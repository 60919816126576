import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Box, SpanStyled } from "../../styled/Elements";
import { useTranslation } from "react-i18next";

const OTPInputField = () => {
    const {t} = useTranslation()
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [seconds, setSeconds] = useState(90);
  const [expired, setExpired] = useState(false);
  const firstInputRef = useRef(null);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (isNaN(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && e.target.nextSibling) {
      e.target.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  };
  useEffect(() => {
    firstInputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setExpired(true);
    }
  }, [seconds]);

  return (
    <Box>
      <TimerContainer>
        {expired ? (
          <SpanStyled color="var(--text)">
            {t("time_up_please_request_new_code")}
          </SpanStyled>
        ) : (
          <>
            <SpanStyled
              color="var(--text2)"
              bg="var(--white)"
              className="count-num p-2"
            >
              {Math.floor(seconds / 60)
                .toString()
                .padStart(2, "0")}
            </SpanStyled>
            &nbsp; : &nbsp;
            <SpanStyled
              color="var(--text2)"
              bg="var(--white)"
              className="count-num p-2"
            >
              {Math.floor(seconds % 60)
                .toString()
                .padStart(2, "0")}
            </SpanStyled>
          </>
        )}
      </TimerContainer>
      <OtpContainer>
        {otp.map((value, index) => (
          <OtpInput
            key={index}
            ref={index === 0 ? firstInputRef : null}
            type="text"
            maxLength="1"
            value={value}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            disabled={expired}
          />
        ))}
      </OtpContainer>
    </Box>
  );
};

export default OTPInputField;

const TimerContainer = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
  direction: ltr;
  .count-num {
    border: 1px solid var(--border);
    border-radius: 6px;
  }
`;

const OtpInput = styled.input`
  width: 100%;
  height: 70px;
  margin: 0 10px;
  font-size: 24px;
  color: var(--text);
  text-align: center;
  border: 1px solid var(--borderLight);
  border-radius: 8px;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  &:focus {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-color: var(--border);
  }
  &:disabled {
    background-color: var(--bgSecondary);
  }
`;

const OtpContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
