import styled from "styled-components";
import { Box, SpanStyled, TextStyled } from "../../styled/Elements";
import { useTranslation } from "react-i18next";



const CustomTile = (props) => {
	const { t } = useTranslation();
	return (
		<TileWrapperStyled 
			className={`d-flex h-100 align-items-start ${props.className ? props.className : ""} ${props && props.border ? " statsBorder" : ""}`}
		>
			<Box className={`tile-icon`} bg={props.bg}>
				<SvgContainerStyled stroke={props.color}>
					<props.icon width="25px" height="25px" />
				</SvgContainerStyled>
			</Box>
			<Box className="mx-3">
				<TextStyled
					className="mb-1"
					size="16px"
					color="var(--text2)"
					dangerouslySetInnerHTML={{ __html: t(props.title) }}
					
				></TextStyled>
				<SpanStyled color={"var(--text)"} size="16px" family="var(--regularBold)">
					{props.count}
				</SpanStyled>
			</Box>
		</TileWrapperStyled>
	);
};

const TileWrapperStyled = styled.div`
  background: var(--white);
  min-width: 220px;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid var(--borderLight);
  border-radius: 15px;
  padding: 20px;
  .tile-icon {
    padding: 15px;
    border-radius: 12.0755px;
  }
  &.allBorder{
    border: 1px solid var(--borderLight);
  }
`;
const SvgContainerStyled = styled.div`
  svg {
    [stroke] {
      stroke: ${({ stroke }) => (stroke ? stroke : "")};
    }
    [fill] {
      fill: ${({ stroke }) => (stroke ? stroke : "")};
    }
  }
`;

export default CustomTile;
