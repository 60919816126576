import styled from "styled-components";

export const ManageDepartmentWrapper = styled.div`
  .content-box > div {
    border-bottom: 1px solid var(--borderLight);
    &:last-child,
    &:nth-last-child(2) {
      border-bottom: none;
    }
  }
  .hqbranch-form-item {
    gap: 10px;
  }
`;
export const DepartmentDetailsWrapper = styled.div``