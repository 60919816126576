import React from 'react'
import { Box } from '../../../../components/styled/Elements'
import PermissionTable from './PermissionTable'

const UserPermissions = () => {
  return (
    <Box>
      <PermissionTable />
    </Box>
  )
}

export default UserPermissions