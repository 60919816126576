import { useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { privateRoutes } from '../routes/privateRoutes';

export const useBreadcrumbs = () => {
  const location = useLocation();

  return useMemo(() => {
    const breadcrumbs = [];
    let currentPath = '';
    let breadcrumb = ''

    location.pathname.split('/')
      .filter(Boolean)
      .forEach((part) => {
        currentPath += `/${part}`;
        breadcrumb = part.replace(/-/g, "_")
        const match = privateRoutes.find(route => route.path.includes(part));

        if (match) {
          breadcrumbs.push({
            path: currentPath,
            breadcrumb: breadcrumb,
          });
        }
      });

    return breadcrumbs;
  }, [location.pathname]);
};