import React, { useState } from "react";
import { BackButton, Box, ButtonStyled, FormElementWrapper, FormEleWrapper, HeadingStyled, LabelStyled, Separator, SeparatorBorder, SpanStyled, SubHeadingStyled, TextStyled } from "../../../../../components/styled/Elements";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DashboardWrapper, DataContainer, DataContainerForm, FormDataContainer, ProfileSidebarWrapper, ProfileWrapper } from "./styled";
import { Camera, DateIcon, LogoutIcon, MenuTriggerCross, Plus, Profile, Profile2, ProfileImgLarge, SingleProfile } from "../../../../../components/styled/AllImages";
import { useNavigate } from "react-router-dom";
import FloatingInput from "../../../../../components/common/inputFields/FloatingInput";
import { RadioButton } from "../../../../../components/styled/FormElements";
import AddUsers from "../addFile/components/addUsers/MainData";
import { addArchiveRadio, addArchiveSettingRadio, addFileRadio, departmentsList, statusArchiveRadio, usersList } from "../../../../../helpers/dummyConstants";
import FormRadio from "../../../../../components/common/radioButtons/FormRadio";
import AddSingleUser from "../addFile/components/addSingleUser/MainData";
import ValidatedInput from "../../../../../components/common/inputFields/ValidatedInput";
import DatePicker from "react-datepicker";
import DocumentUpload from "../../../../../components/common/uploadFiles/documentUpload";
import ImageUpload from "../../../../../components/common/uploadFiles/imagesUpload";
import CustomRadioButton from "../../../../../components/common/radioButtons/CustomRadio";
import DatePickerDefault from "../../../../../components/common/inputFields/DatePicker";
import Breadcrumbs from "../../../../../components/admin/Breadcrumbs/Breadcrumbs";
import { UserUpload } from "../../../../../components/styled/styled";


const EditArchive = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [checkSelectedValueID, setcheckSelectedValueID] = useState(null);
    // Initialize the selected value state with the first radio button's id
    const [selectedRadio, setSelectedRadio] = useState(addArchiveRadio[0].id);

	const checkSelectedValue = (e) => {
		setcheckSelectedValueID(Number(e.target.value));
	};

    

    // Handle radio change
    const handleRadioChange = (event) => {
        setSelectedRadio(parseInt(event.target.value));
    };
    

    // Determine accept and uploadText based on selected radio button
    const getUploadSettings = () => {
        switch (selectedRadio) {
        case 2:
            return {
            accept: "video/*",
            uploadText: "UploadVideo",
            };
        case 3:
            return {
            accept: "audio/*",
            uploadText: "UploadVoice",
            };
        case 5:
            return {
            accept: "*",
            uploadText: "upload",
            };
        default:
            return {
            accept: ".pdf, .doc, .docx, .txt, .odt, .ppt, .pptx, .xls, .xlsx, .rtf, .csv, .ods, .odp, .epub", // Default for other radios
            uploadText: "UploadDocument",
            };
        }
    };
    const { accept, uploadText } = getUploadSettings();

	return (
		<ProfileWrapper>
			<Box className="content-head py-3 px-3 px-md-4">
				{/* <SubHeadingStyled size="16px" color="var(--text)">
					{t("AddFile")}
				</SubHeadingStyled> */}
                <Box className="d-flex align-items-center gap-5">
                    <BackButton
                        label={t("add_archive")}
                        onClick={() => navigate("/cabinets/files/archives")}
                    />
                    <Breadcrumbs />
                </Box>
			</Box>
			<Box className="content-container">
				<Row className="mt-3">
					<Col md={12}>
						<FormDataContainer className="dataContainer">
						
							<FormElementWrapper className="combined pt-3">
								<Box className="formEleContainer">
									<LabelStyled className="labelForm">
										{t('type')}
									</LabelStyled>
                                    <Box className="radiosContainer">
                                        {addArchiveRadio.map((x, i) => (
                                            <FormRadio
                                                name="doc_type"
                                                label={t(x.name)}
                                                subLabel={t(x.label)}
                                                value={x.id}
                                                className="archiveRadios"
                                                checked={selectedRadio === x.id}
                                                onChange={handleRadioChange}
                                                // checked={checkSelectedValueID === x.id}
                                                // onChange={checkSelectedValue}
                                                // checked={selected === 2}
                                                // onChange={onSelect}
                                            />

                                        ))}
                                        
                                    </Box>
								</Box>
							</FormElementWrapper>
                            {(selectedRadio === 5) && (
                                <>
                                    
                                    <ValidatedInput
                                        name="other"
                                        type="input"
                                        fieldType="text"
                                        placeholder={"OtherType"}
                                        label="OtherType"
                                        className="combined"
                                    />
                                </>
                            )}
                            <ValidatedInput
                                name="description"
                                type="input"
                                fieldType="text"
                                placeholder={"Description"}
                                label="Description"
                                className="combined"
                            />
                            {(selectedRadio === 1) && (
                                <>
                                    
                                    <ValidatedInput
                                        name="nopages"
                                        type="input"
                                        fieldType="text"
                                        placeholder={"Nopages"}
                                        label="Nopages"
                                        className="combined"
                                    />
                                </>
                            )}
                            {(selectedRadio === 2 || selectedRadio === 3) && (
                                <>
                                    <ValidatedInput
                                        name="vid_duration"
                                        type="input"
                                        fieldType="text"
                                        placeholder={"Duration"}
                                        label="Duration"
                                        className="combined"
                                    />
                                </>
                            )}
                            
                            
                            <FormElementWrapper className="combined pt-3">
								<Box className="formEleContainer">
									<LabelStyled className="labelForm">
										{t('Attachment')}
									</LabelStyled>
                                    {(selectedRadio === 1 || selectedRadio === 2 || selectedRadio === 3 || selectedRadio === 5) && (
                                        <DocumentUpload 
                                            accept={accept}
                                            uploadText={t(uploadText)}
                                            // accept=".pdf, .doc, .docx, .txt, .odt, .ppt, .pptx, .xls, .xlsx, .rtf, .csv, .ods, .odp, .epub"
                                            // uploadText="Upload Document"
                                        />
                                    )}
                                    {(selectedRadio === 4) && (
                                        <ImageUpload
                                            UploadImage="UploadImage"
                                        />
                                    )}
								</Box>
							</FormElementWrapper>
                            
                            {/* <ValidatedInput
                                name="attachment"
                                type="input"
                                fieldType="text"
                                placeholder={"Attachment"}
                                label="Attachment"
                                className="combined"
                            /> */}
                            
                            <SeparatorBorder className="mt-2" />
                            <FormElementWrapper>
								<Box className="formEleContainer">
									<LabelStyled className="labelForm">
										{t('status')}
									</LabelStyled>
                                    <Box className="radiosContainer">
                                        {statusArchiveRadio.map((x, i) => (
                                            <FormRadio
                                                name="status"
                                                label={t(x.name)}
                                                subLabel={t(x.label)}
                                                value={x.id}
                                                className="archiveRadios"
                                                // checked={checkSelectedValueID === x.id}
                                                // onChange={checkSelectedValue}
                                                // checked={selected === 2}
                                                // onChange={onSelect}
                                            />
                                        ))}
                                    </Box>
								</Box>
							</FormElementWrapper>
                            <FormElementWrapper>
								<Box className="formEleContainer">
									<LabelStyled className="labelForm">
										{t('date')}
									</LabelStyled>
                                    <DatePickerDefault
                                        name={"date"}
                                        type={"input"}
                                        placeholder={t("date")}
                                        icon={DateIcon}
                                    />
                                    {/* <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        dateFormat="EEE, dd MMM"
                                        customInput={
                                            <input value={endDate ? customDateFormat(endDate) : ""} readOnly />
                                        }
                                    /> */}
								</Box>
							</FormElementWrapper>
                            <ValidatedInput
                                name="number"
                                type="input"
                                fieldType="text"
                                placeholder={"number"}
                                label="number"
                            />
                            <ValidatedInput
                                name="title"
                                type="input"
                                fieldType="text"
                                placeholder={"title"}
                                label="title"
                            />
                            <ValidatedInput
                                name="from"
                                type="input"
                                fieldType="text"
                                placeholder={"from"}
                                label="from"
                            />
                            <ValidatedInput
                                name="to"
                                type="input"
                                fieldType="text"
                                placeholder={"to"}
                                label="to"
                            />
                                
							<Box className="innerFoHead">
								<SubHeadingStyled size="16px" color="var(--text)">
									{t("ArchiveSettings")}
								</SubHeadingStyled>
							</Box>
                            
							{addArchiveSettingRadio.map((x, i) => (
                                <Box className={`w-100 p-2 separation-border`}>
									<FormRadio
										name="cabinet_setting"
										label={t(x.name)}
										subLabel={t(x.label)}
										value={x.id}
										checked={checkSelectedValueID === x.id}
                    					onChange={checkSelectedValue}
									// checked={selected === 2}
									// onChange={onSelect}
									/>
									{(checkSelectedValueID === 3 || checkSelectedValueID === 4) && 
 										x.id === checkSelectedValueID && (
										<Box className="addUserCont">
											<AddUsers
												title="add_users"
												icon={<SingleProfile />}
												selPlaceholder="SelectUser"
												mainParents={usersList}
											/>
										</Box>
										
									)}
									
									{(checkSelectedValueID === 5) && 
 										x.id === checkSelectedValueID && (
										<Box className="addUserCont">
											<AddSingleUser
												title="Add_Department"
												icon={<Plus />}
												selPlaceholder="Select_One_Department"
												mainParents={departmentsList}
											/>
										</Box>
									)}
									{(checkSelectedValueID === 6) && 
 										x.id === checkSelectedValueID && (
										<Box className="addUserCont">
											<AddUsers
												title="Add_Department"
												icon={<Plus />}
												selPlaceholder="select_department"
												mainParents={departmentsList}
											/>
										</Box>
									)}
								</Box>
							))}
                            <Box className="innerFoHead">
                                <SubHeadingStyled size="16px" color="var(--text)">
                                    {t("Access_granted_through_request")}
                                </SubHeadingStyled>
                            </Box>
                            <FormElementWrapper className="d-flex gap-2">
                                <UserUpload>
                                    <Box className="userPlaceholder">
                                        <img src={Profile} alt="user" />
                                    </Box>
                                    <Box className="abtUser">
                                        <SpanStyled color="var(--text)" size="14px">
                                            Cameron Williamson
                                        </SpanStyled>
                                    </Box>
                                    <MenuTriggerCross className="removeTaskTrigger" />
                                </UserUpload>
                                <UserUpload>
                                    <Box className="userPlaceholder">
                                        <img src={Profile2} alt="user" />
                                    </Box>
                                    <Box className="abtUser">
                                        <SpanStyled color="var(--text)" size="14px">
                                            Brooklyn Simmons
                                        </SpanStyled>
                                    </Box>
                                    <MenuTriggerCross className="removeTaskTrigger" />
                                </UserUpload>
                            </FormElementWrapper>
							<SeparatorBorder />
							<Box className="commonBtns w-100">
								<ButtonStyled bg="var(--bgSecondary)" label={t("Cancel")} color="var(--text2)"></ButtonStyled>
								<ButtonStyled label={t("Create")}></ButtonStyled>
							</Box>
						</FormDataContainer>
					</Col>
				</Row>
			</Box>
		</ProfileWrapper>
	);
};

export default EditArchive;
