import styled from "styled-components";

export const HistoryModalWrapper = styled.div`
  .history-sub-headings {
    background-color: var(--bgSecondary);
    padding: 10px 15px;
    border-radius: 10px;
    margin-bottom: 1rem;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 3px;
  }
  table th {
    border: none;
  }
  table tr td {
    border-top: 1px solid var(--borderLight);
    border-bottom: 1px solid var(--borderLight);
  }
  table tr td:first-child {
    border-left: 1px solid var(--borderLight);
    border-radius: 10px 0 0 10px;
  }

  table tr td:last-child {
    border-right: 1px solid var(--borderLight);
    border-radius: 0 10px 10px 0;
  }
  body.rtl & {
    table tr td:first-child {
      border-left: none;
      border-right: 1px solid var(--borderLight);
      border-radius: 0 10px 10px 0;
    }

    table tr td:last-child {
      border-left: 1px solid var(--borderLight);
      border-right: none;
      border-radius: 10px 0 0 10px;
    }
  }
`;
