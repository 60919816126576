import styled from 'styled-components';

// Single styled wrapper
export const InfoListWrapper = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .row {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #e6e6e6;
  }

  .row:last-child {
    border-bottom: none;
  }

  .label {
    color: var(--text);
    font-weight: 400;
  }

  .value {
    color: var(--text);
    font-weight: 600;
  }

  .invoice-link {
    display: flex;
    align-items: center;
    color: #007bff;
    text-decoration: none;
    font-weight: 600;
    margin-top: 20px;

    svg {
      margin-right: 8px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;