import TooltipInfo from "../../components/common/tooltip/InfoTooltip";
import ProfileTooltip from "../../components/common/tooltip/ProfileTooltip";
import ReasonTooltip from "../../components/common/tooltip/ReasonTooltip";
import {
  GreenSuccessCheck,
  RedCancelIcon,
} from "../../components/styled/AllImages";
import {
  Box,
  ButtonStyled,
  DateInfo,
  LazyImage,
  SpanStyled,
  StatusText,
  SubHeadingStyled,
  UserInfo,
} from "../../components/styled/Elements";
import { CustomCheckbox } from "../../components/styled/FormElements";

export const cabinetColumns = [
  { label: "id", name: "id" },
  { label: "name", name: "name" },
  // { label: "email", name: "email" },
  { label: "actions", name: "actions" },
];

export const receivedReqColumns = [
  { label: "id", name: "id" },
  {
    label: "status",
    name: "status",
    render: (status, _, t) => (
      <SpanStyled
        style={{
          color: status == "1" ? "var(--themeColor2)" : "var(--redColor)",
          fontWeight: "bold",
        }}
      >
        {status == "1" ? t("Open") : t("closed")}
      </SpanStyled>
    ),
  },
  {
    label: "requester",
    name: "requester",
    render: (requester, row, t) => (
      <UserInfo
        name={requester.name}
        role={t(requester.role)}
        avatar={requester.avatar}
      />
    ),
  },
  {
    label: "access_requested_for",
    name: "accessRequestedFor",
    render: (accessValue, row, t) => (
      <Box className="d-flex align-items-center gap-2">
        <TooltipInfo data={accessValue.infoData} />
        {t(accessValue.utilityName)}
      </Box>
    ),
  },
  {
    label: "decision",
    name: "decision",
    render: (decision, row, t, setShowModal) => (
      <Box>
        {decision == "1" ? (
          <Box className="d-flex">
            <SpanStyled
              color="var(--themeColor2)"
              border="var(--themeColor2)"
              weight={700}
              radius="10px"
              className="py-2 px-3"
            >
              <Box className="d-flex align-items-center gap-1">
                <GreenSuccessCheck />
                {t("approved")}
              </Box>
            </SpanStyled>
          </Box>
        ) : decision == "2" ? (
          <Box className="d-flex align-items-center gap-2">
            <SpanStyled
              color="var(--redColor)"
              border="var(--redColor)"
              weight={700}
              radius="10px"
              className="py-2 px-3"
            >
              <Box className="d-flex align-items-center gap-1">
                <RedCancelIcon />
                {t("rejected")}
              </Box>
            </SpanStyled>
            <ReasonTooltip data={row.reason} />
          </Box>
        ) : (
          <Box className="d-flex gap-2">
            <ButtonStyled
              bg="var(--themeColor2)"
              size="14px"
              label={t("approve")}
            />
            <ButtonStyled
              bg="var(--redColor)"
              size="14px"
              label={t("reject")}
              onClick={() => setShowModal(true)}
            />
          </Box>
        )}
      </Box>
    ),
  },
  {
    label: "close_date",
    name: "closeDate",
    render: (date) => (date ? <DateInfo date={date} /> : "-"),
  },
];
export const sentReqColumns = [
  { label: "id", name: "id" },
  {
    label: "status",
    name: "status",
    render: (status, _, t) => (
      <SpanStyled
        style={{
          color: status == "1" ? "var(--themeColor2)" : "var(--redColor)",
          fontWeight: "bold",
        }}
      >
        {status == "1" ? t("Open") : t("closed")}
      </SpanStyled>
    ),
  },
  {
    label: "benefactor",
    name: "benefactor",
    render: (benefactor, row, t) => (
      <UserInfo
        name={benefactor.name}
        role={t(benefactor.role)}
        avatar={benefactor.avatar}
      />
    ),
  },
  {
    label: "access_requested_for",
    name: "accessRequestedFor",
    render: (accessValue, row, t) => (
      <Box className="d-flex align-items-center gap-2">
        <TooltipInfo data={accessValue.infoData} />
        {t(accessValue.utilityName)}
      </Box>
    ),
  },
  {
    label: "decision",
    name: "decision",
    render: (decision, row, t, setCancelModal) => (
      <Box>
        {decision == "1" ? (
          <Box className="d-flex">
            <SpanStyled
              color="var(--themeColor2)"
              border="var(--themeColor2)"
              weight={700}
              radius="10px"
              className="py-2 px-3"
            >
              <Box className="d-flex align-items-center gap-1">
                <GreenSuccessCheck />
                {t("approved")}
              </Box>
            </SpanStyled>
          </Box>
        ) : decision == "2" ? (
          <Box className="d-flex align-items-center gap-2">
            <SpanStyled
              color="var(--redColor)"
              border="var(--redColor)"
              weight={700}
              radius="10px"
              className="py-2 px-3"
            >
              <Box className="d-flex align-items-center gap-1">
                <RedCancelIcon />
                {t("rejected")}
              </Box>
            </SpanStyled>
            <ReasonTooltip data={row.reason} />
          </Box>
        ) : (
          <Box className="d-flex gap-2">
            <ButtonStyled
              bg="var(--redColor)"
              size="14px"
              label={t("Cancel")}
              onClick={() => setCancelModal(true)}
            />
          </Box>
        )}
      </Box>
    ),
  },
  {
    label: "close_date",
    name: "closeDate",
    render: (date) => (date ? <DateInfo date={date} /> : "-"),
  },
];
export const historyColumns = [
  {
    label: "id",
    name: "id",
  },
  {
    label: "date",
    name: "date",
    render: (date) => (date ? <DateInfo date={date} /> : "-"),
  },
  {
    label: "number",
    name: "number",
  },
  {
    label: "archive_title",
    name: "archiveTitle",
  },
  {
    label: "no_of_view",
    name: "views",
  },
  {
    label: "no_of_downloads",
    name: "downloads",
  },
  {
    label: "actions",
    name: "actions",
  },
];
export const historyDetailColumns = [
  {
    label: "id",
    name: "id",
  },
  {
    label: "date",
    name: "date",
    render: (date) => (date ? <DateInfo date={date} /> : "-"),
  },
  {
    label: "username",
    name: "username",
  },
  {
    label: "user_info",
    name: "user_info",
    render: (userInfo) => <TooltipInfo data={userInfo} />,
  },
  {
    label: "view",
    name: "view",
    render: (view) => (view ? <GreenSuccessCheck /> : <RedCancelIcon />),
  },
  {
    label: "download",
    name: "download",
    render: (download) =>
      download ? <GreenSuccessCheck /> : <RedCancelIcon />,
  },
];
export const usersListColumns = [
  {
    label: "id",
    name: "id",
  },
  {
    label: "status",
    name: "status",
    render: (status, row, t) =>
      status == "1" ? (
        <StatusText status={status} text={t("active")} />
      ) : (
        <StatusText status={status} text={t("deactive")} />
      ),
  },
  {
    label: "type",
    name: "type",
    render: (type, row, t) => t(type)
  },
  {
    label: "user",
    name: "user",
    render: (user) => (
      <UserInfo name={user.name} role={user.role} avatar={user.image} />
    ),
  },
  {
    label: "department",
    name: "department",
    render: (department, _, t) => (
      <Box>
        <SubHeadingStyled size="14px" color="var(--text)" weight="bold" className="text-nowrap">
          {t(department.name)}
        </SubHeadingStyled>
        <SpanStyled size="12px" weight="light" color="var(--text2)">
          {department.branch}
        </SpanStyled>
      </Box>
    ),
  },
  {
    label: "email",
    name: "email",
  },
  {
    label: "phone",
    name: "phone",
    render: (phone) => (
      <Box className="text-nowrap">
        {phone}
      </Box>
    )
  },
  {
    label: "actions",
    name: "actions",
  },
];
export const archiveColumns = [
  {
    label: "id",
    name: "id",
    
  },
  {
    label: "date",
    name: "date",
    render: (date) => (date ? <DateInfo date={date} /> : "-"),
  },
  {
    label: "title",
    name: "title",
  },
  {
    label: "number",
    name: "number",
  },
  {
    label: "from",
    name: "from",
  },
  {
    label: "to",
    name: "to",
  },
  {
    label: "actions",
    name: "actions",
  },
];

export const userPermissionColumns = [
  {
    title: "",
    name: "module_name",
    render: (value, _, t) => <SpanStyled size="16px" color="var(--text2)">{t(value)}</SpanStyled>,
  },
  {
    title: "all",
    name: "all",
    render: (value) => <CustomCheckbox checked={value} />,
  },
  {
    title: "Create",
    name: "create",
    render: (value) => <CustomCheckbox checked={value} />,
  },
  {
    title: "view",
    name: "view",
    render: (value) => <CustomCheckbox checked={value} />,
  },
  {
    title: "Open",
    name: "open",
    render: (value) => <CustomCheckbox checked={value} />,
  },
  {
    title: "edit",
    name: "edit",
    render: (value) => <CustomCheckbox checked={value} />,
  },
];

export const hqBranchColumns = [
  {
    label: 'id',
    name: 'id',
  },
  {
    label: 'type',
    name: 'type',
  },
  {
    label: 'name',
    name: 'name',
    render: (name, _, t) => (
      <SpanStyled size="16px" weight="800" color="var(--text)">
        {t(name)}
      </SpanStyled>
    ),
  },
  {
    label: 'City',
    name: 'city',
    render: (city) => city,
  },
  {
    label: 'no_of_departments',
    name: 'no_of_departments',
  },
  {
    label: 'no_of_users',
    name: 'no_of_users',
  },
  {
    label: 'actions',
    name: 'actions',
  },
];


export const branchesColumns = [
    {
      label: "BranchnameEN",
      name: "BranchnameEN",
    },
    {
      label: "BranchnameAR",
      name: "BranchnameAR",
    },
    {
      label: "City",
      name: "City",
    },
    
];
export const headquartersColumns = [
    {
      label: "HeadquarternameEN",
      name: "HeadquarternameEN",
    },
    {
      label: "HeadquarternameAR",
      name: "HeadquarternameAR",
    },
    {
      label: "City",
      name: "City",
    },
    
];
export const tempADaccessColumns = [
    {
        label: "UserName",
        name: "user",
        render: (user) => (
          <UserInfo name={user.name} role={user.role} avatar={user.image} />
        ),
    },
    {
      label: "startDate",
      name: "startDate",
    },
    {
      label: "endDate",
      name: "endDate",
    },
    
];

export const adminsListColumns = [
    {
      label: "id",
      name: "id",
    },
    {
      label: "type",
      name: "type",
    },
    {
        label: "department",
        name: "department",
        render: (department, _, t) => {
            /* const departmentInfo = [
              {
                title: "Department Name",
                value: department.name,
              },
              {
                title: "Branch",
                value: department.branch,
              },
            ]; */
            const departmentInfo = department.department_info; // Access the department_info array
            return (
              <Box className="d-flex justify-content-between gap-1">
                <Box>
                    <Box className="d-flex align-items-center">
                    <SubHeadingStyled size="14px" color="var(--text)" weight="bold" className="text-nowrap">
                        {t(department.name)}
                    </SubHeadingStyled>
                    
                    </Box>
                    <SpanStyled size="12px" weight="light" color="var(--text2)">
                    {department.branch}
                    </SpanStyled>
                </Box>
                {/* <TooltipInfo data={departmentInfo} /> */}
                <ProfileTooltip 
                  data={departmentInfo}
                  userName={department.userName}
                  userRole={department.userRole}
                  userImage={department.userImage}
                />
              </Box>
            );
        },
        
    },
    {
        label: "user",
        name: "user",
        render: (user) => (
            <UserInfo name={user.name} role={user.role} avatar={user.image} />
        ),
    },
    {
      label: "no_of_licenses",
      name: "no_of_licenses",
    },
    {
        label: "no_of_users",
        name: "no_of_users",
    },
    {
        label: "no_of_licenses_left",
        name: "no_of_licenses_left",
    },
    {
        label: "actions",
        name: "actions",
    },
];

export const adminsViewsListColumns = [
    {
      label: "id",
      name: "id",
    },
    {
        label: "status",
        name: "status",
        render: (status, row, t) =>
          status == "1" ? (
            <StatusText status={status} text={t("active")} />
          ) : (
            <StatusText status={status} text={t("deactive")} />
          ),
    },
    {
        label: "user",
        name: "user",
        render: (user) => (
            <UserInfo name={user.name} role={user.role} avatar={user.image} />
        ),
        
    },
    {
        label: "actions",
        name: "actions",
    },
];

export const departmentColumns = [
  {
      label: "id",
      name: "id",
  },
  {
      label: "name",
      name: "name",
  },
  {
      label: "City",
      name: "city",
  },
  {
      label: "contact_email",
      name: "contactEmail",
  },
  {
      label: "contact_phone",
      name: "contactPhone",
  },
  {
      label: "actions",
      name: "actions",
  },
];
export const editTempADaccessColumns = [
    {
        label: "UserName",
        name: "user",
        render: (user) => (
          <UserInfo name={user.name} role={user.role} avatar={user.image} />
        ),
    },
    {
      label: "startDate",
      name: "startDate",
    },
    {
      label: "endDate",
      name: "endDate",
    },
    {
        label: "actions",
        name: "actions",
    },
];