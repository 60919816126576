import React from 'react'
import { Box, FormButtonStyled, HeadingStyled, TextStyled } from '../../../../components/styled/Elements'
import { t } from 'i18next'
import ValidatedInput from '../../../../components/common/inputFields/ValidatedInput'

const PhoneSignin1 = ({register, handleSteps}) => {
  return (
    <Box>
        <Box className="mb-4">
        <HeadingStyled size="24px" weight="bold" color="var(--text)">
          {t("wellcome_back")}
        </HeadingStyled>
        <TextStyled size="16px" color="var(--text2)">
          {t("please_enter_phone_num_for_otp")}
        </TextStyled>
      </Box>
      <Box>
        <Box>
          <ValidatedInput
            name="phone"
            label={"phone"}
            type="input"
            fieldType="text"
            placeholder={"phonePlaceholder"}
            register={register}
            className={"mb-3 combined flex-wrap p-0"}
            bg="var(--white)"
          />
          <FormButtonStyled
            label={t("send_otp")}
            color="var(--white)"
            bg="var(--themeColor)"
            className="w-100"
            clName="w-100 my-4"
            onClick={handleSteps}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default PhoneSignin1