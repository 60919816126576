import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  ButtonStyled,
  HeadingStyled,
  LabelStyled,
  TextStyled,
} from "../../../styled/Elements";
import { Modal } from "react-bootstrap";
import ValidatedInput from "../../../common/inputFields/ValidatedInput";
import styled from "styled-components";

const ReasonModal = ({ show, onHide, onChange }) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={onHide} centered>
      <ReasonModalWrapper>
        <Box className="p-4">
          <Box className="mb-4">
            <Box>
              <HeadingStyled size="24px" weight="bold" color="var(--text)">
                {t("rejection_reason")}
              </HeadingStyled>
              <TextStyled size="16px" color="var(--text2)">
                {t("enter_reason_to_reject")}
              </TextStyled>
            </Box>
          </Box>
          <Box className="d-flex gap-5">
            <LabelStyled size="16px" color="var(--text)">
              {t("reason")}
            </LabelStyled>
            <ValidatedInput
              name={"reason"}
              type={"textarea"}
              fieldType={"text"}
              placeholder={t("type_here")}
            />
          </Box>
          <Box className="d-flex justify-content-end">
            <Box className="mt-4 d-flex gap-3">
              <ButtonStyled
                label={t("Cancel")}
                bg={"var(--bgSecondary)"}
                color={"var(--text2)"}
                onClick={onHide}
              />
              <ButtonStyled label={t("reject")} />
            </Box>
          </Box>
        </Box>
      </ReasonModalWrapper>
    </Modal>
  );
};

export default ReasonModal;

const ReasonModalWrapper = styled.div``