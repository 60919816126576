import styled from "styled-components";

export const LoginWrapper = styled.div`
  .or-separator {
    position: relative;
    &::before,
    &::after {
      content: "";
      flex: 1;
      border-bottom: 2px solid var(--borderLight);
    }

    &::before {
      margin-right: 10px;
    }

    &::after {
      margin-left: 10px;
    }
  }
`;
