import React, { useState } from "react";
import { BackButton, Box, ButtonStyled, DataContainerHeader, FormElementWrapper, FormEleWrapper, HeadingStyled, LabelStyled, SelectionDropdown, Separator, SeparatorBorder, SpanStyled, SubHeadingStyled, TextStyled } from "../../../components/styled/Elements";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SettingsWrapper, DataContainer, DataContainerForm, FormDataContainer, ProfileSidebarWrapper, ProfileWrapper } from "./styled";
import { Camera, DateIcon, LogoutIcon, Plus, ProfileImgLarge, SingleProfile } from "../../../components/styled/AllImages";
import { useNavigate } from "react-router-dom";
// import FloatingInput from "../../../../../components/common/inputFields/FloatingInput";
// import { RadioButton } from "../../../../../components/styled/FormElements";
// import AddUsers from "../addFile/components/addUsers/MainData";
import { addArchiveRadio, addArchiveSettingRadio, addFileRadio, departmentsList, estCityOptions, estCountryOptions, estSectorOptions, estTypeOptions, statusArchiveRadio, usersList } from "../../../helpers/dummyConstants";
import FormRadio from "../../../components/common/radioButtons/FormRadio";
// import AddSingleUser from "../addFile/components/addSingleUser/MainData";
import ValidatedInput from "../../../components/common/inputFields/ValidatedInput";
import DatePicker from "react-datepicker";
import DocumentUpload from "../../../components/common/uploadFiles/documentUpload";
import ImageUpload from "../../../components/common/uploadFiles/imagesUpload";
// import CustomRadioButton from "../../../../../components/common/radioButtons/CustomRadio";
import DatePickerDefault from "../../../components/common/inputFields/DatePicker";
import Breadcrumbs from "../../../components/admin/Breadcrumbs/Breadcrumbs";


const EditEstablishment = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [checkSelectedValueID, setcheckSelectedValueID] = useState(null);
    // Initialize the selected value state with the first radio button's id
    const [selectedRadio, setSelectedRadio] = useState(addArchiveRadio[0].id);

	const checkSelectedValue = (e) => {
		setcheckSelectedValueID(Number(e.target.value));
	};

    

    // Handle radio change
    const handleRadioChange = (event) => {
        setSelectedRadio(parseInt(event.target.value));
    };
    

    // Determine accept and uploadText based on selected radio button
    const getUploadSettings = () => {
        switch (selectedRadio) {
        case 2:
            return {
            accept: "video/*",
            uploadText: "UploadVideo",
            };
        case 3:
            return {
            accept: "audio/*",
            uploadText: "UploadVoice",
            };
        case 5:
            return {
            accept: "*",
            uploadText: "upload",
            };
        default:
            return {
            accept: ".pdf, .doc, .docx, .txt, .odt, .ppt, .pptx, .xls, .xlsx, .rtf, .csv, .ods, .odp, .epub", // Default for other radios
            uploadText: "UploadDocument",
            };
        }
    };
    const { accept, uploadText } = getUploadSettings();
    // Helper function to translate options
    const translateOptions = (options, t) => 
        options.map(option => ({
            label: t(option.label),  // Translate the label
            value: option.value
        }));
    // Use helper function to translate each dropdown's options
    const estTypeOptionsTrans = translateOptions(estTypeOptions, t);
    const estSectorOptionsTrans = translateOptions(estSectorOptions, t);
    const estCountryOptionsTrans = translateOptions(estCountryOptions, t);
    const estCityOptionsTrans = translateOptions(estCityOptions, t);
    

	return (
		<SettingsWrapper>
			<Box className="content-head py-3 px-3 px-md-4">
                <SubHeadingStyled size="16px" color="var(--text)">
					{t("settings")}
				</SubHeadingStyled>
			</Box>
			<Box className="content-container">
				<Row className="mt-3">
					<Col md={12}>
						<FormDataContainer className="dataContainer">
						    <DataContainerHeader
                                label={t("establishment")}
                            />
                            <FormElementWrapper className="combined pt-3">
                                <Box className="formEleContainer">
									<LabelStyled className="labelForm">
										{t('type')}
									</LabelStyled>
                                    <SelectionDropdown
                                        name={"type"}
                                        placeholder={t("select_type")}
                                        options={estTypeOptionsTrans}
                                    />
                                </Box>
                            </FormElementWrapper>
                            <FormElementWrapper className="combined">
                                <Box className="formEleContainer">
									<LabelStyled className="labelForm">
										{t('Sector')}
                                        <span className="staric">*</span>
									</LabelStyled>
                                    <SelectionDropdown
                                        name={"sector"}
                                        placeholder={t("select_sector")}
                                        options={estSectorOptionsTrans}
                                    />
                                </Box>
                            </FormElementWrapper>
                            <ValidatedInput
                                name="name"
                                type="input"
                                fieldType="text"
                                placeholder="John Smith"
                                label="name"
                                className="combined"
                                staric="staric"
                            />
                            <ValidatedInput
                                name="description"
                                type="textarea"
                                fieldType="text"
                                placeholder={"DescriptionLocation"}
                                label="Description"
                                className="combined"
                            />
                            <SeparatorBorder className="mt-2" />
                            <Box className="innerFoHead">
								<SubHeadingStyled size="16px" color="var(--text)">
									{t("location")}
								</SubHeadingStyled>
							</Box>
                            <FormElementWrapper className="combined pt-3">
                                <Box className="formEleContainer">
									<LabelStyled className="labelForm">
										{t('Country')}
                                        <span className="staric">*</span>
									</LabelStyled>
                                    <SelectionDropdown
                                        name={"type"}
                                        placeholder={t("Country")}
                                        options={estCountryOptionsTrans}
                                    />
                                </Box>
                            </FormElementWrapper>
                            <FormElementWrapper className="combined">
                                <Box className="formEleContainer">
									<LabelStyled className="labelForm">
										{t('City')}
                                        <span className="staric">*</span>
									</LabelStyled>
                                    <SelectionDropdown
                                        name={"type"}
                                        placeholder={t("City")}
                                        options={estCityOptionsTrans}
                                    />
                                </Box>
                            </FormElementWrapper>
                            <ValidatedInput
                                name="address"
                                type="input"
                                fieldType="text"
                                placeholder={"AddressFull"}
                                label="Address"
                                className="combined"
                            />
                            <SeparatorBorder className="mt-2" />
                            <Box className="innerFoHead">
								<SubHeadingStyled size="16px" color="var(--text)">
									{t("ContactDetails")}
								</SubHeadingStyled>
							</Box>
                            <ValidatedInput
                                name="contactno"
                                type="input"
                                fieldType="text"
                                placeholder={"+966 963852741"}
                                label="ContactNo"
                                className="combined"
                                staric="staric"
                            />
                            <ValidatedInput
                                name="email"
                                type="input"
                                fieldType="text"
                                placeholder={"johnsmith@example.com"}
                                label="Email"
                                className="combined"
                                staric="staric"
                            />
                            <ValidatedInput
                                name="contactperson"
                                type="input"
                                fieldType="text"
                                placeholder={"John Smith"}
                                label="ContactPerson"
                                className="combined"
                            />
                            <SeparatorBorder className="mt-2" />
                            <FormElementWrapper className="combined pt-3">
                                <Box className="formEleContainer">
									<LabelStyled className="labelForm">
										{t('UploadLogo')}
									</LabelStyled>
                                    <ImageUpload 
                                        UploadImage="UploadJobID"
                                    />
                                </Box>
                            </FormElementWrapper>
                            
							
							<SeparatorBorder className="mt-2" />
							<Box className="commonBtns w-100">
								<ButtonStyled bg="var(--bgSecondary)" label={t("Cancel")} color="var(--text2)"></ButtonStyled>
								<ButtonStyled label={t("Save")}></ButtonStyled>
							</Box>
						</FormDataContainer>
					</Col>
				</Row>
			</Box>
		</SettingsWrapper>
	);
};

export default EditEstablishment;
