import React from "react";
import { Box, LabelStyled } from "../../styled/Elements";
import { Form, FormGroup } from "react-bootstrap";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const FloatingInput = ({
  name,
  type = "text",
  fieldType,
  placeholder,
  label,
  value,
  icon: Icon,
  register,
  validationRules,
  className,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <FloatingInputWrapper {...rest}>
      <FormGroup controlId={name}>
      
        <Box className="position-relative">
          {Icon && (
            <Box className="icon-container position-absolute">
              <Icon />
            </Box>
          )}
          <Form.Control
            as={type}
            type={fieldType}
            value={value}
            disabled={disabled}
            placeholder={t(placeholder)}
            {...(register && register(name, validationRules))}
            className={`${Icon ? "with-icon" : ""}`}
          />
        </Box>
      </FormGroup>
    </FloatingInputWrapper>
  );
};

export default FloatingInput;

const FloatingInputWrapper = styled.div`
  width: 100%;
  
  .form-control {
    border: none;
    background-color: ${({bg}) => bg ? bg : "var(--bgSecondary)"};
    height: ${({height}) => height ? height : "48px"};
    box-shadow: none;
    outline: none;
    border-radius: 10px;
    color: var(--text);
    font-size: 14px;
    &::placeholder {
      color: var(--text2);
    }
  }

  .form-control:focus {
    box-shadow: none;
    outline: none;
    border: none;
  }

  .icon-container {
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    color: var(--textPlaceholder2);
    body.rtl &{
      left: unset;
      right: 15px;
    }
  }

  .with-icon {
    padding-left: 45px;
    body.rtl &{
      padding-left: .375rem;
      padding-right: 45px;
    }
  }
`;
