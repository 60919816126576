import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  ButtonStyled,
  HeadingStyled,
  LazyImage,
  TextStyled,
} from "../../../styled/Elements";
import { Col, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import { RequestCancelImg } from "../../../styled/AllImages";

const CancelReqModal = ({ show, onHide }) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={onHide} centered>
      <CancelReqWrapper>
        <Box className="p-4">
          <Row className="mb-2 mt-4">
            <Col lg={5} sm={12}>
              <Box className="d-flex align-items-center justify-content-center">
                <LazyImage src={RequestCancelImg} />
              </Box>
            </Col>
            <Col lg={7} sm={12}>
              <Box>
                <HeadingStyled size="24px" weight="bold" color="var(--text)">
                  {t("cancel_request")}
                </HeadingStyled>
                <TextStyled size="16px" color="var(--text2)">
                  {t("sure_you_want_to_cancel_req")}
                </TextStyled>
              </Box>
            </Col>
          </Row>
          <Box className="d-flex justify-content-end">
            <Box className="mt-4 d-flex gap-3">
              <ButtonStyled
                label={t("Cancel")}
                bg={"var(--bgSecondary)"}
                color={"var(--text2)"}
                onClick={onHide}
              />
              <ButtonStyled label={t("reject")} />
            </Box>
          </Box>
        </Box>
      </CancelReqWrapper>
    </Modal>
  );
};

export default CancelReqModal;

export const CancelReqWrapper = styled.div``;
