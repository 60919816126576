import React, { useState } from "react";
import { BackButton, Box, ButtonStyled, DataContainerHeader, FormElementWrapper, FormEleWrapper, HeadingStyled, LabelStyled, SelectionDropdown, Separator, SeparatorBorder, SpanStyled, SubHeadingStyled, TextStyled } from "../../../components/styled/Elements";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SettingsWrapper, DataContainer, DataContainerForm, FormDataContainer, ProfileSidebarWrapper, ProfileWrapper, BoxTblHead } from "./styled";
import { Camera, DateIcon, LogoutIcon, Plus, PlusIcon, ProfileImgLarge, SingleProfile } from "../../../components/styled/AllImages";
import { useNavigate } from "react-router-dom";
// import FloatingInput from "../../../../../components/common/inputFields/FloatingInput";
// import { RadioButton } from "../../../../../components/styled/FormElements";
// import AddUsers from "../addFile/components/addUsers/MainData";
import { addArchiveRadio, addArchiveSettingRadio, adDepartmentOptions, addFileRadio, adHqBranchOptions, departmentsList, editTempADaccessRows, estCityOptions, estCountryOptions, estSectorOptions, estTypeOptions, historyData, statusArchiveRadio, tempADaccessRows, usersList } from "../../../helpers/dummyConstants";
import FormRadio from "../../../components/common/radioButtons/FormRadio";
// import AddSingleUser from "../addFile/components/addSingleUser/MainData";
import ValidatedInput from "../../../components/common/inputFields/ValidatedInput";
import DatePicker from "react-datepicker";
import DocumentUpload from "../../../components/common/uploadFiles/documentUpload";
import ImageUpload from "../../../components/common/uploadFiles/imagesUpload";
// import CustomRadioButton from "../../../../../components/common/radioButtons/CustomRadio";
import DatePickerDefault from "../../../components/common/inputFields/DatePicker";
import Breadcrumbs from "../../../components/admin/Breadcrumbs/Breadcrumbs";
import ListTable from "../../../components/common/tables/ListTable";
import { editTempADaccessColumns, tempADaccessColumns } from "../../../helpers/adminConstants/TableConstants";
import HistoryModal from "../../../components/admin/Modals/HistoryModal/HistoryModal";
import SelectUserModal from "../../../components/admin/Modals/SelectUser/SelectUserModal";


const EditAdminDirector = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
    const [showSelectUserModal, setShowSelectUserModal] = useState(false);

    const handleInfo = (row) => {
        setShowSelectUserModal(true);
    };

    

    

    // Helper function to translate options
    const translateOptions = (options, t) => 
        options.map(option => ({
            label: t(option.label),  // Translate the label
            value: option.value
        }));
    // Use helper function to translate each dropdown's options
    const adHqBranchOptionsTrans = translateOptions(adHqBranchOptions, t);
    const adDepartmentOptionsTrans = translateOptions(adDepartmentOptions, t);

    

	return (
		<SettingsWrapper>
			<Box className="content-head py-3 px-3 px-md-4">
                <SubHeadingStyled size="16px" color="var(--text)">
					{t("settings")}
				</SubHeadingStyled>
			</Box>
			<Box className="content-container">
				<Row className="mt-3">
					<Col md={12}>
						<FormDataContainer className="dataContainer">
						    <DataContainerHeader
                                label={t("admin_director")}
                            />
                            <ValidatedInput
                                name="name"
                                type="input"
                                fieldType="text"
                                placeholder="John Smith"
                                label="name"
                                className="combined pt-3"
                                staric="staric"
                            />
                            <ValidatedInput
                                name="jobtitle"
                                type="input"
                                fieldType="text"
                                placeholder="Manager"
                                label="JobTitle"
                                className="combined"
                                staric="staric"
                            />
                            <ValidatedInput
                                name="jobrole"
                                type="input"
                                fieldType="text"
                                placeholder="ProjectManager"
                                label="JobRole"
                                className="combined"
                                staric="staric"
                            />
                            
                            <FormElementWrapper className="combined">
                                <Box className="formEleContainer">
									<LabelStyled className="labelForm">
										{t('HQBranch')}
									</LabelStyled>
                                    <SelectionDropdown
                                        name="hqbranch"
                                        placeholder={t("Select")}
                                        options={adHqBranchOptionsTrans}
                                    />
                                </Box>
                            </FormElementWrapper>
                            <FormElementWrapper className="combined">
                                <Box className="formEleContainer">
									<LabelStyled className="labelForm">
										{t('department')}
                                        <span className="staric">*</span>
									</LabelStyled>
                                    <SelectionDropdown
                                        name={"department"}
                                        placeholder={t("Select")}
                                        options={adDepartmentOptionsTrans}
                                    />
                                </Box>
                            </FormElementWrapper>
                            <SeparatorBorder className="mt-2" />
                            <Box className="innerFoHead">
								<SubHeadingStyled size="16px" color="var(--text)">
									{t("ContactDetails")}
								</SubHeadingStyled>
							</Box>
                            <ValidatedInput
                                name="contactno"
                                type="input"
                                fieldType="text"
                                placeholder={"+966 963852741"}
                                label="ContactNo"
                                className="combined"
                                staric="staric"
                            />
                            <ValidatedInput
                                name="email"
                                type="input"
                                fieldType="text"
                                placeholder={"johnsmith@example.com"}
                                label="Email"
                                className="combined"
                                staric="staric"
                            />
                            {/* <ValidatedInput
                                name="contactperson"
                                type="input"
                                fieldType="text"
                                placeholder={"John Smith"}
                                label="ContactPerson"
                                className="combined"
                            /> */}
                            <SeparatorBorder className="mt-2" />
                            <FormElementWrapper className="combined pt-3">
                                <Box className="formEleContainer">
									<LabelStyled className="labelForm">
										{t('Upload')}
									</LabelStyled>
                                    <ImageUpload 
                                        UploadImage="UploadJobID"
                                    />
                                    <ImageUpload 
                                        UploadImage="UploadJobID"
                                    />
                                </Box>
                            </FormElementWrapper>
                            <SeparatorBorder className="mt-2" />
                            <FormElementWrapper className="combined pt-3">
                                <Box className="formEleContainer">
									<BoxTblHead >
                                        <DataContainerHeader
                                            label={t("admin_director")}
                                            btnLabel="add_user"
                                            icon={PlusIcon}
                                            onClick={handleInfo}
                                        />
                                        <Box className="listTableWrapper">
                                            <ListTable
                                                options={["delete"]}
                                                rows={editTempADaccessRows}
                                                columns={editTempADaccessColumns}
                                                className=""
                                            />
                                        </Box>
                                    </BoxTblHead>
                                </Box>
                            </FormElementWrapper>
                            <SelectUserModal
                                title={t("cabinet_history")}
                                show={showSelectUserModal}
                                onHide={() => setShowSelectUserModal(false)}
                                createdBy={historyData.createdBy}
                                editHistory={historyData.editHistory}
                                transferHistory={historyData.transferHistory}
                            />

                            {/* <ValidatedInput
                                name="description"
                                type="textarea"
                                fieldType="text"
                                placeholder={"DescriptionLocation"}
                                label="Description"
                                className="combined"
                            /> */}
                            {/* <SeparatorBorder className="mt-2" />
                            <Box className="innerFoHead">
								<SubHeadingStyled size="16px" color="var(--text)">
									{t("location")}
								</SubHeadingStyled>
							</Box> */}
                            {/* <FormElementWrapper className="combined pt-3">
                                <Box className="formEleContainer">
									<LabelStyled className="labelForm">
										{t('Country')}
                                        <span className="staric">*</span>
									</LabelStyled>
                                    <SelectionDropdown
                                        name={"type"}
                                        placeholder={t("Country")}
                                        options={estCountryOptionsTrans}
                                    />
                                </Box>
                            </FormElementWrapper> */}
                            {/* <FormElementWrapper className="combined">
                                <Box className="formEleContainer">
									<LabelStyled className="labelForm">
										{t('City')}
                                        <span className="staric">*</span>
									</LabelStyled>
                                    <SelectionDropdown
                                        name={"type"}
                                        placeholder={t("City")}
                                        options={estCityOptionsTrans}
                                    />
                                </Box>
                            </FormElementWrapper> */}
                            {/* <ValidatedInput
                                name="address"
                                type="input"
                                fieldType="text"
                                placeholder={"AddressFull"}
                                label="Address"
                                className="combined"
                            />
                            <SeparatorBorder className="mt-2" />
                            
                            <FormElementWrapper className="combined pt-3">
                                <Box className="formEleContainer">
									<LabelStyled className="labelForm">
										{t('UploadLogo')}
									</LabelStyled>
                                    <ImageUpload 
                                        UploadImage="UploadJobID"
                                    />
                                </Box>
                            </FormElementWrapper> */}
                            
							
							<SeparatorBorder className="mt-2" />
							<Box className="commonBtns w-100">
								<ButtonStyled bg="var(--bgSecondary)" label={t("Cancel")} color="var(--text2)"></ButtonStyled>
								<ButtonStyled label={t("Save")}></ButtonStyled>
							</Box>
						</FormDataContainer>
					</Col>
				</Row>
			</Box>
		</SettingsWrapper>
	);
};

export default EditAdminDirector;
