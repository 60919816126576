import styled from "styled-components";

export const HeaderContainer = styled.header`
height: 93px;
  .header-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    background: var(--white);
    box-shadow: 0px 3px 10px #07758473;
    z-index: 200;
  }
  .nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .logo {
    width: 179px;
    cursor: pointer;
    span {
      width: 100%;
    }
    img {
      width: 179px;
      height: auto;
      -webkit-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s;
    }
  }
  .signin-btn {
    box-shadow: 5px 0px 5.64px 5.1px #064a930d;
  }
  .toggleBtn {
    display: none;
  }
  .whtLngWrapper {
    gap: 7px;
  }
  .headerBtn {
    background-color: var(--themeColor);
  }

  .Social-links {
    display: flex;
    gap: 12px;
    cursor: pointer;
  }

  @media (max-width: 1199px) {
    .nav-bar button span {
      font-size: 14px;
    }
  }
`;
