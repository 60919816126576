import React, { useState } from 'react'
import { Box, SubHeadingStyled, TabsList } from '../../../components/styled/Elements'
import { RequestWrapper } from './styled'
import { useTranslation } from 'react-i18next'
import { requestsTabs } from '../../../helpers/adminConstants/AdminConstants'

const Requests = () => {
    const {t} = useTranslation()
    const [activeTab, setActiveTab] = useState(requestsTabs[0])
    const handleTabChange = (tabIndex) => {
        setActiveTab(requestsTabs[tabIndex])
    }
  return (
    <RequestWrapper>
      <Box className="content-head py-3 px-3 px-md-4">
        <SubHeadingStyled size="18px" weight="700" color="var(--text)">
          {t("requests")}
        </SubHeadingStyled>
      </Box>
      <Box>
        <TabsList tabs={requestsTabs} activeTab={activeTab} handleTabChange={handleTabChange} />
      </Box>
      <Box>
        <activeTab.component />
      </Box>
    </RequestWrapper>
  )
}

export default Requests