import React, { useState } from "react";
import { ArchiveWrapper } from "./styled";
import {
	BackButton,
	Box,
	ButtonStyled,
	SpanStyled,
} from "../../../../components/styled/Elements";
import { useTranslation } from "react-i18next";
import FloatingInput from "../../../../components/common/inputFields/FloatingInput";
import {
	PlusIcon,
	ProfileImg,
	SearchIcon,
} from "../../../../components/styled/AllImages";
import ListTable from "../../../../components/common/tables/ListTable";
import { Col, Row } from "react-bootstrap";
import CustomTile from "../../../../components/common/statusTiles/CustomTile";
import { useNavigate } from "react-router-dom";
import { archiveFilters, archiveRows, archiveTileArray } from "../../../../helpers/dummyConstants";
import FormRadio from "../../../../components/common/radioButtons/FormRadio";
import { DateRangePickersBar } from "./components/DateRangePickerBar";
import { archiveColumns } from "../../../../helpers/adminConstants/TableConstants";
import HistoryModal from "../../../../components/admin/Modals/HistoryModal/HistoryModal";


const Archives = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [showHistoryModal, setShowHistoryModal] = useState(false);
	const onViewDetails = () => {
	  navigate("/cabinets/files/archives/archive-details")
	}
    const handleEdit = (row) => {
        navigate("/cabinets/files/archives/edit-archive")
    };
	const handleInfo = (row) => {
		setShowHistoryModal(true);
	  };
	
	  const data = {
		createdBy: {
		  name: "Jenny Wilson",
		  role: "Corporate Administration (HQ Branch)",
		  avatar: ProfileImg,
		  date: "5/30/14",
		  time: "10:32 PM",
		},
		editHistory: [
		  {
			name: "Wade Warren",
			role: "Corporate Administration (CO Branch)",
			avatar: ProfileImg,
			date: "3/4/15",
			time: "12:54 PM",
		  },
		  // ... other edit history entries
		],
		transferHistory: [
		  {
			from: {
			  name: "Floyd Miles",
			  avatar: ProfileImg,
			  role: "Some Role",
			},
			to: {
			  name: "Wade Warren",
			  avatar: ProfileImg,
			  role: "Some Role",
			},
			date: "8/15/17",
			time: "12:54 PM",
		  },
		  {
			from: {
			  name: "Floyd Miles",
			  avatar: ProfileImg,
			  role: "Some Role",
			},
			to: {
			  name: "Wade Warren",
			  avatar: ProfileImg,
			  role: "Some Role",
			},
			date: "8/15/17",
			time: "12:54 PM",
		  },
		  // ... other transfer history entries
		],
	  };
	return (
		<ArchiveWrapper>
			<Box className="content-head py-3 px-3 px-md-4">
				<BackButton
					label={t("human_resources")}
					onClick={() => navigate("/cabinets/files")}
				/>
				<Box className="d-flex gap-2">
					<FloatingInput
						name="search"
						type="input"
						fieldType="text"
						label={t("search")}
						placeholder={t("searchFile")}
						icon={SearchIcon}
						
					/>
					<ButtonStyled label={t("add_archive")} icon={PlusIcon} onClick={() => navigate("/cabinets/files/archives/add-archive")} />
				</Box>
			</Box>
			<Box className="content-container content-container-topBar">
				<Box className="radioFilterContainer">
					{archiveFilters.map((x, i) => (
						<FormRadio
							name="archive_filters"
							label={t(x.name)}
							value={x.id}
							// checked={checkSelectedValueID === x.id}
							// onChange={checkSelectedValue}
							// checked={selected === 2}
							// onChange={onSelect}
						/>
					))}
				</Box>
				<Box className="rangepickerContainer">
					<SpanStyled size="14px" className="wsNowrap w-r-100">
						{t('DateRange')}:
					</SpanStyled>
					<DateRangePickersBar />
				</Box>
				<Box className="searchBarContainer">
					<FloatingInput
						height= "40px"
						name="search"
						type="input"
						fieldType="text"
						label={t("search")}
						placeholder={t("search")}
						bg="var(--white)"
						icon={SearchIcon}
					/>
				</Box>
				
			</Box>

			<Box className="content-container">
				<Row className="justify-content-center">
					{archiveTileArray.map((tile, key) => (
						<Col lg={3} md={6} sm={12} className="my-2">
							<CustomTile key={key} {...tile} />
						</Col>
					))}
				</Row>

			</Box>
			<Box>
				<ListTable
					options={["view", "info", "edit", "transfer", "status", "delete"]}
					rows={archiveRows}
					columns={archiveColumns}
					onViewDetails={onViewDetails}
					onInfoView={handleInfo}
                    onEdit={handleEdit}
				/>
				{/* <ListTable
					options={["open", "info", "edit", "transfer", "status", "delete"]}
					columns={columns}
					rows={rowsData}
					totalCount={totalCount}
					perPage={perPage}
					activePage={activePage}
					setActivePage={setActivePage}
					onEdit={handleEdit}
					onDelete={handleDelete}
					onOpen={handleView}
					onInfoView={handleInfo}
					onStatusToggle={handleStatusToggle}
				/> */}
			</Box>
			<HistoryModal
				title={t("ArchiveHistory")}
				show={showHistoryModal}
				onHide={() => setShowHistoryModal(false)}
				createdBy={data.createdBy}
				editHistory={data.editHistory}
				transferHistory={data.transferHistory}
			/>
		</ArchiveWrapper>
	);
};

export default Archives;
