import React from "react";
import { SpanStyled } from "../../styled/Elements";
import { Col, Row } from "react-bootstrap";

const InformationListItem = ({ label, value }) => {
  return (
    <Row className="justify-content-between">
      <Col lg={4} md={5} sm={6}>
        <SpanStyled className="label">{label}</SpanStyled>
      </Col>
      <Col lg={4} md={5} sm={6}>
        <SpanStyled className="value">{value}</SpanStyled>
      </Col>
    </Row>
  );
};

export default InformationListItem;
