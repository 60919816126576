import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

function CustomDropdown({
  itemsList,
  title,
  ...rest
}) {
  const { t } = useTranslation();
  return (
    <DropdownWrapper {...rest}>
      <DropdownButton
        id="dropdown-basic-button"
        title={title}
        className="custom-dropdown"
      >
        {itemsList && itemsList.length > 0 && itemsList.map((item, key) => (
          <Dropdown.Item as={Link} key={key} to={item.path}>
            {item.icon && <item.icon className={"mx-2"}/>}
            {t(item.label)}
            </Dropdown.Item>
        ))}
      </DropdownButton>
    </DropdownWrapper>
  );
}

export default CustomDropdown;

const DropdownWrapper = styled.div`
  .custom-dropdown .btn {
    display: flex;
    align-items: center;
    padding-top: ${({paddingY}) => paddingY && `${paddingY}px`}!important;
    padding-bottom: ${({paddingY}) => paddingY && `${paddingY}px`}!important;
    padding-right: ${({paddingX}) => paddingX && `${paddingX}px`}!important;
    padding-left: ${({paddingX}) => paddingX && `${paddingX}px`}!important;
  }
  .custom-dropdown .btn {
    &,
    &.show,
    &:focus-visible,
    &:focus,
    &:hover,
    &:active {
      border: none;
      border-radius: 10px;
      padding: 10px;
      background-color: ${({ bg }) => (bg ? bg : "var(--white)")};
      color: ${({ color }) => (color ? color : "var(--text)")};
    }
  }
  .custom-dropdown  {
    .dropdown-item {
      text-align: start;
        padding: 10px;
    }
    .dropdown-item:not(:last-child){
        border-bottom: 1px solid var(--border);
    }
}
`;
