import React, { useState } from "react";
import { CabinetsWrapper } from "./styled";
import {
  BackButton,
  Box,
  ButtonStyled,
  SubHeadingStyled,
} from "../../../../components/styled/Elements";
import { useTranslation } from "react-i18next";
import FloatingInput from "../../../../components/common/inputFields/FloatingInput";
import {
  PlusIcon,
  ProfileImg,
  SearchIcon,
} from "../../../../components/styled/AllImages";
import ListTable from "../../../../components/common/tables/ListTable";
import HistoryModal from "../../../../components/admin/Modals/HistoryModal/HistoryModal";
import { Col, Row } from "react-bootstrap";
import CustomTile from "../../../../components/common/statusTiles/CustomTile";
import { fileTileArray } from "../../../../helpers/adminConstants/AdminConstants";
import { useNavigate } from "react-router-dom";
import TransferModal from "../components/TransferModal";
import Breadcrumbs from "../../../../components/admin/Breadcrumbs/Breadcrumbs";
import DeleteModal from "../../../../components/admin/Modals/DeleteModal/DeleteModal";

const Files = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Table columns configuration
  const columns = [
    { label: t("id"), name: "id" },
    { label: t("FileName"), name: "filename" },
    { label: t("NoofArchives"), name: "archivesnumber" },
    { label: t("Description"), name: "description" },
    { label: t("actions"), name: "actions" }, // Action buttons column
  ];

  // Example data (rows)
  const rows = [
    {
      id: "00123",
      filename: t("HumanResources"),
      archivesnumber: "125",
      description: "Annual financial report with revenue,...",
      status: true,
    },
    {
      id: "00124",
      filename: t("Sales"),
      archivesnumber: "132",
      description: "Annual financial report with revenue,...",
      status: true,
    },
    {
      id: "00125",
      filename: t("Marketing"),
      archivesnumber: "135",
      description: "Annual financial report with revenue,...",
      status: true,
    },
    {
      id: "00126",
      filename: t("Products"),
      archivesnumber: "138",
      description: "Annual financial report with revenue,...",
      status: true,
    },
    // Add more rows as needed...
  ];
  const data = {
    createdBy: {
      name: "Jenny Wilson",
      role: "Corporate Administration (HQ Branch)",
      avatar: ProfileImg,
      date: "5/30/14",
      time: "10:32 PM",
    },
    editHistory: [
      {
        name: "Wade Warren",
        role: "Corporate Administration (CO Branch)",
        avatar: ProfileImg,
        date: "3/4/15",
        time: "12:54 PM",
      },
      // ... other edit history entries
    ],
    transferHistory: [
      {
        from: {
          name: "Floyd Miles",
          avatar: ProfileImg,
          role: "Some Role",
        },
        to: {
          name: "Wade Warren",
          avatar: ProfileImg,
          role: "Some Role",
        },
        date: "8/15/17",
        time: "12:54 PM",
      },
      {
        from: {
          name: "Floyd Miles",
          avatar: ProfileImg,
          role: "Some Role",
        },
        to: {
          name: "Wade Warren",
          avatar: ProfileImg,
          role: "Some Role",
        },
        date: "8/15/17",
        time: "12:54 PM",
      },
      // ... other transfer history entries
    ],
  };
  const perPage = 10; // Items per page
  const totalCount = 20;

  const [activePage, setActivePage] = useState(1);
  const [rowsData, SetRowsData] = useState(rows);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false)

  const handleEdit = (row) => {
    navigate("/cabinets/files/edit-file");
  };
  const handleInfo = (row) => {
    setShowHistoryModal(true);
  };

  const handleDelete = (row) => {
    setShowDeleteModal(true)
  };

  const handleView = (row) => {
    navigate("/cabinets/files/archives");
    console.log("Viewing row:", row);
  };

  const handleTransfer = (row) => {
    setShowTransferModal(true);
  };

  const handleStatusToggle = (row) => {
    const rowIndex = rowsData.indexOf(row);
    const updatedRows = rowsData.map((r, i) => {
      if (i === rowIndex) {
        return { ...r, status: !r.status };
      }
      return r;
    });
    SetRowsData(updatedRows);
  };

  return (
    <CabinetsWrapper>
      <Box className="content-head py-3 px-3 px-md-4">
        <Box className="d-flex align-items-center gap-5">
          <BackButton label={t("files")} onClick={() => navigate("/cabinets")} />
          <Breadcrumbs />
        </Box>
        <Box className="d-flex gap-2">
          <FloatingInput
            name="search"
            type="input"
            fieldType="text"
            label={t("search")}
            placeholder={t("searchFile")}
            icon={SearchIcon}
          />
          <ButtonStyled
            label={t("add_file")}
            icon={PlusIcon}
            onClick={() => navigate("/cabinets/files/add-file")}
          />
        </Box>
      </Box>
      <Box className="content-container">
        <Row className="justify-content-center">
          {fileTileArray.map((tile, key) => (
            <Col lg={3} md={6} sm={12} className="my-2">
              <CustomTile key={key} {...tile} />
            </Col>
          ))}
        </Row>
      </Box>
      <Box>
        <ListTable
          options={["open", "info", "edit", "transfer", "status", "delete"]}
          columns={columns}
          rows={rowsData}
          totalCount={totalCount}
          perPage={perPage}
          activePage={activePage}
          setActivePage={setActivePage}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onOpen={handleView}
          onInfoView={handleInfo}
          onTransfer={handleTransfer}
          onStatusToggle={handleStatusToggle}
        />
      </Box>
      <HistoryModal
        title={t("FileHistory")}
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(false)}
        createdBy={data.createdBy}
        editHistory={data.editHistory}
        transferHistory={data.transferHistory}
      />

      <TransferModal
        show={showTransferModal}
        onHide={() => setShowTransferModal(false)}
      />
      <DeleteModal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} title={t("File")} />
    </CabinetsWrapper>
  );
};

export default Files;
