import React, { useState } from 'react';
import styled from 'styled-components';
import { Cancel, DocxIcon, FileIcon2, FileUpload, PdfIcon } from '../../styled/AllImages';
import { useTranslation } from "react-i18next";

const DocumentUpload = ({accept, uploadText}) => {
    const { t } = useTranslation();
    const [selectedFile, setSelectedFile] = useState(null); // Store selected file

    // Function to handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    // Function to reset the file (remove selected file)
    const handleFileRemove = () => {
        setSelectedFile(null);
    };

    // Function to get file icon based on file type
    const getFileIcon = () => {
        if (!selectedFile) return null;

        const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
        if (fileExtension === 'pdf') {
            return <img src={PdfIcon} width="24px" />;
        } else if (fileExtension === 'doc' || fileExtension === 'docx') {
            return <img src={DocxIcon} width="24px" />;
        } else {
            // return null; // Return null if the file is not supported
            return <FileIcon2 width="20px" />;
        }
    };

    return (
        <UploadContainer>
            {selectedFile ? (
                <FileDisplay>
                    {getFileIcon()}
                    <FileName>{selectedFile.name}</FileName>
                    <RemoveButton onClick={handleFileRemove}>
                        <Cancel />
                    </RemoveButton>
                </FileDisplay>
            ) : (
                <DefaultUploadBox onClick={() => document.getElementById('fileInput').click()}>
                    <FileUpload size={40} />
                    <UploadText>{t(uploadText)}</UploadText>
                </DefaultUploadBox>
            )}

            {/* Hidden file input */}
            <input
                id="fileInput"
                type="file"
                accept={accept}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </UploadContainer>
    );
};

export default DocumentUpload;

// Styled components
const UploadContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 450px;
  border: 2px dashed var(--text2);
  position: relative;
  border-radius: 10px;
`;

const DefaultUploadBox = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
  color: var(--text2);
  
`;

const UploadText = styled.p`
  font-size: 14px;
  margin-top: 7px;
  color: var(--text2);
  line-height: 18px;
`;

const FileDisplay = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 30px;

`;

const FileName = styled.p`
  font-size: 14px;
  margin-top: 5px;
  color: var(--text2);
  line-height: 18px;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 11px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  body.rtl &{
    right: auto;
    left: 5px;
  }
  svg{
    width: 14px;
    height: auto;
    [fill] {
        fill: var(--redColor);
    }

  }
`;
