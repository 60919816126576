import React, { useState } from "react";
import { Box } from "../../../../components/styled/Elements";
import PhoneSignin1 from "./PhoneSignin1";
import PhoneSignin2 from "./PhoneSignin2";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const phoneSigninSteps = [
  {
    label: "wellcome_back",
    component: PhoneSignin1,
  },
  {
    label: "we_just_texted_you",
    component: PhoneSignin2,
  },
];

const PhoneSigninForm = () => {
  const { register } = useForm();
  const navigate = useNavigate()
  const [currentStep, setCurrentStep] = useState(phoneSigninSteps[0]);

  const currentStepIndex = phoneSigninSteps.indexOf(currentStep);

  const handleSteps = () => {
    if (currentStepIndex < 1) setCurrentStep(phoneSigninSteps[currentStepIndex + 1]);
    else navigate('/dashboard')
  };

  return (
    <Box>
      <currentStep.component register={register} handleSteps={handleSteps} />
    </Box>
  );
};

export default PhoneSigninForm;
