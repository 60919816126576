import React from "react";
import { Box } from "./components/styled/Elements";
import { Route, Routes } from "react-router-dom";
import { privateRoutes } from "./routes/privateRoutes";
import { publicRoutes } from "./routes/pubicRoutes";
import AdminLayout from "./layout/AdminLayout";

const App = () => {
  return (
    <Box>
      <Routes>
        {publicRoutes.map((route, key) => (
          <Route key={key} {...route} />
        ))}
        <Route path="/" element={<AdminLayout />}>
          {privateRoutes.map((route, key) => (
            <Route key={key} {...route} />
          ))}
        </Route>
      </Routes>
    </Box>
  );
};

export default App;
