import React from "react";
import styled from "styled-components";
import { Box, LazyImage, SpanStyled, TextStyled } from "../../styled/Elements";

const NewCustomRadio = ({
  label,
  value,
  checked,
  onChange,
  name,
  subLabel,
  avatar,
  ...rest
}) => {
  return (
    <RadioWrapper {...rest} checked={checked}>
      <Box className="d-flex align-items-center gap-2">
        {avatar && (
          <Box>
            <LazyImage src={avatar} />
          </Box>
        )}
        <Box>
          <SpanStyled
            size="16px"
            weight="bold"
            color="var(--text)"
            checked={checked}
          >
            {label}
          </SpanStyled>
          <TextStyled
            size="14px"
            weight="300"
            color="var(--text2)"
            checked={checked}
          >
            {subLabel}
          </TextStyled>
        </Box>
      </Box>
      <input
        className="radio-input"
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
    </RadioWrapper>
  );
};

export default NewCustomRadio;

const RadioWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 11px 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: var(--white);

  .radio-input {
    appearance: none;
    min-width: 25px;
	  max-width: 25px;
    height: 25px;
    border: 2px solid var(--border);
    background-color: var(--bgSecondary);
    border-radius: 50%;
    position: relative;

    &:checked {
      border: 7px solid var(--themeColor2);
    }
    &:checked::after {
      content: "";
      width: 9px;
      height: 9px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  /* .radio-text {
    font-weight: ${(props) => (props.checked ? "bold" : "normal")};
    color: ${(props) => (props.checked ? "var(--themeColor2)" : "var(--text)")};
  } */
`;
