import styled from "styled-components";

export const UsersWrapper = styled.div``;
export const AddUsersWrapper = styled.div`
  .user-personal-info .info-item {
    border-bottom: 2px solid var(--borderLight);

    label {
      min-width: 160px;
    }
  }
  .location-map-cont .location-map {
    width: 100%;
    height: 230px;
    border-radius: 10px;
  }
`;
export const UserDetailsWrapper = styled.div`
  .user-info-box {
    border-right: 1px solid var(--borderLight);
    & > div {
      padding: 1.5rem;
      border-bottom: 1px solid var(--borderLight);
    }
    body.rtl & {
      border-right: 0;
      border-left: 1px solid var(--borderLight);
    }
  }
`