import React, { useState } from 'react'
import { HqBranchesWrapper } from './styled'
import { Box, ButtonStyled, SubHeadingStyled } from '../../../components/styled/Elements'
import { hqBranchTileArray } from '../../../helpers/adminConstants/AdminConstants'
import CustomTile from '../../../components/common/statusTiles/CustomTile'
import { Col, Row } from 'react-bootstrap'
import ListTable from '../../../components/common/tables/ListTable'
import HistoryModal from '../../../components/admin/Modals/HistoryModal/HistoryModal'
import DeleteModal from '../../../components/admin/Modals/DeleteModal/DeleteModal'
import { hqBranchColumns } from '../../../helpers/adminConstants/TableConstants'
import { historyData, hqBranchRows } from '../../../helpers/dummyConstants'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FileIcon, FileIcon3, PlusIcon } from '../../../components/styled/AllImages'

const HQBranches = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [showDelete, setShowDelete] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
  
    const onOpen = (row) => {
      navigate(`/hq-branches/departments/${row.id}`)
    }
    const onDelete = () => {
      setShowDelete(true);
    };
  
    const onHistory = () => {
      setShowHistory(true)
    }
  
    const handleView = (row) => {
      navigate(`/hq-branches/view-hq-branch/${row.id}`)
    }
  
    const handleEdit = (row) => {
      navigate(`/hq-branches/edit-hq-branch/${row.id}`)
    }
  return (
    <HqBranchesWrapper>
      <Box className="content-head py-3 px-3 px-md-4">
        <SubHeadingStyled size="18px" weight="700" color="var(--text)">
          {t("hq_branches")}
        </SubHeadingStyled>

        <Box className='d-flex align-items-center gap-2'>
          <ButtonStyled label={t("print_as_pdf")} icon={FileIcon3} bg="var(--themeColor2)" />
          <ButtonStyled label={t("add_hq_branch")} icon={PlusIcon} onClick={() => navigate('/hq-branches/add-hq-branch')} />
        </Box>
      </Box>

      <Row className="mx-2 justify-content-center">
        {hqBranchTileArray.map((tile, key) => (
          <Col lg={3} md={6} sm={12} className="my-2">
            <CustomTile key={key} {...tile} />
          </Col>
        ))}
      </Row>
      <Box>
        <ListTable
          options={["open", "view", "info", "edit", "status", "delete"]}
          rows={hqBranchRows}
          columns={hqBranchColumns}
          onDelete={onDelete}
          onInfoView={onHistory}
          onViewDetails={handleView}
          onEdit={handleEdit}
          onOpen={onOpen}
        />
      </Box>
      <HistoryModal
        title={t("hq_branches_history")}
        show={showHistory}
        onHide={() => setShowHistory(false)}
        createdBy={historyData.createdBy}
        editHistory={historyData.editHistory}
      />
      <DeleteModal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        title={t("hq_branch")}
      />
    </HqBranchesWrapper>
  )
}

export default HQBranches