import React from "react";
import { Box } from "../../styled/Elements";
import { Controller } from "react-hook-form";
import ValidationErrorText from "./ValidationErrorText";
import styled from "styled-components";

const ValidationController = ({
  control,
  name,
  validationConfig,
  renderer,
  errors,
  form,
}) => {
  return (
    <ControllerWrapper>
      <Controller
        name={name}
        control={control}
        rules={validationConfig}
        render={renderer}
      />
      {errors[name] && !form[name]?.length && !errors[name]?.ref?.value && (
        <ValidationErrorText text={errors[name]?.message} />
      )}
    </ControllerWrapper>
  );
};

export default ValidationController;

const ControllerWrapper = styled.div`
  .preview-box {
    position: relative;
    width: 100%;
    height: auto;
    z-index: 999;
    
    .default-icon.delete-icon {
      svg:hover {
        [stroke] {
          stroke: var(--redColor);
        }
      }
    }
  }

  .preview-img {
    width: 230px;
    height: 80px;
    object-fit: cover;
  }
`;
