import { useState } from "react";


// import { InputWithIcon } from "../../../Styled/FormElements";
// import { calendar } from "../../../Styled/AllImages";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { useTranslation } from "react-i18next";
import { DateIcon } from "../../../../../components/styled/AllImages";
import styled from "styled-components";
import { Box, SpanStyled, TextWithIcon } from "../../../../../components/styled/Elements";

export const DateRangePickersBar = () => {
	const { t } = useTranslation();
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	// Format the date as 'DD / MM / YYYY'
	// const formattedDate = format(date, 'dd/MM/yyyy');
	const customDateFormat = (date) => {
		return format(date, 'EEE, dd MMM'); // 'Wed, 25 June'
	  };

	return (
		<DateRangePickWrapper>
			
			<Box className='d-flex gap-2 align-items-center'>
				<Box className="rangeIconText">
					<TextWithIcon icon={DateIcon} title={t('From')} />
				</Box>

				<Box className="rangeInner">
					<DatePicker
						selected={startDate}
						onChange={(date) => setStartDate(date)}
						selectsStart
						startDate={startDate}
						endDate={endDate}
						dateFormat="EEE, dd MMM"
						customInput={
							<input value={endDate ? customDateFormat(endDate) : ""} readOnly />
						}
					/>
				</Box>
				<Box className="rangeIconText">
					<TextWithIcon icon={DateIcon} title={t('To')} />
				</Box>
				<Box className="rangeInner">
					<DatePicker
						selected={endDate}
						onChange={(date) => setEndDate(date)}
						selectsEnd
						startDate={startDate}
						endDate={endDate}
						minDate={startDate}
						dateFormat="EEE, dd MMM"
						customInput={
							<input value={endDate ? customDateFormat(endDate) : ""} readOnly />
						}
					/>
				</Box>
			</Box>
		</DateRangePickWrapper>
	);
};


export const DateRangePickWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
  	.rangeInner{
		position: relative;
		padding-top: 2px;
		.react-datepicker-popper{
			transform: translate(-80px, 23px) !important;
			//-ms-transform: translate(-50%, 23px) !important;
			//transform: translate(-50%, 23px) !important;
		}
		.react-datepicker{
			.react-datepicker__triangle{
				//-ms-transform: translate(130px , -0px) !important;
				transform: translate(136px , -0px) !important;
				&::before{
					border-bottom-color: #aeaeae !important;
				}
				&::after{
					border-bottom-color: var(--white) !important;
				}
			}
			// .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
			// 	border-bottom-color: var(--white) !important;
			// }
			// .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
			// 	border-bottom-color: #000 !important;
			// }
		}
	}
	.react-datepicker-wrapper{
		width: 95px;
		
		
		input{
			width: 100%;
			color: var(--text);
			font-size: 14px;
			border: none;
			padding-top: 2px;
			&:focus{
				border: none;
				box-shadow: none;
				outline: none;
			}
		}
		
	}
  .rangeIconText{
		display: flex;
		align-items: center;
		p{
			padding-top: 2px;
		}
	}
`;