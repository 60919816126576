import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import {
  Cancel,
  ProfileImg,
  SearchIcon,
} from "../../../../components/styled/AllImages";
import {
  Box,
  ButtonStyled,
  HeadingStyled,
} from "../../../../components/styled/Elements";
import { useTranslation } from "react-i18next";
import NewCustomRadio from "../../../../components/common/radioButtons/NewCustomRadio";
import FloatingInput from "../../../../components/common/inputFields/FloatingInput";

const TransferTo = ({ onSelect, selected, t }) => (
  <ul className="option-list">
    <li className="option-item">
      <div className="option-text py-1 px-0 px-md-2">
        <NewCustomRadio
          name="type"
          label={t("corporate_administration")}
          subLabel={t("north_region_branch")}
          value={1}
          checked={selected === 1}
          onChange={onSelect}
        />
      </div>
    </li>
    <li className="option-item">
      <div className="option-text py-2 px-0 px-md-2">
        <NewCustomRadio
          name="type"
          label={t("other_department")}
          value={2}
          checked={selected === 2}
          onChange={onSelect}
        />
      </div>
    </li>
  </ul>
);

const SelectDepartment = ({ onSelect, selected, t }) => {
  const departments = [
    {
      id: 1,
      name: "it_infrastructure_security",
      label: "north_region_branch",
    },
    {
      id: 2,
      name: "sales_and_distribution",
      label: "north_region_branch",
    },
    {
      id: 3,
      name: "research_and_development",
      label: "north_region_branch",
    },
    {
      id: 4,
      name: "customer_support_services",
      label: "north_region_branch",
    },
  ];
  return (
    <>
    <Box className="mb-3 mx-3">
      <FloatingInput
        name="search"
        type="input"
        fieldType="text"
        label={t("search")}
        placeholder={t("search")}
        icon={SearchIcon}
      />
    </Box>
      <ul className="option-list">
        {departments.map((dept, i) => (
          <li key={i} className="option-item">
            <div className="option-text py-1 px-0 px-md-2">
              <NewCustomRadio
                name="department"
                label={t(dept.name)}
                subLabel={t(dept.label)}
                value={dept.id}
                checked={selected === dept.id}
                onChange={onSelect}
              />
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

const SelectMember = ({ onSelect, selected, t }) => (
  <>
    <Box className="mb-3 mx-3">
      <FloatingInput
        name="search"
        type="input"
        fieldType="text"
        label={t("search")}
        placeholder={t("search")}
        icon={SearchIcon}
      />
    </Box>
    <ul className="option-list">
      {[
        {
          id: 1,
          name: "Jenny Wilson",
          department: "corporate_administration",
          branch: "NR Branch",
          color: "#5DADE2",
          avatar: ProfileImg,
        },
        {
          id: 2,
          name: "Brooklyn Simmons",
          department: "sales_and_distribution",
          avatar: ProfileImg,
        },
        {
          id: 3,
          name: "Arlene McCoy",
          department: "research_and_development",
          avatar: ProfileImg,
        },
        {
          id: 4,
          name: "Devon Lane",
          department: "customer_support_services",
          avatar: ProfileImg,
        },
        {
          id: 5,
          name: "Darrell Steward",
          department: "it_infrastructure_security",
          avatar: ProfileImg,
        },
      ].map((member, i) => (
        <li key={member.name} className="option-item">
          <div className="option-text py-1 px-0 px-md-2">
            <NewCustomRadio
              name="department"
              label={t(member.name)}
              subLabel={t(member.department)}
              value={member.id}
              checked={selected === member.id}
              onChange={onSelect}
              avatar={member.avatar}
            />
          </div>
        </li>
      ))}
    </ul>
  </>
);

const steps = [
  { title: "transfer_to", component: TransferTo },
  { title: "select_department", component: SelectDepartment },
  { title: "transfer_to", component: SelectMember },
];

const TransferModal = ({ show, onHide }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const [selections, setSelections] = useState(Array(steps.length).fill(null));

  const handleClose = () => {
    onHide();
    setCurrentStep(0);
    setSelections(Array(steps.length).fill(null));
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      if (currentStep === 0 && selections[currentStep] == 1)
        setCurrentStep(currentStep + 2);
      else setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(0);
      setSelections(Array(steps.length).fill(null));
      onHide();
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      if (selections[0] == 1) setCurrentStep(currentStep - 2);
      else setCurrentStep(currentStep - 1);
    }
  };

  const handleSelect = (e) => {
    const { value } = e.target;

    const newSelections = [...selections];
    newSelections[currentStep] = Number(value);
    setSelections(newSelections);
  };

  const CurrentStepComponent = steps[currentStep].component;

  return (
    <Modal show={show} centered onHide={handleClose}>
      <StyledModalWrapper>
        <div className="d-flex justify-content-between align-items-center p-3">
          <HeadingStyled size="24px" color="var(--text)">
            {t(steps[currentStep].title)}
          </HeadingStyled>
          <span className="close-button cursor-pointer" onClick={handleClose}>
            <Cancel />
          </span>
        </div>
        <div>
          <CurrentStepComponent
            onSelect={handleSelect}
            selected={selections[currentStep]}
            t={t}
          />
        </div>
        <div className="modal-footer">
          {currentStep > 0 && (
            <ButtonStyled
              bg="var(--bgSecondary)"
              color="var(--text)"
              label={t("back")}
              onClick={handleBack}
            />
          )}
          <ButtonStyled
            label={currentStep === steps.length - 1 ? t("transfer") : t("next")}
            onClick={handleNext}
            disabled={selections[currentStep] === null}
            bg="var(--themeColor)"
          />
        </div>
      </StyledModalWrapper>
    </Modal>
  );
};

export default TransferModal;

const StyledModalWrapper = styled.div`
  .modal-content {
    border-radius: 8px;
  }

  .option-text {
    border-top: 1px solid var(--borderLight);
  }

  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    padding: 0;
    line-height: 1;
  }

  .option-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  }

  .action-button {
    margin-left: 8px;
  }
`;
