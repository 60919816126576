import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  Box,
  HeadingStyled,
  SpanStyled,
} from "../../../../components/styled/Elements";
import { BackArrow } from "../../../../components/styled/AllImages";
import { useTranslation } from "react-i18next";
import SignupStep1 from "./SignupStep1";
import SignupStep2 from "./SignupStep2";
import SignupStep3 from "./SignupStep3";


const signupSteps = [
  {
      label: "signup_to_cloudarchive",
      component: SignupStep1
  },
  {
      label: "signup_to_cloudarchive",
      component: SignupStep2
  },
  {
      label: "signup_to_cloudarchive",
      component: SignupStep3
  }
]

const SignupForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(signupSteps[0]);
  const [paymentMethod, setPaymentMethod] = useState(null)
  const currentStepIndex = signupSteps.indexOf(currentStep);

  const handleSteps = () => {
    if (currentStepIndex > 1) {
      navigate("/dashboard");
    } else {
      setCurrentStep(signupSteps[currentStepIndex + 1]);
    }
  };

  return (
    <Form>
      <Box className="mb-4">
        <HeadingStyled
          size="24px"
          weight="bold"
          color="var(--text)"
          className="d-flex align-items-center"
        >
          {currentStepIndex > 0 && (
            <>
              <SpanStyled className="mb-1">
                <BackArrow
                  className="back-btn cursor-pointer"
                  onClick={() => setCurrentStep(signupSteps[currentStepIndex - 1])}
                />
              </SpanStyled>
              &nbsp;
            </>
          )}
          {t(currentStep.label)}
        </HeadingStyled>
      </Box>
      <currentStep.component handleSteps={handleSteps} paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />
    </Form>
  );
};

export default SignupForm;
