import styled from "styled-components";

export const CabinetsWrapper = styled.div``
export const ArchiveWrapper = styled.div`
    .content-container-topBar{
        display: flex;
        width: 100%;
        gap: 16px;
        & > div{
            width: 100%;
            background-color: var(--white);
            border-radius: 6px;
            padding-left: 16px;
            padding-right: 16px;
            display: flex;
            align-items: center;
            height: 40px;
        }
        .radioFilterContainer{
            gap: 10px;

            label{
                padding: 8px 0;
                gap: 10px;
            }
            
        }
        .rangepickerContainer{
            
            gap: 40px;
            
        }
    }
    .fileAttachmentBox{
        display: flex;
        width: fit-content;
        background-color: var(--bgSecondary);
        align-items: center;
        border-radius: 10px;
        padding: 10px 16px;
        gap: 20px;
        span{
            padding-top: 2px;
        }
    }
    @media (max-width: 1300px){
        .content-container-topBar{
            .rangepickerContainer{
                gap: 10px;
            }
        }
    }
    @media (max-width: 1250px){
        .content-container-topBar{
            flex-wrap: wrap;
            & > div{
                width: auto;
            }
            .rangepickerContainer{
                gap: 10px;

            }
        }
    }
    @media (max-width: 991px){
        .content-container-topBar{
            & > div{
                width: 100%;
            }
            
            
        }
    }
    @media (max-width: 767px){
        .content-container-topBar{
            .rangepickerContainer{
                flex-wrap: wrap;
                height: auto;
                padding-top: 8px;
                padding-bottom: 8px;
            }
            
            
        }
    }
    
`
export const FormDataContainer = styled.div``