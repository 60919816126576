import React from "react";
import { Box, ButtonStyled, FormEleWrapper, HeadingStyled, LabelStyled, SubHeadingStyled, TextStyled } from "../../../components/styled/Elements";
import { useTranslation } from "react-i18next";
import FloatingInput from "../../../components/common/inputFields/FloatingInput";
import { useForm } from "react-hook-form";
import ValidatedInput from "../../../components/common/inputFields/ValidatedInput";

const ChangePassword = () => {
	const { t } = useTranslation();
	const {register} = useForm()
	return (
		<Box className="profileContentData">
			<FormEleWrapper className="pt-0">
				<Box className="formEleContainer">
					<LabelStyled className="labelForm">{t('OldPassword')}<span className="staric">*</span></LabelStyled>
                <ValidatedInput
                  name="old_password"
                  type="input"
                  fieldType="password"
                  placeholder={"OldPassword"}
                  register={register}
                />
				</Box>
				<Box className="formEleContainer">
					<LabelStyled className="labelForm">{t('NewPassword')}<span className="staric">*</span></LabelStyled>
					<ValidatedInput
					  name="new_password"
					  type="input"
					  fieldType="password"
					  placeholder={"NewPassword"}
					  register={register}
					/>
				</Box>
				<Box className="formEleContainer">
					<LabelStyled className="labelForm">{t('ConfirmNewPassword')}<span className="staric">*</span></LabelStyled>
					<ValidatedInput
					  name="confirm_password"
					  type="input"
					  fieldType="password"
					  placeholder={"ConfirmNewPassword"}
					  register={register}
					/>
				</Box>
				
				
			</FormEleWrapper>
			<Box className="commonBtns px-0">
				<ButtonStyled bg="var(--bgSecondary)" label={t("Cancel")} color="var(--text2)"></ButtonStyled>
				<ButtonStyled label={t("Update")}></ButtonStyled>
			</Box>
		</Box>
	);
};

export default ChangePassword;
