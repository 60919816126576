import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Cancel, DateIcon, SelectUser, SingleProfile, Users } from "../../../styled/AllImages";
import {
    Box,
    ButtonStyled,
    DateInfo,
    HeadingStyled,
    LazyImage,
    SpanStyled,
    SubHeadingStyled,
    TextStyled,
    TextWithIcon,
    TimeInfo,
    UserInfo,
} from "../../../styled/Elements";
import { HistoryModalWrapper } from "./styled";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import AddSingleUser from "../../../../pages/admin/cabinets/files/addFile/components/addSingleUser/MainData";
import { departmentsList, usersTempList } from "../../../../helpers/dummyConstants";

const SelectUserModal = ({
    show,
    onHide,
    title,
    createdBy,
    editHistory,
    transferHistory,
}) => {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
    // const [userAdded, setUserAdded] = useState(false);

	// Format the date as 'DD / MM / YYYY'
	// const formattedDate = format(date, 'dd/MM/yyyy');
	const customDateFormat = (date) => {
		return format(date, 'EEE, dd MMM'); // 'Wed, 25 June'
	};
    // This function will be triggered when a user is successfully added
    // const handleUserAdd = () => {
    //     setUserAdded(true);
    // };

    return (
        <Modal size="lg" show={show} onHide={onHide} centered>
            <SelectUserModalWrapper>
                <Box className="">
                    <Box className="d-flex justify-content-between align-items-center border-bottom mb-4 p-4">
                        <HeadingStyled size="24px" weight="bold" color="var(--text)">
                            Assign users to temporary access AD
                        </HeadingStyled>
                        <Cancel
                            className="cursor-pointer"
                            style={{ height: "20px", width: "20px" }}
                            onClick={onHide}
                        />
                    </Box>

                    <Box className="mb-4">
                        <section className="mb-3">
                            <Box className="table-responsive">
                                <table>
                                    <tr>
                                        <td>
                                            <TextStyled size="14px" color="var(--text2)">{t("SelectUser")}</TextStyled>
                                        </td>
                                        <td>
                                            <Box className="">
                                                <AddSingleUser
                                                    title="add_user"
                                                    icon={<SingleProfile />}
                                                    selPlaceholder="add_user"
                                                    mainParents={usersTempList}
                                                    // onAddUser={handleUserAdd} // Pass the function to AddSingleUser
                                                />
                                            </Box>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><TextStyled size="14px" color="var(--text2)">{t("startDate")}</TextStyled></td>
                                        <td>
                                            <Box className='d-flex gap-2 align-items-center'>
                                                <Box className="rangeIconText">
                                                    <TextWithIcon icon={DateIcon} />
                                                </Box>

                                                <Box className="rangeInner">
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={(date) => setStartDate(date)}
                                                        selectsStart
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        dateFormat="EEE, dd MMM"
                                                        customInput={
                                                            <input value={endDate ? customDateFormat(endDate) : ""} readOnly />
                                                        }
                                                    />
                                                </Box>
                                                
                                            </Box>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><TextStyled size="14px" color="var(--text2)">{t("endDate")}</TextStyled></td>
                                        <td>
                                        <Box className='d-flex gap-2 align-items-center'>
                                                <Box className="rangeIconText">
                                                    <TextWithIcon icon={DateIcon} />
                                                </Box>
                                                <Box className="rangeInner">
                                                    <DatePicker
                                                        selected={endDate}
                                                        onChange={(date) => setEndDate(date)}
                                                        selectsEnd
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        minDate={startDate}
                                                        dateFormat="EEE, dd MMM"
                                                        customInput={
                                                            <input value={endDate ? customDateFormat(endDate) : ""} readOnly />
                                                        }
                                                    />
                                                </Box>
                                            </Box>
                                        </td>
                                    </tr>
                                </table>
                            </Box>
                        </section>

                    </Box>
                </Box>
            </SelectUserModalWrapper>
            <Box className="modal-footer">
            {/* {userAdded && ( */}
                <ButtonStyled
                    label={t("Cancel")}
                    onClick={onHide}
                    bg="var(--bgSecondary)"
                    color="var(--text2)"
                />
            {/* )} */}
                <ButtonStyled
                    bg="var(--themeColor)"
                    label={t("Save")}
                    // disabled={!userAdded} // Disabled until a user is added
                    // onClick={handleAddUserClick} // Only add the user when the Add button is clicked
                />
            </Box>
        </Modal>
    );
};

export default SelectUserModal;

const SelectUserModalWrapper = styled.div`
    .react-datepicker-wrapper{
		input{
			width: 100%;
			color: var(--text);
			font-size: 14px;
			border: none;
			padding-top: 2px;
            cursor: pointer;
			&:focus{
				border: none;
				box-shadow: none;
				outline: none;
			}
		}
		
	}
    .rangeIconText .mx-2{
        margin: 0 !important;
    }
  table{
    border-collapse: collapse;
    border-spacing: 0 0px;
    tr{
        td{
            &:first-child{
                max-width: 200px;
                width: 200px;
            }
            border-bottom: 1px solid var(--borderLight);
            padding: 8px 16px;
            color: var(--text);
            font-size: 14px;
            vertical-align: middle;
        }
        &:last-child{
            td{
                border-bottom: 0;
            }
        }
    }
    td{
        border-radius: 0 !important;
    }
  }
`;