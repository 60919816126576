import React from "react";
import { Container, Row, Col, Button, Table, Badge } from "react-bootstrap";
import {
  BackButton,
  Box,
  ButtonStyled,
  HeadingStyled,
  LazyImage,
  SpanStyled,
  TextStyled,
} from "../../../components/styled/Elements";
import {
  GreenSuccessCheck,
  ProfileImg,
  RedCancelIcon,
  SaudiIdcard,
  SaudiIdCard2,
} from "../../../components/styled/AllImages";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "../../../components/styled/styled";
import { StyledTable } from "./components/PermissionTable";
import { userPermissionColumns } from "../../../helpers/adminConstants/TableConstants";
import { userPermissionsRows } from "../../../helpers/dummyConstants";
import { UserDetailsWrapper } from "./styled";
import Breadcrumbs from "../../../components/admin/Breadcrumbs/Breadcrumbs";
import { useNavigate, useParams } from "react-router-dom";

const UserDetails = () => {
  const userData = {
    name: "Wade Warren",
    status: 1,
    type: "admin",
    branch: "north_region_branch",
    department: "corporate_administration",
    email: "wadewarren@gmail.com",
    phone: "(255) 555-0118",
    location: "P.O. Box: 84281,Riyadh, Saudi Arabia",
    dob: "21/11/1979",
    allowedLogins: "204",
  };

  const { t } = useTranslation();
  const {id} = useParams()
  const navigate = useNavigate();

  return (
    <UserDetailsWrapper>
      <Box className="content-head py-3 px-3 px-md-4">
        <Box className="d-flex align-items-center gap-5">
          <BackButton
            label={t("user_details")}
            onClick={() => navigate("/users")}
          />
          <Breadcrumbs />
        </Box>
      </Box>
      <ContentWrapper>
        <Box className="d-flex flex-wrap">
          <Col md={4} className="user-info-box">
            <Box className="d-flex align-items-center justify-content-between gap-1">
              <Box className="d-flex align-items-center gap-2">
                <LazyImage
                  src={ProfileImg}
                  alt="User avatar"
                  className="rounded-circle"
                  style={{ width: "48px", height: "48px" }}
                />
                <Box>
                  <TextStyled size="20px" weight="900" className="mb-0">
                    {userData.name}
                  </TextStyled>
                  <SpanStyled
                    size="16px"
                    weight="400"
                    color="var(--text2)"
                    className="mb-0"
                  >
                    {124145}
                  </SpanStyled>
                </Box>
              </Box>
              <ButtonStyled label={t("edit_user")} onClick={() => navigate(`/users/edit-user/${id}`)} />
            </Box>
            <Box className="d-flex align-items-center">
              <Col sm={4}>
                <SpanStyled color="var(--text2)">{t("status")}</SpanStyled>
              </Col>
              <Col sm={8}>
                <SpanStyled
                  color={
                    userData.status === 1
                      ? "var(--themeColor2)"
                      : "var(--redColor)"
                  }
                >
                  {t(userData.status === 1 ? "active" : "deactive")}
                </SpanStyled>
              </Col>
            </Box>

            {[
              { label: "type", value: userData.type },
              { label: "branch_hq", value: userData.branch },
              { label: "department", value: userData.department },
              { label: "email", value: userData.email },
              { label: "phone", value: userData.phone },
              { label: "location", value: userData.location },
              { label: "date_of_birth", value: userData.dob },
              { label: "no_of_allowed_login", value: userData.allowedLogins },
            ].map((field, index) => (
              <Box key={index} className="d-flex align-items-center">
                <Col sm={4}>
                  <SpanStyled color="var(--text2)">{t(field.label)}</SpanStyled>
                </Col>
                <Col sm={8}>
                  <Box>{t(field.value)}</Box>
                </Col>
              </Box>
            ))}
            <Box className="d-flex">
              <Col sm={4}>
                <SpanStyled color="var(--text2)">{t("id")}</SpanStyled>
              </Col>
              <Col sm={8}>
                <LazyImage
                  src={SaudiIdcard}
                  alt="ID Card"
                  className="img-fluid"
                />
              </Col>
            </Box>
            <Box className="d-flex">
              <Col sm={4}>
                <SpanStyled color="var(--text2)">{t("job_id")}</SpanStyled>
              </Col>
              <Col sm={8}>
                <LazyImage
                  src={SaudiIdCard2}
                  alt="Job ID Card"
                  className="img-fluid"
                />
              </Col>
            </Box>
          </Col>

          <Col md={8} className="p-0">
            <StyledTable className="border-0">
              <thead>
                <tr>
                  {userPermissionColumns.map((col, index) => (
                    <th
                      key={index}
                      className={`${col.title === "" && "title-column"}`}
                    >
                      <SpanStyled size="16px" color="var(--text)" weight="bold">
                        {t(col.title)}
                      </SpanStyled>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {userPermissionsRows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {userPermissionColumns.map((col, colIndex) => (
                      <td key={colIndex}>
                        <Box className="d-flex justify-content-center">
                          {col.render(row[col.name], row, t)}
                        </Box>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          </Col>
        </Box>
      </ContentWrapper>
    </UserDetailsWrapper>
  );
};

export default UserDetails;
