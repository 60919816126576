import { Spinner } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ArrowDown, BackArrow, DateIcon, TimeIcon } from "./AllImages";
import Breadcrumbs from "../admin/Breadcrumbs/Breadcrumbs";

export const Box = styled.div`
  background-color: ${({ bg }) => (bg ? bg : "")};
`;

export const SpanStyled = styled.span`
  color: ${(props) => (props.color ? props.color : "")};
  border: ${(props) => (props.border ? `1px solid ${props.border}` : "")};
  border-radius: ${(props) => (props.radius ? props.radius : "")};
  line-height: ${(props) => (props.lh ? props.lh : "")};
  font-size: ${(props) => (props.size ? props.size : "")};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  letter-spacing: ${(props) => (props.spacing ? props.spacing : "")};
  text-transform: ${(props) => (props.transform ? props.transform : "")};
  background-color: ${({ bg }) => (bg ? bg : "")};
  font-family: ${(props) => (props.family ? props.family : "var(--regular)")};
  body.rtl & {
    font-family: ${(props) =>
        props.family ? props.family : "var(--arRegular)"};
  }
`;

export const HeadingStyled = styled.div`
  color: ${(props) => (props.color ? props.color : "var(--themeColor)")};
  line-height: ${(props) => (props.lh ? props.lh : "")};
  font-size: ${(props) => (props.size ? props.size : "20px")};
  display: ${(props) => (props.display ? props.display : "")};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  letter-spacing: ${(props) => (props.spacing ? props.spacing : "")};
  text-transform: ${(props) => (props.transform ? props.transform : "")};
  font-family: ${(props) =>
        props.family ? props.family : "var(--regularBold)"};
  body.rtl & {
    font-family: ${(props) =>
        props.family ? props.family : "var(--arBoldSans)"};
  }
`;

export const SubHeadingStyled = styled.div`
  color: ${(props) => (props.color ? props.color : "var(--themeColor)")};
  line-height: ${(props) => (props.lh ? props.lh : "")};
  font-size: ${(props) => (props.size ? props.size : "24px")};
  display: ${(props) => (props.display ? props.display : "")};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  letter-spacing: ${(props) => (props.spacing ? props.spacing : "")};
  text-transform: ${(props) => (props.transform ? props.transform : "")};
  font-family: ${(props) =>
        props.family ? props.family : "var(--regularBold)"};
  body.rtl & {
    font-family: ${(props) =>
        props.family ? props.family : "var(--arBoldSans)"};
  }
`;

export const TextStyled = styled.p`
  display: ${(props) => (props.display ? props.display : "flex")};
  width: ${(props) => (props.display ? props.display : "100%")};
  line-height: ${(props) => (props.lh ? props.lh : "24px")};
  font-size: ${(props) => (props.size ? props.size : "15px")};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  color: ${(props) => (props.color ? props.color : "var(--text)")};
  text-transform: ${(props) => (props.transform ? props.transform : "")};
  letter-spacing: ${(props) => (props.spacing ? props.spacing : "")};
  background-color: ${({ bg }) => (bg ? bg : "")};
  font-family: ${(props) => (props.family ? props.family : "var(--regular)")};
  body.rtl & {
    font-family: ${(props) =>
        props.family ? props.family : "var(--arRegular)"};
  }
`;

export const LabelStyled = styled.label`
  line-height: ${(props) => (props.lh ? props.lh : "")};
  font-size: ${(props) => (props.size ? props.size : "15px")};
  font-weight: ${(props) => (props.weight ? props.weight : "")};
  letter-spacing: ${(props) => (props.spacing ? props.spacing : "")};
  color: ${(props) => (props.color ? props.color : "var(--text)")};
  position: relative;
  font-family: ${(props) => (props.family ? props.family : "var(--regular)")};
  span.optional {
    display: block;
    width: 100%;
    font-size: 14px;
    color: var(--text2);
    line-height: 16px;
  }
  body.rtl & {
    font-family: ${(props) =>
        props.family ? props.family : "var(--arRegular)"};
  }
`;
export const FileUploaderStyled = styled.label`
  background: var(--white);
  border: 2px dashed var(--text2);
  min-height: 80px;
  max-width: 300px;
  border-radius: 6.9px;
  padding: 10px;
  cursor: pointer;
  &:hover {
    border-color: var(--textPlaceholder2);
  }
`;
export const IconBoxColor = styled.div`
  svg {
    [stroke] {
      stroke: ${({ iconColor }) => (iconColor ? iconColor : "")};
    }
  }
`;

export const sliceProps = (props, sub) => {
    const { children, ...otherProps } = props;
    sub.forEach((prop) => delete otherProps[prop]);
    return otherProps;
};

export const LazyImage = (props) => {
    return <LazyLoadImage className="custom-img" {...props} effect="blur" />;
};

export const StatusText = ({ text, status }) => {
    return (
        <StatusContainer status={status} className="d-flex align-items-center gap-2 text-nowrap">
            <div className="status-color-box" />
            <SpanStyled>{text}</SpanStyled>
        </StatusContainer>
    )
}
export const TextWithIcon = (props) => (
    <TextWithStyledWrapper
        color={props.color}
        className={`d-flex ${props.desc ? "align-items-start" : "align-items-center"
            }`}
    >
        <Box className="mx-2 d-flex align-items-center justify-content-center">
            <props.icon width="24px" height="24px" />
        </Box>
        <Box>
            <TextStyled
                className="mb-0"
                weight={400}
                size={props.headingSize ? props.headingSize : "14px"}
                color={!props.alsotextColor ? "var(--text2)" : "var(--text2)"}
                dangerouslySetInnerHTML={{ __html: props.title }}
            ></TextStyled>
            {props.desc ? (
                <SpanStyled
                    className="text-uppercase"
                    color={"var(--greyText)"}
                    size="12px"
                    weight={300}
                >
                    {props?.desc}
                </SpanStyled>
            ) : null}
        </Box>
    </TextWithStyledWrapper>
);
const TextWithStyledWrapper = styled.div`
  svg {
    [stroke] {
      stroke: ${({ color }) => (color ? color : "")};
    }
  }
`;


export const SelectionDropdown = (props) => {
  const unwantedProps = ["options"];
  return (
    <DropdownStyled {...sliceProps(props, unwantedProps)} arrow={ArrowDown}>
      <option value="" className="label">
        {props.placeholder && props.placeholder}
      </option>
      {props.options && props.options.length > 0 && props.options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </DropdownStyled>
  );
};
export const DateInfo = ({ date }) => {
    return (
        <Box className="d-flex gap-2 small text-muted">
            <DateIcon />
            <SpanStyled size="14px" color="var(--text)">
                {date}
            </SpanStyled>
        </Box>
    );
};

export const TimeInfo = ({ time }) => {
    return (
        <Box className="d-flex gap-2 small text-muted">
            <TimeIcon />
            <SpanStyled size="14px" color="var(--text)">
                {time}
            </SpanStyled>
        </Box>
    );
};

export const UserInfo = ({ name, role, avatar }) => (
    <Box className="d-flex gap-2 align-items-center">
        <Box className="profile-avatar">
            <LazyImage
                src={avatar}
                alt={name}
                className="rounded-circle mr-2 w-100"
            />
        </Box>
        <Box className="d-flex flex-column">
            <SpanStyled size="14px" weight="bold" className="text-nowrap">
                {name}
            </SpanStyled>
            <SpanStyled  size="14px" color="var(--text2)">{role}</SpanStyled>
        </Box>
    </Box>
);

export const BackButton = (props) => {
    return (
        <BackButtonWrapper {...props} className="d-flex align-items-center gap-2">
            <button type="button" className="icon-box border-0">
                <BackArrow color="var(--text)" />
            </button>
            <SpanStyled className={`${props.clName ? props.clName : ""}`} weight={700} color="var(--text)" size="16px">
                {props.label && props.label}
            </SpanStyled>
        </BackButtonWrapper>
    );
};

export const TabsList = ({ tabs, activeTab, handleTabChange }) => {
    const { t } = useTranslation()
    return (
        <TabStyled className="mb-0 list-unstyled d-flex justify-content-between">
            {tabs &&
                tabs.length > 0 &&
                tabs.map((item, key) => (
                    <li
                        className={`text-uppercase text-center py-3 ${item.title == activeTab.title ? "active" : ""
                            }`}
                        onClick={() => handleTabChange(key)}
                        key={key}
                    >
                        <SpanStyled size="14px">{t(item.title)}</SpanStyled>
                    </li>
                ))}
        </TabStyled>
    );
};




export const ButtonStyled = (props) => {
    const unwantedProps = [
        "icon",
        "label",
        "className",
        "clName",
        "onClick",
        "isLoading",
    ];
    const buttonProps = props.type ? { href: props.type } : {};
    return (
        <ButtonWrapper
            {...sliceProps(props, unwantedProps)}
            className={`${props.clName ? props.clName : ""}`}
            disabled={props.disabled}
        >
            <button
                className={`d-flex align-items-center justify-content-center ${props.className || ""
                    } ${!props.icon ? "Btn-icon" : ""} `}
                {...buttonProps}
                onClick={props.onClick}
                autoComplete="false"
                disabled={props.disabled}
            >
                {props.isLoading ? (
                    <Spinner variant="success" />
                ) : (
                    <>
                        {props.icon && <props.icon width="14px" />}
                        <SpanStyled size={props.size ? props.size : "16px"}>
                            {props.label ? props.label : ""}
                        </SpanStyled>
                    </>
                )}
            </button>
        </ButtonWrapper>
    );
};
export const FormButtonStyled = (props) => {
    const unwantedProps = [
        "icon",
        "label",
        "className",
        "clName",
        "onClick",
        "isLoading",
    ];
    const buttonProps = props.type ? { href: props.type } : {};
    return (
        <FormButtonWrapper
            {...sliceProps(props, unwantedProps)}
            className={`${props.clName ? props.clName : ""}`}
            disabled={props.disabled}
        >
            <button
                className={`d-flex align-items-center justify-content-center ${props.className || ""
                    } ${!props.icon ? "Btn-icon" : ""} `}
                {...buttonProps}
                onClick={props.onClick}
                autoComplete="false"
                disabled={props.disabled}
            >
                {props.isLoading ? (
                    <Spinner variant="success" />
                ) : (
                    <>
                        <SpanStyled size={props.size ? props.size : "18px"}>
                            {props.label ? props.label : ""}
                        </SpanStyled>
                        {props.icon && <props.icon width="22px" />}
                    </>
                )}
            </button>
        </FormButtonWrapper>
    );
};


export const DataContainerHeader = (props) => {
    const { t } = useTranslation()
    const unwantedProps = [
        "icon",
        "label",
        "className",
        "clName",
        "onClick",
        "isLoading",
        "btnLabel",
        "backBtnLabel",
        "onClickBack",
    ];
    
    return (
        <DataHeader
            {...sliceProps(props, unwantedProps)}
            className={`${props.clName ? props.clName : ""}`}
            
            >
            <Box className="d-flex flex-wrap gap-3">
                {props.backBtnLabel && (
                    <>
                        <BackButton
                            label={t(props.backBtnLabel)}
                            // backBtnLabel= {props.backBtnLabel}
                            onClick= {props.onClickBack}
                            // onClick={() => navigate("/users")}
                            clName="secHeadSpn"
                        />
                        <Breadcrumbs />
                    </>
                )}
                {!props.backBtnLabel && (
                <SpanStyled color="var(--text)" size="20px" family="var(--regularBold)">
                    {props.label && props.label}
                </SpanStyled>
                )}
            </Box>
            {props.btnLabel &&
                <ButtonStyled
                    label={t(props.btnLabel)}
                    icon={props.icon}
                    onClick={props.onClick}
                />
            }
        </DataHeader>
    );
};

export const DataHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 25px 12px 25px;
    border-bottom: 1px solid var(--borderLight);
    width: 100%;
    span.secHeadSpn{
        color: var(--text);
        font-size: 20px;
        font-family: var(--regularBold);
    }
    body.rtl &{
        span{
            font-family: var(--arBoldSans);
        }
    }
`





export const Separator = (props) => {
    <SeparatorBorder></SeparatorBorder>
};
export const SeparatorBorder = styled.div`
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--borderLight);
`

const DropdownStyled = styled.select`
  border: 1px solid ${({ border }) => (border ? border : 'var(--border)')};
  border-radius: 8px;
  height: 48px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  background: var(--bgSecondary);
  color: var(--text);
  cursor: pointer;

  .label{
    color: var(--text2);
  }

  &::placeholder {
    color: var(--text2);
  }
  option[disabled] {
    color: var(--text2);
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
  button,
  button::after {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
  button {
    background: ${(props) => (props.bg ? props.bg : "var(--themeColor)")};
    border: 1px solid
      ${(props) => (props.border ? props.border : "transparent")};
    display: flex;
    gap: 8px;
    padding: 7px 20px;
    position: relative;
    border-radius: 10px;
    /* box-shadow: ${({ shadow }) =>
        shadow ? shadow : "0px 4px 8px 0px #064a9340"}; */
    span {
      text-wrap: nowrap;
      /* font-family: var(--regular); */
      color: ${(props) => (props.color ? props.color : "var(--white)")};
    }
    & svg {
      height: 22px;
      [fill] {
        /* fill: var(--themeColor); */
      }
    }
    /* &:hover {
      background: var(--hoverBg);
    } */
    ${(props) =>
        props.disabled &&
        `
        opacity: 0.5;
      `}
  }
`;
export const FormButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
  button,
  button::after {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
  button {
    background: ${(props) => (props.bg ? props.bg : "var(--themeColor)")};
    border: 1px solid
      ${(props) => (props.border ? `var(${props.border})` : "transparent")};
    display: flex;
    gap: 12px;
    height: 50px;
    padding: 0px 20px;
    position: relative;
    border-radius: 10px;
    box-shadow: ${({ shadow }) =>
        shadow ? shadow : "0px 4px 8px 0px #064a9340"};
    span {
      text-wrap: nowrap;
      /* font-family: var(--regular); */
      color: ${(props) => (props.color ? props.color : "var(--white)")};
    }
    & svg {
      height: 22px;
      [fill] {
        fill: var(--darkColor);
      }
    }
    /* &:hover {
      background: var(--hoverBg);
    } */
    ${(props) =>
        props.disabled &&
        `
        background: var(--borderLight);
        color: var(--text2);
        cursor: not-allowed;
        
        span {
          color: var(--text2);
        }

        & svg [fill] {
          fill: var(--text2);
        }
      `}
  }
`;

export const BackButtonWrapper = styled.div`
  button {
    background: none !important;
    &.icon-box {
      width: 28px;
      height: 28px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      body.rtl & svg {
        transform: scale(-1);
      }
    }
  }
`;

export const FormEleWrapper = styled.div`
  padding: 9px 0px 9px 0px;
  border-bottom: 1px solid var(--borderLight);
  width: 100%;
  .formEleContainer {
    display: flex;
    padding: 7px 0px 7px 0px;
    margin: 7px 0 7px 0;
    align-items: center;
    gap: 10px;
    & > label {
      min-width: 160px;
    }
  }
  .formEleContainer.inp-white {
    input {
      background-color: var(--white);
    }
  }
  .form-floating {
    width: 100%;
  }
  input[type="text"] {
    width: 100%;
  }
  @media (max-width:767px){
    .formEleContainer {
      flex-wrap: wrap;
      gap: 4px;
      label{
        width: 100%;
      }
    }
  }
`;
export const FormElementWrapper = styled.div`
  padding: 9px 25px 9px 25px;
  border-bottom: 1px solid var(--borderLight);
  width: 100%;
  .formEleContainer {
    display: flex;
    padding: 7px 0px 7px 0px;
    margin: 7px 0 7px 0;
    align-items: center;
    gap: 10px;
    & > label {
      min-width: 160px;
    }
  }
  &.combined{
        border-bottom: none;
        padding: 8px 25px 8px 25px;
        &:last-child{
            border-bottom: 1px solid var(--borderLight);
        }
        .formEleContainer {
            display: flex;
            padding: 0;
            margin: 0;
        }
    }
  .formEleContainer.inp-white {
    input {
      background-color: var(--white);
    }
  }
  .form-floating {
    width: 100%;
  }
  input[type="text"] {
    width: 100%;
  }
`;

export const TabStyled = styled.ul`
  overflow-x: auto;
  background-color: var(--white);
  border-bottom: 1px solid var(--border);
  li {
    white-space: nowrap;
    width: 50%;
    cursor: pointer;
    position: relative;
    &:not(.active) {
      color: var(--text);
      font-weight: 500;
    }
    &.active {
      color: var(--themeColor);
      font-weight: 700;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        border-radius: 20px;
        background-color: var(--themeColor);
      }
    }
  }
  @media (max-width: 768px) {
    li {
      min-width: fit-content;
      padding-left: 25px;
      padding-right: 25px;
    }
  }
`;
const StatusContainer = styled.div`
  border-radius: 4px;
  padding: 8px;
  width: fit-content;
  
  .status-color-box {
    position: relative;
    width: 20px;
    &::before {
      content: '';
      position: absolute;
      top: -6px;
      left: 3px;
      width: 10px;
      height: 10px;
      background-color: ${({ status }) => status == 1 ? "var(--themeColor2)" : "var(--redColor)"};
      border-radius: 3px;
      display: inline-block;
      body.rtl & {
        left: unset;
        right: 3px;
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: -9px;
      left: 0;
      width: 16px;
      height: 16px;
      border: 1px solid ${({ status }) => status == 1 ? "var(--themeColor2)" : "var(--redColor)"};
      border-radius: 3px;
      display: inline-block;
      
      body.rtl & {
        left: unset;
        right: 0;
      }
    }
  }

  span {
    color: ${({ status }) => status == 1 ? "var(--themeColor2)" : "var(--redColor)"};
    font-size: 16px;
    font-weight: 500;
  }
`;