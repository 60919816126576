import styled from "styled-components";

export const HeaderContainer = styled.header`
  height: 94px;
  .header-container {
    width: calc(100% - 270px);
    position: fixed;
    top: 0;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    background: var(--white);
    border-bottom: 1px solid var(--border);
    z-index: 1001;
    height: 94px;
  }
  .nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .logo {
    width: 179px;
    cursor: pointer;
    span {
      width: 100%;
    }
    img {
      width: 179px;
      height: auto;
      -webkit-transition: all 0.3s ease-out 0s;
      transition: all 0.3s ease-out 0s;
    }
  }
  .signin-btn {
    box-shadow: 5px 0px 5.64px 5.1px #064a930d;
  }
  .toggleBtn {
    display: none;
  }
  .whtLngWrapper {
    gap: 7px;
  }
  .headerBtn {
    background-color: var(--themeColor);
  }

  .Social-links {
    display: flex;
    gap: 12px;
    cursor: pointer;
  }

  @media (max-width: 1199px) {
    .nav-bar button span {
      font-size: 14px;
    }
  }
  @media (max-width: 992px) {
    .header-container {
      width: 100%;
    }
  }
  @media (max-width: 660px) {
    .support-dropdown {
      display: none;
    }
    .greet-heading{
      font-size: 16px;
    }
  }
`;

export const MenuToggle = styled.button`
  display: none;
  svg {
    width: 18px;
    height: 18px;
  }
  @media (max-width: 992px) {
    display: block;
  }
`;