import React, { useState } from "react";
import { Box, FormEleWrapper, HeadingStyled, SpanStyled, SubHeadingStyled, TextStyled } from "../../../components/styled/Elements";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DashboardWrapper, DataContainer, ProfileSidebarWrapper, ProfileWrapper } from "./styled";
import { Camera, LogoutIcon, ProfileImgLarge } from "../../../components/styled/AllImages";
import { tabsArrayList } from "./TabsArray";
import { Link } from "react-router-dom";

const Profile = () => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(tabsArrayList[0]);
	return (
		<ProfileWrapper>
			<Box className="content-head py-3 px-3 px-md-4">
				<SubHeadingStyled size="16px" weight="700" color="var(--text)">
					{t("Profile")}
				</SubHeadingStyled>
			</Box>
			<Box className="content-container">
				<Row className="mt-3">
					<Col md={12}>
						<DataContainer className="dataContainer">
							<ProfileSidebarWrapper>
								<Box className="text-center">
									<Box className="sidebar-list custom-scroll">
										<ul className="list-unstyled mb-0 pt-3">
											{tabsArrayList.map((item, key) => (
												<li
													className={` mb-2 ${
													item.title === activeTab.title ? "active" : ""
													}`}
													onClick={() => setActiveTab(tabsArrayList[key])}
													key={key}
												>
													<Link 
														className={`text-decoration-none d-flex align-items-center`}
													>
														<Box className="list-icon mx-2">
															<item.icon />
															
														</Box>
														<Box>
															<SpanStyled
																className="text-capitalize d-flex"
															>
																{t(item.title)}
															</SpanStyled>
														</Box>
													</Link>
													
												</li>
											))}
											<li	className="mb-2 logout-tab">
													<Link 
														className={`text-decoration-none d-flex align-items-center`}
													>
														<Box className="list-icon mx-2">
															<LogoutIcon />
															
														</Box>
														<Box>
															<SpanStyled
																className="text-capitalize d-flex"
																color="var(--redColor)"
															>
																{t('Logout')}
															</SpanStyled>
														</Box>
													</Link>
													
												</li>
											{/* {ProfileSidebarList.map((item, key) => {
												const active = location.pathname.includes(item.url)
													? "active"
													: "";
												return (
													<>
														<li key={item.title + key} className="mb-2">
															<Link
																to={item.url}
																className={`text-decoration-none d-flex align-items-center ${active}`}
															>
																<Box className="list-icon mx-2">
																	<item.icon />
																</Box>
																<Box>
																	<SpanStyled
																		className="text-capitalize d-flex"
																		style={{ textAlign: "start" }}
																	>
																		{t(item.title)}
																	</SpanStyled>
																</Box>
															</Link>
														</li>
													</>
												);
											})} */}
										</ul>
									</Box>
								</Box>
							</ProfileSidebarWrapper>
							
							<Box className="profileContent">
								
								<activeTab.component />
								{/* <AccountSetting /> */}
							</Box>
						</DataContainer>
					</Col>
				</Row>
			</Box>
		</ProfileWrapper>
	);
};

export default Profile;
