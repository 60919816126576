import React, { useState } from "react";
import { Box } from "../../../components/styled/Elements";
import { sentReqColumns } from "../../../helpers/adminConstants/TableConstants";
import { sentReqsRows } from "../../../helpers/dummyConstants";
import { TableWrapper } from "../../../components/common/tables/ListTable";
import { useTranslation } from "react-i18next";
import CancelReqModal from "../../../components/admin/Modals/CancelReqModal/CancelReqModal";

const SentRequests = () => {
  const [cancelModal, setCancelModal] = useState(false)
  const {t} = useTranslation()

  return (
    <Box>
      <TableWrapper>
        <Box className="table-responsive custom-scroll overflow-x-auto">
          <table className="w-100 ">
            <thead>
              <tr>
                {sentReqColumns.map((col, key) => (
                  <th key={key} className="text-nowrap">
                    {t(col.label)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sentReqsRows?.map((row, index) => (
                <tr key={index}>
                  {sentReqColumns?.map((col, key) => (
                    <td key={key}>
                      {col.render
                        ? col.render(row[col.name], row, t, setCancelModal)
                        : row[col.name]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        {/* {totalCount && (
        <PaginationContainer>
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={activePage}
            itemsCountPerPage={perPage}
            totalItemsCount={totalCount}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            getPageUrl={(pageNumber) => pageHrefs[pageNumber - 1]}
          />
        </PaginationContainer>
      )} */}
      </TableWrapper>
      <CancelReqModal show={cancelModal} onHide={() => setCancelModal(false)} />
    </Box>
  );
};

export default SentRequests;
