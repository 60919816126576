import React from "react";
import { HistoryWrapper } from "./styled";
import { Box, SubHeadingStyled } from "../../../components/styled/Elements";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "../../../components/styled/AllImages";
import FloatingInput from "../../../components/common/inputFields/FloatingInput";
import ListTable from "../../../components/common/tables/ListTable";
import { historyRows } from "../../../helpers/dummyConstants";
import { historyColumns } from "../../../helpers/adminConstants/TableConstants";
import { useNavigate } from "react-router-dom";

const History = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()

  const onViewDetails = () => {
    navigate("/history/history-details")
  }

  return (
    <HistoryWrapper>
      <Box className="content-head py-3 px-3 px-md-4">
        <SubHeadingStyled size="18px" weight="700" color="var(--text)">
          {t("history")}
        </SubHeadingStyled>

        <Box>
          <FloatingInput
            name="search"
            type="input"
            fieldType="text"
            label={t("search")}
            placeholder={t("search")}
            icon={SearchIcon}
          />
        </Box>
      </Box>
      <Box>
        <ListTable
        options={["tooltip", "view"]}
            rows={historyRows}
            columns={historyColumns}
            onViewDetails={onViewDetails}
        />
      </Box>
    </HistoryWrapper>
  );
};

export default History;
