import React from 'react'
import { InfoListWrapper } from './styled'

const InformationList = ({children}) => {
  return (
    <InfoListWrapper>
      {children}
    </InfoListWrapper>
  )
}

export default InformationList