import React, { useState } from "react";
import { CabinetsWrapper } from "./styled";
import {
  Box,
  ButtonStyled,
  SubHeadingStyled,
} from "../../../components/styled/Elements";
import { useTranslation } from "react-i18next";
import FloatingInput from "../../../components/common/inputFields/FloatingInput";
import {
  PlusIcon,
  ProfileImg,
  SearchIcon,
} from "../../../components/styled/AllImages";
import ListTable from "../../../components/common/tables/ListTable";
import HistoryModal from "../../../components/admin/Modals/HistoryModal/HistoryModal";
import { replace, useNavigate } from "react-router-dom";
import TransferModal from "./components/TransferModal";
import { cabinetColumns } from "../../../helpers/adminConstants/TableConstants";
import DeleteModal from "../../../components/admin/Modals/DeleteModal/DeleteModal";
import { historyData } from "../../../helpers/dummyConstants";

const Cabinets = () => {
  const { t } = useTranslation();
  // Table columns configuration

  // Example data (rows)
  const rows = [
    { id: "00123", name: "John Doe", email: "john@example.com", status: true },
    {
      id: "00124",
      name: "Jane Smith",
      email: "jane@example.com",
      status: false,
    },
    { id: "00125", name: "John Doe", email: "john@example.com", status: true },
    {
      id: "00126",
      name: "Jane Smith",
      email: "jane@example.com",
      status: false,
    },
    { id: "00125", name: "John Doe", email: "john@example.com", status: true },
    // Add more rows as needed...
  ];
  const perPage = 10; // Items per page
  const totalCount = 20;

  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(1);
  const [rowsData, SetRowsData] = useState(rows);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);

  const handleEdit = (row) => {
    navigate(`/cabinets/edit-cabinet/${row.id}`);
  };
  const handleInfo = (row) => {
    setShowHistoryModal(true);
  };

  const handleTransfer = (row) => {
    setShowTransferModal(true);
  };

  const handleDelete = (row) => {
    setShowDeleteModal(true)
  };

  const handleView = (row) => {
    navigate("/cabinets/files")
  };

  const handleStatusToggle = (row) => {
    const rowIndex = rowsData.indexOf(row);
    const updatedRows = rowsData.map((r, i) => {
      if (i === rowIndex) {
        return { ...r, status: !r.status };
      }
      return r;
    });
    SetRowsData(updatedRows);
  };

  return (
    <CabinetsWrapper>
      <Box className="content-head py-3 px-3 px-md-4">
        <SubHeadingStyled size="16px" weight="700" color="var(--text)">
          {t("cabinets")}
        </SubHeadingStyled>
        <Box className="d-flex gap-2">
          <FloatingInput
            name="search"
            type="input"
            fieldType="text"
            label={t("search")}
            placeholder={t("search")}
            icon={SearchIcon}
          />
          <ButtonStyled
            label={t("add_cabinet")}
            icon={PlusIcon}
            onClick={() => navigate("/cabinets/add-cabinet", { replace: true })}
          />
        </Box>
      </Box>
      <Box>
        <ListTable
          options={["open", "info", "edit", "transfer", "status", "delete"]}
          columns={cabinetColumns}
          rows={rowsData}
          totalCount={totalCount}
          perPage={perPage}
          activePage={activePage}
          setActivePage={setActivePage}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onOpen={handleView}
          onInfoView={handleInfo}
          onStatusToggle={handleStatusToggle}
          onTransfer={handleTransfer}
        />
      </Box>
      <HistoryModal
        title={t("cabinet_history")}
        show={showHistoryModal}
        onHide={() => setShowHistoryModal(false)}
        createdBy={historyData.createdBy}
        editHistory={historyData.editHistory}
        transferHistory={historyData.transferHistory}
      />
      <TransferModal
        show={showTransferModal}
        onHide={() => setShowTransferModal(false)}
      />
      <DeleteModal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} title={t("Cabinet")} />
    </CabinetsWrapper>
  );
};

export default Cabinets;
