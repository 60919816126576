import styled from "styled-components";

export const AdminSidebarWrapper = styled.div`
  width: 270px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.18);
  background-color: var(--white);
  transition: 0.3s all;
  z-index: 1010;
  .logo {
    width: 200px;
  }
  a {
    position: relative;
    padding: 10px 10px;
    transition: all ease-in-out 0.3s;
    font-size: 15px;
    color: var(--text);
    border-color: var(--themeColor2);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 0.25rem;
      background-color: var(--themeColor2);
      transition: all 0.3s ease;
      opacity: 0;
    }
    svg {
      width: 24px;
      
    }
    &:hover,
    &.active {
      background-color: var(--bgSecondary);
      &::before {
        opacity: 1;
      }
      svg {
        [stroke] {
          stroke: var(--themeColor);
        }
        [fill] {
          fill: var(--themeColor);
        }
      }
      span {
        color: var(--themeColor);
        font-weight: 600;
      }
    }
    .subArrow{
        body.rtl &{
            margin-right: auto;
            margin-left: 0 !important;
        }
        
        svg {
            [stroke] {
                stroke: var(--text2);
            }
            [fill] {
                fill: var(--text2);
            }
            
        }
    }
  }
  .subMenu li{

    margin-bottom: 1px;
    &:first-child{
        margin-top: 1px;
    }
    a{
        text-align: left;
        padding: 12px 10px 12px 20px;
        background-color: var(--bgSecondary);
        &:hover,
        &.subActive{
            font-family: var(--medium);
            color: var(--text);
        }
        
        &.subActive{
            background-color: var(--bgSecondary);
            &::before{
                background-color: var(--text2);
                opacity: 1;
            }
            svg {
                [stroke] {
                    stroke: var(--themeColor);
                }
                [fill] {
                    fill: var(--themeColor);
                }
            }
        }
        svg {
            [stroke] {
                stroke: var(--themeColor);
            }
            [fill] {
                fill: var(--themeColor);
            }
        }
    }
    }
  .logo-container {
    padding: 29.5px;
    border-bottom: 1px solid var(--border);
  }
  .sidebar-list {
    height: calc(100vh - 93px);
    /* height: 100%; */
    overflow-y: auto;
  }
  .close {
    width: 20px;
  }

  @media (max-width: 992px) {
    width: 0;
    opacity: 0;
    pointer-events: none;
    &.open {
      width: 270px;
      opacity: 1;
      pointer-events: all;
    }
    .logo {
      width: 165px;
    }
  }
`;
