import React, { useState } from "react";
import styled from "styled-components";
import { ProfileImg } from "../../../../components/styled/AllImages";
import {
  Box,
  ButtonStyled,
  HeadingStyled,
  TextStyled,
} from "../../../../components/styled/Elements";
import { useTranslation } from "react-i18next";
import TooltipInfo from "../../../../components/common/tooltip/InfoTooltip";
import ReasonModal from "../../../../components/admin/Modals/ReasonModal/ReasonModal";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid var(--borderLight);
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  text-align: start;
  padding: 12px 20px;
  color: var(--text);
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid var(--borderLight);
`;

const TableRow = styled.tr`
  &:not(:last-child) {
    border-bottom: 1px solid var(--borderLight);
  }
`;

const TableData = styled.td`
  padding: 12px 20px;
  vertical-align: middle;
`;

const ProfilePic = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const RequesterInfo = styled.div`
  display: flex;
  align-items: center;
`;

const PendingReceivedReqs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const requests = [
    {
      name: "Albert Flores",
      role: "CorporateAdmin",
      requestedFor: {
        utilityName: "Cabinet",
        infoData: [
          {
            title: "archive_id",
            value: "32413",
          },
          {
            title: "archive_id",
            value: "32413",
          },
          {
            title: "archive_id",
            value: "32413",
          },
        ],
      },
      profilePic: ProfileImg,
    },
    {
      name: "Theresa Webb",
      role: "SalesandDistribution",
      requestedFor: {
        utilityName: "Cabinet",
        infoData: [
          {
            title: "archive_id",
            value: "32413",
          },
          {
            title: "archive_id",
            value: "32413",
          },
          {
            title: "archive_id",
            value: "32413",
          },
        ],
      },
      profilePic: ProfileImg,
    },
    // Add more dummy requests as needed
  ];

  const [showReasonModal, setShowReasonModal] = useState(false);
  const [reason, setReason] = useState("");

  const handleReasonChange = (e) => {
    const { value } = e.target;
    setReason(value);
  };

  return (
    <Container>
      <Header>
        <HeadingStyled size="20px" color="var(--text)">
          {t("PendingReceivedRequests")}
        </HeadingStyled>
        <ButtonStyled size="14px" className="py-2" label={t("view_all")} onClick={() => navigate("/requests")} />
      </Header>
      <Box className="table-responsive">
        <Table>
          <thead>
            <tr>
              <TableHeader>{t("Requester")}</TableHeader>
              <TableHeader>{t("Requestedfor")}</TableHeader>
              <TableHeader>{t("Decision")}</TableHeader>
            </tr>
          </thead>
          <tbody>
            {requests.map((request, index) => (
              <TableRow key={index}>
                <TableData>
                  <RequesterInfo>
                    <ProfilePic src={request.profilePic} alt={request.name} />
                    <div>
                      <TextStyled
                        className="mb-0"
                        size="14px"
                        color="var(--text)"
                        weight="500"
                      >
                        {request.name}
                      </TextStyled>
                      <TextStyled
                        className="mb-0"
                        size="12px"
                        color="var(--text2)"
                        weight="500"
                      >
                        {t(request.role)}
                      </TextStyled>
                    </div>
                  </RequesterInfo>
                </TableData>
                <TableData>
                  <Box className="d-flex align-items-center gap-2">
                    <TooltipInfo data={request.requestedFor.infoData} />
                    <TextStyled
                      className="mb-0"
                      size="14px"
                      color="var(--text)"
                    >
                      {t(request.requestedFor.utilityName)}
                    </TextStyled>
                  </Box>
                </TableData>
                <TableData>
                  <Box className="d-flex">
                    <ButtonStyled
                      bg="var(--themeColor2)"
                      size="14px"
                      className={"py-1"}
                      label={t("approve")}
                    />
                    <ButtonStyled
                      bg="var(--redColor)"
                      size="14px"
                      className={"py-1 mx-2"}
                      label={t("reject")}
					  onClick={() => setShowReasonModal(true)}
                    />
                  </Box>
                </TableData>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </Box>

      <ReasonModal
        show={showReasonModal}
        onHide={() => setShowReasonModal(false)}
        onChange={handleReasonChange}
      />
    </Container>
  );
};

export default PendingReceivedReqs;
