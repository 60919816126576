import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowDown, ArrowUp, Cancel, LogoAr, LogoEn } from "../../styled/AllImages";
import { AdminSidebarWrapper } from "./styled";
import { Box, LazyImage, SpanStyled } from "../../styled/Elements";
import { SidebarList } from "../../../helpers/adminConstants/AdminConstants";

const AdminSidebar = ({ sidebarStatus, setSidebarStatus }) => {
    const location = useLocation();
    const {
        t,
        i18n: { language },
    } = useTranslation();



    /* const [menuOpen, setMenuOpen] = useState(true);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        document.body.classList.toggle("bodyClosed");
    }; */
    const [activeSubMenu, setActiveSubMenu] = useState();
    const handleSubMenuToggle = (index) => {
        setActiveSubMenu(activeSubMenu === index ? null : index);
    };
    const handleMenuItemClick = (index) => {
        setActiveSubMenu(null);
    };
    return (
        <>
            <AdminSidebarWrapper
                className={`SideBar ${sidebarStatus ? "open" : "close"}`}
            >
                <Box className="text-center">
                    <Box className="logo-container d-flex justify-content-between">
                        {language === "en" ? <LazyImage src={LogoEn} /> : <LazyImage src={LogoAr} />}
                        <button
                            className="border-0 bg-transparent p-0"
                            onClick={() => setSidebarStatus(false)}
                        >
                            <Cancel className="close d-lg-none d-block" />
                        </button>
                    </Box>
                    <Box className="border-seprator"></Box>
                    <Box className="sidebar-list custom-scroll">
                        <ul className="list-unstyled mb-0 pt-3">
                            {SidebarList.map((item, key) => {
                                // Check if the current location matches any submenu item to set the parent menu as active
                                // const isSubmenuActive = item.submenu && item.submenu.some(subitem => location.pathname.includes(subitem.url));
                                const active = location.pathname.includes(item.url) ? "active" : "";
                                // const hasSubMenu = item.submenu && item.submenu.length > 0;



                                const hasSubMenu = item.submenu && item.submenu.length > 0;

                                // Check if the current URL matches the parent menu or any of the submenu items
                                const isSubmenuActive = item.submenu && item.submenu.some(subitem => location.pathname.includes(subitem.url));
                                const isParentActive = location.pathname.includes(item.url) || isSubmenuActive;
                        
                                // Add active class to parent li if the submenu is active or the parent item is active
                                const activeClass = isParentActive ? "active" : "";




                                return (
                                    // <li key={item.title + key} className="mb-2">            
                                    <li key={item.title + key} className="mb-2" >
                                        <Link
                                            to={item.url}
                                            className={`text-decoration-none d-flex align-items-center ${activeClass} ${active}`}
                                            // className={`text-decoration-none d-flex align-items-center ${hasSubMenu && activeSubMenu === key ? "active" : ""} ${activeClass} ${active}`} //Keep Active on Click Submenu
                                            onClick={() => {
                                                handleMenuItemClick(key);
                                                hasSubMenu && handleSubMenuToggle(key);
                                            }}
                                        >
                                            <Box className="list-icon mx-2">
                                                <item.icon />
                                            </Box>
                                            <Box>
                                                <SpanStyled
                                                    className="text-capitalize d-flex"
                                                    style={{ textAlign: "start" }}
                                                >
                                                    {t(item.title)}
                                                </SpanStyled>
                                            </Box>
                                            {hasSubMenu && (
                                                <Box className="ms-auto subArrow">
                                                    {activeSubMenu === key ? <ArrowDown /> : <ArrowUp />}
                                                </Box>
                                            )}
                                        </Link>
                                        {hasSubMenu && activeSubMenu === key && (
                                            <ul className="subMenu">
                                                {item.submenu.map((subitem, subkey) => (
                                                    <li key={subitem.title + subkey}>
                                                        <Link
                                                            to={subitem.url}
                                                            className={`text-decoration-none w-100 d-block ${location.pathname.includes(subitem.url) &&
                                                                "subActive"
                                                                }`}
                                                        >
                                                            {t(subitem.title)}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </Box>
                </Box>
            </AdminSidebarWrapper>
        </>
    );
};

export default AdminSidebar;
