import React, { useState } from "react";
import {
  Box,
  FormButtonStyled,
  LabelStyled,
  SpanStyled,
  TextStyled,
} from "../../../../components/styled/Elements";
import CustomRadioButton from "../../../../components/common/radioButtons/CustomRadio";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import ValidatedInput from "../../../../components/common/inputFields/ValidatedInput";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SignupStep1 = ({handleSteps}) => {
  const { t } = useTranslation();
  const { register } = useForm();
  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = useState(1);

  const handleChange = (e) => {
    const { value } = e.target;

    setSelectedOption(parseInt(value));
  };

  return (
    <Step1Wrapper>
      <Form>
        <Box>
          <Box>
            <ValidatedInput
              name="name"
              label={"name"}
              type="input"
              fieldType="text"
              placeholder={"namePlaceholder"}
              className={"mb-3 combined flex-wrap p-0"}
              bg="var(--white)"
              register={register}
            />
          </Box>
          <Box className="mb-3">
            <Box>
              <LabelStyled
                size="16px"
                weight="bold"
                color="var(--text)"
                className="mb-1"
              >
                {t("type")}
              </LabelStyled>
            </Box>
            <Box className="d-flex gap-3">
              <CustomRadioButton
                name="type"
                label={t("organization")}
                value={1}
                checked={selectedOption === 1}
                onChange={handleChange}
              />

              <CustomRadioButton
                name="type"
                label={t("individual")}
                value={2}
                checked={selectedOption === 2}
                onChange={handleChange}
              />
            </Box>
          </Box>
          <Box>
            <ValidatedInput
              name="organization_name"
              label={"organization_name"}
              type="input"
              fieldType="text"
              placeholder={"orgNamePlaceholder"}
              className={"mb-3 combined flex-wrap p-0"}
              bg="var(--white)"
              register={register}
            />
          </Box>
          <Box>
            <ValidatedInput
              name="email"
              label={"email"}
              type="input"
              fieldType="email"
              placeholder={"emailPlaceholder"}
              className={"mb-3 combined flex-wrap p-0"}
              bg="var(--white)"
              register={register}
            />
          </Box>
          <Box>
            <ValidatedInput
              name="phone"
              label={"phone"}
              type="input"
              fieldType="text"
              placeholder={"phonePlaceholder"}
              className={"mb-3 combined flex-wrap p-0"}
              bg="var(--white)"
              register={register}
            />
          </Box>
          <Box>
            <ValidatedInput
              name="password"
              label={"password"}
              type="input"
              fieldType="password"
              placeholder={"passwordPlaceholder"}
              className={"mb-3 combined flex-wrap p-0"}
              bg="var(--white)"
              register={register}
            />
          </Box>
          <Box>
            <ValidatedInput
              name="confirm_password"
              label={"confirm_password"}
              type="input"
              fieldType="password"
              placeholder={"confirmPasswordPlaceholder"}
              className={"mb-3 combined flex-wrap p-0"}
              bg="var(--white)"
              register={register}
            />
          </Box>
        </Box>
        <FormButtonStyled
          label={t("next")}
          color="var(--white)"
          bg="var(--themeColor)"
          className="w-100"
          clName="w-100 my-4"
          onClick={handleSteps}
        />
        <Box className="d-flex justify-content-end">
          <Box>
            <TextStyled color="var(--text2  )" size="16px" weight="500">
              {t("already_have_account")}
              &emsp;
              <SpanStyled
                className="cursor-pointer"
                color="var(--themeColor)"
                size="16px"
                weight="700"
                onClick={() => navigate('/login')}
              >
                {t("sign_in")}
              </SpanStyled>
            </TextStyled>
          </Box>
        </Box>
      </Form>
    </Step1Wrapper>
  );
};

export default SignupStep1;

const Step1Wrapper = styled.div``;
