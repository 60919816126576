import React, { useState } from "react";
import DataWrapper from "./DataWrapper";
import CloneWrapper from "./CloneWrapper";
import styled from "styled-components";
import UserDataModal from "./DataModal";
import { usersList } from "../../../../../../../helpers/dummyConstants";
import { useTranslation } from "react-i18next";

const AddSingleUser = ({mainParents, title, icon, selPlaceholder}) => {
    const { t } = useTranslation();
  const [selectedMainParents, setSelectedMainParents] = useState([]);
  const [pendingSelection, setPendingSelection] = useState(null); // Pending selection for the modal
  const [showUserDataModal, setShowUserDataModal] = useState(false);

  const handleAddUserClick = () => {
    if (pendingSelection) {
      setSelectedMainParents([pendingSelection]); // Replace previous users with the newly selected user
      setShowUserDataModal(false); // Close the modal after adding the user
    }
  };

  const handleRemoveClick = () => {
    setSelectedMainParents([]); // Remove the selected user
  };

  return (
    <MultiUserRow>
      <CloneWrapper selectedMainParents={selectedMainParents} onRemoveClick={handleRemoveClick} />
      <DataWrapper
				icon={icon}
				selPlaceholder={t(selPlaceholder)} 
        setShowUserDataModal={setShowUserDataModal} />
      <UserDataModal
        title={t(title)}
        show={showUserDataModal}
        onHide={() => setShowUserDataModal(false)}
        mainParents={mainParents}
        pendingSelection={pendingSelection}
        setPendingSelection={setPendingSelection} // Set pending selection on radio button click
        handleAddUserClick={handleAddUserClick} // Add user only when "Add" button is clicked
      />
    </MultiUserRow>
  );
};

export default AddSingleUser;

const MultiUserRow = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;
