import React from "react";
import {
  Box,
  ButtonStyled,
  HeadingStyled,
  LazyImage,
  TextStyled,
} from "../../../../components/styled/Elements";
import { ForgetPassImg } from "../../../../components/styled/AllImages";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const EmailSentModal = (props) => {
    const {t} = useTranslation()
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Box className="d-flex flex-column align-items-center py-3 px-2">
          <Box>
            <LazyImage src={ForgetPassImg} />
          </Box>
          <Box>
            <HeadingStyled size="24px" weight="bold" color="var(--text)">
              {t("check_your_email")}
            </HeadingStyled>
          </Box>
          <Box>
            <TextStyled
              size="16px"
              weight="500"
              color="var(--text)"
              className="text-center px-3"
            >
              {t("we_emailed_you_instructions_to_reset_pass")}
            </TextStyled>
          </Box>
          <ButtonStyled
            onClick={props.onHide}
            className="w-100"
            clName="w-100"
            label={t("ok")}
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default EmailSentModal;
