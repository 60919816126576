import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import {
  Box,
  ButtonStyled,
  HeadingStyled,
  LabelStyled,
  SubHeadingStyled,
  TextStyled,
} from "../../../styled/Elements";
import ValidatedInput from "../../../common/inputFields/ValidatedInput";
import { useTranslation } from "react-i18next";

const DeleteModal = ({ show, onHide, title }) => {
    const {t} = useTranslation()
  return (
    <Modal show={show} onHide={onHide} centered>
      <DeleteModalWrapper>
        <Box className="p-4">
          <Box className="mb-4">
            <Box>
              <SubHeadingStyled size="22px" weight={300} color="var(--redColor)">
                {`${t("delete")} ${title}`}
              </SubHeadingStyled>
              <TextStyled size="16px" color="var(--text2)">
                {t(`confirm_to_delete`, {title})}
              </TextStyled>
            </Box>
          </Box>
          <Box className="d-flex justify-content-end">
            <Box className="mt-4 d-flex gap-3">
              <ButtonStyled
                label={t("Cancel")}
                bg={"var(--bgSecondary)"}
                color={"var(--text2)"}
                onClick={onHide}
              />
              <ButtonStyled label={t("yes_delete")} bg="var(--redColor)" />
            </Box>
          </Box>
        </Box>
      </DeleteModalWrapper>
    </Modal>
  );
};

export default DeleteModal;

const DeleteModalWrapper = styled.div``;
