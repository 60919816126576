import React, { useState } from "react";
import DataWrapper from "./DataWrapper";
import CloneWrapper from "./CloneWrapper";
import styled from "styled-components";
import UserDataModal from "./DataModal";
import { usersList } from "../../../../../../../helpers/dummyConstants";
import { useTranslation } from "react-i18next";

const AddUsers = ({mainParents, title, icon, selPlaceholder}) => {
    const { t } = useTranslation();
  const [selectedMainParents, setSelectedMainParents] = useState([]);
	const [showUserDataModal, setShowUserDataModal] = useState(false);

  /* const handleMainParentClick = (mainParent) => {
    if (selectedMainParents.some((selected) => selected.id === mainParent.id)) {
      setSelectedMainParents(
        selectedMainParents.filter((selected) => selected.id !== mainParent.id)
      );
    } else {
      setSelectedMainParents([...selectedMainParents, mainParent]);
    }
  }; */

  
  const handleRemoveClick = (parentId) => {
		// Remove the selected data based on its ID
		setSelectedMainParents(selectedMainParents.filter((selected) => selected.id !== parentId));
	};

  return (
    <MultiUserRow>
      
      <CloneWrapper selectedMainParents={selectedMainParents} onRemoveClick={handleRemoveClick} />
      <DataWrapper
				icon={icon}
				selPlaceholder={t(selPlaceholder)}
				setShowUserDataModal={setShowUserDataModal} />
      {/* <UserDataModal
					show={showUserDataModal}
					onHide={() => setShowUserDataModal(false)}
        mainParents={usersList}
        onMainParentClick={handleMainParentClick}
        selectedMainParents={selectedMainParents}
      /> */}
      <UserDataModal
        title={t(title)}
        show={showUserDataModal}
        onHide={() => setShowUserDataModal(false)}
        mainParents={mainParents}
        selectedMainParents={selectedMainParents}
        setSelectedMainParents={setSelectedMainParents} // Pass the function here
      />
    </MultiUserRow>
  );
};

export default AddUsers;

const MultiUserRow = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;
