import React from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import NewCustomRadio from "../radioButtons/NewCustomRadio";
import { useTranslation } from "react-i18next";
import FloatingInput from "../inputFields/FloatingInput";
import { Box, SpanStyled } from "../../styled/Elements";
import { usersList } from "../../../helpers/dummyConstants";
import { SearchIcon } from "../../styled/AllImages";

export function UsersDropdown({ onSearch, userList, selecteduser, placeholder }) {
  const { t } = useTranslation();

  return (
    <StyledDropdown>
      <Dropdown>
        <Dropdown.Toggle variant="light" id="dropdown-basic">
          {selecteduser ? selecteduser.name : (
            <SpanStyled color="var(--text2)">{placeholder}</SpanStyled>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu className="custom-scroll">
          <Box className="mb-1 mt-3 mx-4">
            <FloatingInput
              name="search"
              type="input"
              fieldType="text"
              label={t("search")}
              placeholder={t("search")}
              icon={SearchIcon}
              onChange={onSearch}
            />
          </Box>

          {usersList.map((user, index) => (
            <div key={index} className="user-option">
              <NewCustomRadio
                name={"user"}
                avatar={user.profile}
                label={t(user.userName)}
                subLabel={t(user.userRole)}
                className={"px-4"}
              />
            </div>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </StyledDropdown>
  );
}
export function DepartmentDropdown({ onSearch, departmentList, selectedDepartment, placeholder }) {
  const { t } = useTranslation();

  return (
    <StyledDropdown>
      <Dropdown>
        <Dropdown.Toggle variant="light" id="dropdown-basic">
          {selectedDepartment ? selectedDepartment.name : (
            <SpanStyled color="var(--text2)">{placeholder}</SpanStyled>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu className="custom-scroll">
          <Box className="mb-1 mt-3 mx-4">
            <FloatingInput
              name="search"
              type="input"
              fieldType="text"
              label={t("search")}
              placeholder={t("search")}
              icon={SearchIcon}
              onChange={onSearch}
            />
          </Box>

          {departmentList.map((dept, index) => (
            <div key={index} className="user-option">
              <NewCustomRadio
                name={"department"}
                label={t(dept.departmentName)}
                subLabel={t(dept.branch)}
                className={"px-4"}
              />
            </div>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </StyledDropdown>
  );
}

const StyledDropdown = styled.div`
  .dropdown-toggle {
    position: relative;
    width: 100%;
    height: 48px;
    text-align: start;
    border-radius: 10px;
    border: 1px solid var(--borderLight);
    padding: 10px;
    background-color: var(--white);
    font-size: 14px;
    color: var(--text);
  }
  .dropdown-toggle::after {
    position: absolute;
    right: 10px;
    top: 20px;
    body.rtl &{
      right: unset;
      left: 10px;
    }
  }

  .dropdown-menu {
    max-height: 350px;
    overflow-y: auto;
    width: 400px;
    min-width: 250px;
    border-radius: 10px;
    border: 1px solid var(--borderLight);
  }

  .user-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 1px solid var(--borderLight);

    &:last-child {
      border: none;
    }

    .user-name {
      font-size: 16px;
      font-weight: 500;
    }

    .branch-name {
      font-size: 12px;
      color: gray;
    }

    .status {
      color: green;
      font-size: 18px;
    }
  }
`;
