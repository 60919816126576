import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import ValidatedInput from "../../../../components/common/inputFields/ValidatedInput";
import {
  Box,
  FileUploaderStyled,
  HeadingStyled,
  LabelStyled,
  LazyImage,
  SpanStyled,
} from "../../../../components/styled/Elements";
import { useTranslation } from "react-i18next";
import AddSingleUser from "../../cabinets/files/addFile/components/addSingleUser/MainData";
import {
  Cancel,
  DateIcon,
  FileUpload,
  Plus,
  RoundedArrow,
  TrashIcon,
} from "../../../../components/styled/AllImages";
import { departmentsList } from "../../../../helpers/dummyConstants";
import DatePickerDefault from "../../../../components/common/inputFields/DatePicker";
import CustomRadioButton from "../../../../components/common/radioButtons/CustomRadio";
import ValidationController from "../../../../components/common/inputFields/ValidationController";
import { useForm } from "react-hook-form";
import { FormDataContainer } from "../../cabinets/files/addFile/styled";

const UserPersonalInfo = () => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    getFieldState,
  } = useForm();
  const [previewImg, setPreviewImg] = useState(null);

  const handleFileChange = (e) => {
    const { files } = e.target;
    if (files[0]) {
      const objUrl = URL.createObjectURL(files[0]);
      setPreviewImg(objUrl);
    }
  };

  return (
    <FormDataContainer className="user-personal-info">
      {/* <Col lg={2}>
          <LabelStyled>{t("name")}*</LabelStyled>
        </Col>
        <Col lg={10}> */}
      <ValidatedInput
        name={"user_name"}
        type={"input"}
        fieldType={"text"}
        label="name"
        placeholder={t("namePlaceholder")}
      />
      {/* </Col> */}
      <ValidatedInput
        name={"user_id"}
        type={"input"}
        fieldType={"text"}
        label="id"
        placeholder={t("id")}
      />

      <Box className="info-item d-flex align-items-center gap-3 py-4 px-3 px-lg-4 w-100">
        <Box className="form-label">
          <LabelStyled>{t("branch_hq")}</LabelStyled>
        </Box>
        <Box>
          <AddSingleUser
            title={t("select_branch_hq")}
            icon={<Plus />}
            selPlaceholder={t("select_branch_hq")}
            mainParents={departmentsList}
          />
        </Box>
      </Box>
      <Box className="info-item d-flex align-items-center gap-3 py-4 px-3 px-lg-4 w-100">
        <Box className="form-label">
          <LabelStyled>{t("department")}</LabelStyled>
        </Box>
        <Box>
          <AddSingleUser
            title={t("select_department")}
            icon={<Plus />}
            selPlaceholder={t("select_department")}
            mainParents={departmentsList}
          />
        </Box>
      </Box>
      <ValidatedInput
        name={"location"}
        type={"input"}
        fieldType={"text"}
        label="location"
        placeholder={t("location")}
        className="combined pt-3"
      />
      <Box className="w-100">
        <Box className="info-item d-flex align-items-center py-4 w-100">
          <Col lg={12}>
            <Box className="location-map-cont mx-3 my-3 mx-lg-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3623.8758235021496!2d46.667506475346116!3d24.73114305041999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f1d4d2f832a77%3A0x88aba85942837e42!2sCenter%20Point%2C%20King%20Abdullah%20Rd%2C%20King%20Fahd%2C%20Riyadh%2012271%2C%20Saudi%20Arabia!5e0!3m2!1sen!2s!4v1729505560340!5m2!1sen!2s"
                style={{ border: "0" }}
                allowfullscreen=""
                referrerpolicy="no-referrer-when-downgrade"
                className="location-map"
              ></iframe>
            </Box>
          </Col>
        </Box>
      </Box>
      <ValidatedInput
        name={"email"}
        type={"input"}
        fieldType={"email"}
        placeholder={t("emailPlaceholder")}
        label={t("email")}
      />
      <ValidatedInput
        name={"contact_no"}
        type={"input"}
        fieldType={"number"}
        placeholder={t("phonePlaceholder")}
        label={t("contact_no")}
      />

      <Box className="info-item gap-2 d-flex align-items-center py-4 px-3 px-lg-4 w-100">
        <LabelStyled size="15px" color="var(--text)" className="form-label">
          {t("date_of_birth")}*
        </LabelStyled>
        <Box className="w-100">
          <DatePickerDefault
            name={"date"}
            type={"input"}
            placeholder={t("date")}
            icon={DateIcon}
          />
        </Box>
      </Box>
      <ValidatedInput
        name={"password"}
        type={"input"}
        fieldType={"password"}
        placeholder={t("passwordPlaceholder")}
        label={t("password")}
      />
      <ValidatedInput
        name={"confirm_password"}
        type={"input"}
        fieldType={"password"}
        placeholder={t("confirmPasswordPlaceholder")}
        label={t("confirm_password")}
      />

      <Box className="w-100">
        <Box className="info-item d-flex align-items-center flex-wrap gap-3 py-4 px-3 px-lg-4">
          <Box className="form-label">
            <LabelStyled>{t("type")}*</LabelStyled>
          </Box>
          <Col>
            <Box className="d-flex align-items-center gap-2">
              <Col lg={3}>
                <CustomRadioButton
                  name={"user_type"}
                  label={t("user")}
                  value={1}
                />
              </Col>
              <Col lg={3}>
                <CustomRadioButton
                  name={"user_type"}
                  label={t("admin")}
                  value={2}
                />
              </Col>
            </Box>
          </Col>

          <Col lg={12} className="mt-3">
            <ValidatedInput
              name={"licenses"}
              type={"input"}
              fieldType={"text"}
              placeholder={t("no_of_licenses")}
              label="no_of_licenses_allowed"
              className={"combined px-0"}
            />
          </Col>
        </Box>
      </Box>
      <Box className="w-100">
        <Box className="info-item d-flex flex-wrap align-items-center gap-3 py-4 px-3 px-lg-4">
          <Box className="form-label">
            <LabelStyled>{t("upload")}</LabelStyled>
          </Box>
          <Col>
            <Box className="d-flex align-items-center gap-2">
              <Col lg={4}>
                <ValidationController
                  name={"job_id"}
                  validationConfig={{
                    required: true ? `${t("fieldRequired")}` : "",
                  }}
                  errors={errors}
                  control={control}
                  previewImg={previewImg}
                  renderer={({ field }) => (
                    <FileUploaderStyled className="d-flex align-items-center justify-content-center w-100">
                      <Form
                        as="input"
                        name="job_id"
                        type="file"
                        validationConfig={{
                          required: true ? `${t("fieldRequired")}` : "",
                        }}
                        fieldState={getFieldState("job_id")}
                        accept="image/*"
                        onChange={handleFileChange}
                        className="d-none"
                      />
                      {previewImg ? (
                        <Box
                          className="d-flex align-items-center justify-content-between w-100 mt-n4 preview-box"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <LazyImage src={previewImg} className="preview-img" />
                          <Box className="d-flex align-items-center flex-column gap-1">
                            <span
                              className="default-icon replace-icon"
                              // onClick={handleReplaceFile}
                            >
                              <RoundedArrow />
                            </span>
                            <span
                              className="default-icon delete-icon"
                              // onClick={handleDeleteFile}
                            >
                              <TrashIcon />
                            </span>
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <Box className="d-flex align-items-center justify-content-center mt-n4 w-100">
                            <FileUpload size={26} />
                            &nbsp;&nbsp;
                            <SpanStyled color="var(--text)" size="14px">
                              {t("upload_job_id")}
                            </SpanStyled>
                          </Box>
                        </>
                      )}
                    </FileUploaderStyled>
                  )}
                />
              </Col>
              <Col lg={4}>
                <ValidationController
                  name={"job_id"}
                  validationConfig={{
                    required: true ? `${t("fieldRequired")}` : "",
                  }}
                  errors={errors}
                  control={control}
                  previewImg={previewImg}
                  renderer={({ field }) => (
                    <FileUploaderStyled className="d-flex align-items-center justify-content-center w-100">
                      <Form
                        as="input"
                        name="job_id"
                        type="file"
                        validationConfig={{
                          required: true ? `${t("fieldRequired")}` : "",
                        }}
                        fieldState={getFieldState("job_id")}
                        accept="image/*"
                        onChange={handleFileChange}
                        className="d-none"
                      />
                      {previewImg ? (
                        <Box
                          className="d-flex align-items-center justify-content-between w-100 mt-n4 preview-box"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <LazyImage src={previewImg} className="preview-img" />
                          <Box className="d-flex align-items-center flex-column gap-1">
                            <span
                              className="default-icon replace-icon"
                              // onClick={handleReplaceFile}
                            >
                              <RoundedArrow />
                            </span>
                            <span
                              className="default-icon delete-icon"
                              // onClick={handleDeleteFile}
                            >
                              <TrashIcon />
                            </span>
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <Box className="d-flex align-items-center justify-content-center mt-n4 w-100">
                            <FileUpload size={26} />
                            &nbsp;&nbsp;
                            <SpanStyled color="var(--text)" size="14px">
                              {t("upload_job_id")}
                            </SpanStyled>
                          </Box>
                        </>
                      )}
                    </FileUploaderStyled>
                  )}
                />
              </Col>
            </Box>
          </Col>
        </Box>
      </Box>
    </FormDataContainer>
  );
};

export default UserPersonalInfo;
