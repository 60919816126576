import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { HQBranchDetailsWrapper } from "./styled";
import { BackButton, Box, HeadingStyled } from "../../../components/styled/Elements";
import Breadcrumbs from "../../../components/admin/Breadcrumbs/Breadcrumbs";
import { Col, Row } from "react-bootstrap";
import { FormDataContainer } from "../settings/styled";
import ViewPage from "../../../components/common/pageView/pageView";
import { viewHqBranch, viewHqBranchContact, viewHqBranchLocation } from "../../../helpers/dummyConstants";

const HQBranchDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <HQBranchDetailsWrapper>
            <Box className="content-head py-3 px-3 px-md-4">
                <Box className="d-flex align-items-center gap-5">
                    <BackButton
                        label={t("view_hq_branch")}
                        onClick={() => navigate("/hq-branches")}
                    />
                    <Breadcrumbs />
                </Box>
            </Box>
            <Box className="content-container">
                <Row className="mt-3">
                    <Col md={12} className="mb-4">
                        <FormDataContainer className="dataContainer">
                            {viewHqBranch.map((view, i) => (
                                <ViewPage
                                    key={view.id}
                                    label={view.label}
                                    text= {view.text}
                                    render={view.render ? view.render : null}
                                />
                            ))}
                        </FormDataContainer>
                    </Col>
                    <Col md={12} className="mb-4">
                    <HeadingStyled size="20px" weight="400" color="var(--text)" className="mb-2">{t("location")}</HeadingStyled>
                        <FormDataContainer className="dataContainer">
                            {viewHqBranchLocation.map((view, i) => (
                                <ViewPage
                                    key={view.id}
                                    label={view.label}
                                    text= {view.text}
                                    render={view.render ? view.render : null}
                                />
                            ))}
                        </FormDataContainer>
                    </Col>
                    <Col md={12} className="mb-4">
                    <HeadingStyled size="20px" weight="400" color="var(--text)" className="mb-2">{t("contact")}</HeadingStyled>
                        <FormDataContainer className="dataContainer">
                            {viewHqBranchContact.map((view, i) => (
                                <ViewPage
                                    key={view.id}
                                    label={view.label}
                                    text= {view.text}
                                    render={view.render ? view.render : null}
                                />
                            ))}
                        </FormDataContainer>
                    </Col>
                </Row>
            </Box>
        </HQBranchDetailsWrapper>
    );
};

export default HQBranchDetails;
