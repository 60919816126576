import React, { useState } from "react";
import { Box, LabelStyled } from "../../styled/Elements";
import { Form, FormGroup } from "react-bootstrap";
import styled from "styled-components";
import { Eye, Eyelash } from "../../styled/AllImages";
import { useTranslation } from "react-i18next";

const ValidatedInput = ({
  name,
  type,
  fieldType,
  placeholder,
  label,
  value,
  register,
  validationRules,
  className,
  disabled,
  staric,
  ...rest
}) => {
  const { t } = useTranslation();
  const [showType, setType] = useState(fieldType);

  const inputStyles = type === "textarea" ? { height: "130px" } : {};

  return (
    <ValidatedInputWrapper className={className} {...rest}>
      <FormGroup controlId={name} className="formEleContainer">
        {label && (
          <LabelStyled
            size="15px"
            color="var(--text)"
          >
            {t(label)}
            {staric && (
                <span className="staric">*</span>
            )}
          </LabelStyled>
        )}
        <Box className="position-relative w-100">
          <Form.Control
            as={type}
            type={showType}
            value={value}
            disabled={disabled}
            placeholder={t(placeholder)}
            style={inputStyles}
            {...(register && register(name, validationRules))}
          />
          {fieldType === "password" && (
            <>
              {showType === "password" ? (
                <Eyelash
                  className="eye-btn position-absolute cursor-pointer"
                  onClick={() => setType("text")}
                />
              ) : (
                <Eye
                  className="eye-btn position-absolute cursor-pointer"
                  onClick={() => setType("password")}
                />
              )}
            </>
          )}
        </Box>
      </FormGroup>
    </ValidatedInputWrapper>
  );
};

export default ValidatedInput;

export const ValidatedInputWrapper = styled.div`
    width: 100%;
    padding: 9px 25px 9px 25px;
    border-bottom: 1px solid var(--borderLight);
    width: 100%;
    .formEleContainer {
        display: flex;
        padding: 7px 0px 7px 0px;
        margin: 7px 0 7px 0;
        align-items: center;
        gap: 10px;
        & > label {
        min-width: 160px;
        }
        &.inp-white {
            input {
                background-color: var(--white);
            }
        }
    }
    &.combined{
        border-bottom: none;
        padding: 8px 25px 8px 25px;
        /* &:last-child{
            border-bottom: 1px solid var(--borderLight);
        } */
        .formEleContainer {
            display: flex;
            padding: 0;
            margin: 0;
        }
    }
    &.flex-wrap{
        .formEleContainer {
            flex-wrap: wrap;
            label{
                width: 100%;
            }
        }
        
    }
    .form-floating {
        width: 100%;
    }
    input[type="text"] {
        width: 100%;
    }

  .form-control {
    height: 48px;
    box-shadow: none;
    outline: none;
    border-radius: 10px;
    border: 1px solid var(--border);
    background-color: ${({ bg }) => (bg ? bg : "var(--bgSecondary)")};
    &::placeholder {
      color: var(--textPlaceholder2);
    }
  }
  .form-control:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid var(--border);
  }
  @media (max-width: 767px){
    .formEleContainer {
        flex-wrap: wrap;
        gap: 3px;
        margin: 0;
        padding: 3px 0px 3px 0px;
        label{
            width: 100%;
        }
    }
    }
`
