import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import LangChangeButton from "../../../languages/LangChangeButton";
import { HeaderContainer } from "./styled";
import { Box, ButtonStyled, LazyImage } from "../../styled/Elements";
import { LogoAr, LogoEn } from "../../styled/AllImages";

const AuthHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasLoginInLocation = location.pathname.includes("login");
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Box>
      <HeaderContainer>
        <Box
          className={`${
            isSticky ? "sticky" : ""
          } header-container px-3 px-xl-5`}
        >
          <Box className="nav-bar">
            <Box className="logo" onClick={() => navigate("/")}>
              {language === "ar" ? (
                <LazyImage src={LogoAr} />
              ) : (
                <LazyImage src={LogoEn} />
              )}
            </Box>
            <Box className="whtLngWrapper d-flex align-items-center justify-content-center">
              {hasLoginInLocation ? (
                <ButtonStyled
                  label={t("sign_up")}
                  color="var(--white)"
                  bg="var(--themeColor)"
                  clName={"signup-btn"}
                  className={"py-2"}
                  size="16px"
                  onClick={() => navigate('/signup')}
                />
              ) : (
                <ButtonStyled
                  label={t("sign_in")}
                  color="var(--white)"
                  bg="var(--themeColor)"
                  clName={"signin-btn"}
                  className={"py-2"}
                  size="16px"
                  onClick={() => navigate('/login')}
                />
              )}
              <LangChangeButton />
            </Box>
          </Box>
        </Box>
      </HeaderContainer>
    </Box>
  );
};

export default AuthHeader;
