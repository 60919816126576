import React from "react";
import {
  Box,
  ButtonStyled,
  HeadingStyled,
  LazyImage,
  TextStyled,
} from "../../../../components/styled/Elements";
import { ForgetPassImg } from "../../../../components/styled/AllImages";
import { Modal } from "react-bootstrap";
import ValidatedInput from "../../../../components/common/inputFields/ValidatedInput";
import { useTranslation } from "react-i18next";

const ForgetPasswordModal = (props) => {
  const {t} = useTranslation()
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Box className="d-flex flex-column align-items-center py-3 px-2">
          <Box>
            <LazyImage src={ForgetPassImg} />
          </Box>
          <Box>
            <HeadingStyled size="24px" weight="bold" color="var(--text)">
              {t("forgot_password")}
            </HeadingStyled>
          </Box>
          <Box>
            <TextStyled
              size="16px"
              weight="500"
              color="var(--text)"
              className="text-center px-3"
            >
              {t("enter_email_below_to_get_reset_link")}
            </TextStyled>
          </Box>
          <Box className="w-100">
            <ValidatedInput
              name="email"
              label={"email"}
              type="input"
              fieldType="text"
              placeholder={"emailPlaceholder"}
              className={"w-100 mb-3 combined flex-wrap p-0"}
              bg="var(--white)"
            />
          </Box>
          <ButtonStyled
            onClick={props.onBtnClick}
            className="w-100"
            clName="w-100"
            label={t("send")}
          />
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default ForgetPasswordModal;
