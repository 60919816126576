import React, { useState } from "react";
// import {
//     BackButton,
//     Box,
//     ButtonStyled,
//     FormElementWrapper,
//     LabelStyled,
//     SpanStyled,
//     SubHeadingStyled,
// } from "../../../../components/styled/Elements";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Box, BackButton, ButtonStyled, FormElementWrapper, LabelStyled, SpanStyled, SubHeadingStyled, DataContainerHeader, } from "../../../components/styled/Elements";
import { SettingsWrapper, FormDataContainer,} from "./styled";
// import {} from "../../../../components/styled/AllImages";
// import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/admin/Breadcrumbs/Breadcrumbs";
import { overviewData, viewarchive } from "../../../helpers/dummyConstants";
import ViewPage from "../../../components/common/pageView/pageView";
import { fileTileArray, overviewTileArray } from "../../../helpers/adminConstants/AdminConstants";
import CustomTile from "../../../components/common/statusTiles/CustomTile";
// import FloatingInput from "../../../../components/common/inputFields/FloatingInput";
// import Breadcrumbs from "../../../../components/admin/Breadcrumbs/Breadcrumbs";
// import ValidatedInput from "../../../../components/common/inputFields/ValidatedInput";
// import ViewPage, { ViewPageWrapper } from "../../../../components/common/pageView/pageView";
// import { viewarchive } from "../../../../helpers/dummyConstants";

const Overview = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // const [checkSelectedValueID, setcheckSelectedValueID] = useState(null);
    // const checkSelectedValue = (e) => {
    //     setcheckSelectedValueID(Number(e.target.value));
    // };
    // const { register } = useForm();
    return (
        <SettingsWrapper>
            <Box className="content-head py-3 px-3 px-md-4">
                <SubHeadingStyled size="16px" color="var(--text)">
					{t("settings")}
				</SubHeadingStyled>
            </Box>
            <Box className="content-container">
                <Row className="mt-3">
                    <Col md={12}>
                        
                        <FormDataContainer className="dataContainer">
                            <DataContainerHeader label={t("overview")} />
                            <Box className="content-container">
                                <Row className="justify-content-center">
                                {overviewTileArray.map((tile, key) => (
                                    <Col lg={3} md={6} sm={12} className="my-2">
                                        <CustomTile key={key} {...tile} className="allBorder" />
                                    </Col>
                                ))}
                                </Row>
                            </Box>
                            <Box className="innerFoHead">
                                <SubHeadingStyled size="16px" color="var(--text)">
                                    {t("AdminDirectorInformation")}
                                </SubHeadingStyled>
                            </Box>
                            {overviewData.map((view, i) => (
                                <ViewPage
                                    key={view.id}
                                    label={view.label}
                                    text= {view.text}
                                    render={view.render ? view.render : null}
                                />
                            ))}


                        </FormDataContainer>
                    </Col>
                </Row>
            </Box>
        </SettingsWrapper>

    );
};

export default Overview;
