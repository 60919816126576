import { Navigate } from "react-router-dom";
import Login from "../pages/auth/login/Login";
import SignUp from "../pages/auth/signUp/SignUp";
import LoginWithPhone from "../pages/auth/loginWithPhone/LoginWithPhone";
import ResetPassword from "../pages/auth/resetPassword/ResetPassword";

export const publicRoutes = [
    {
        path: "login",
        element: <Login/>,
    },
    {
        path: "signup",
        element: <SignUp/>,
    },
    {
        path: "login-with-phone",
        element: <LoginWithPhone/>,
    },
    {
        path: "reset-password",
        element: <ResetPassword/>,
    },
    {
        path: "",
        element: <Navigate to={'/login'} replace />
    }
]