import { useTranslation } from "react-i18next";
import {
  HalfEyeIcon,
  InfoTooltip,
  ReverseArrows,
  SettingIcon,
  TrashRedIcon,
} from "../../../styled/AllImages";
import TooltipInfo from "../../tooltip/InfoTooltip";
import ProfileTooltip from "../../tooltip/ProfileTooltip";

const ActionButtons = ({
  row,
  onEdit,
  onDelete,
  onOpen,
  onViewDetails,
  onStatusToggle,
  status,
  options,
  onInfoView,
  onTransfer,
  tooltipData,
  department,
  
  
}) => {
  const { t } = useTranslation();
//   const departmentInfo = department.department_info; // Access the department_info array
  // Check if department exists to prevent accessing undefined properties
  const departmentInfo = department ? department.department_info : []; // Use empty array as fallback
  return (
    <div className="action-buttons">
      {options && options.includes("open") && (
        <button onClick={onOpen}>{t('open')}</button>
      )}
      {options && options.includes("tooltip") && (
        <TooltipInfo data={tooltipData} />
      )}
      {/* {options && options.includes("tooltip2") && (
        <ProfileTooltip 
            data={departmentInfo}
            userName={department.userName}
            userRole={department.userRole}
            userImage={department.userImage}
        />
      )} */}
      {options && options.includes("tooltip2") && department && ( // Render only if department exists
        <ProfileTooltip 
            data={departmentInfo}
            userName={department.userName}
            userRole={department.userRole}
            userImage={department.userImage}
        />
      )}
      {options && options.includes("view") && (
        <span className="default-icon" onClick={() => onViewDetails(row)}>
          <HalfEyeIcon />
        </span>
      )}
      {options && options.includes("info") && (
        <span className="default-icon" onClick={onInfoView}>
          <InfoTooltip />
        </span>
      )}
      {options && options.includes("edit") && (
        <span onClick={() => onEdit(row)} className="default-icon">
          <SettingIcon />
        </span>
      )}
      {options && options.includes("transfer") && (
        <span className="default-icon" onClick={onTransfer}>
          <ReverseArrows />
        </span>
      )}
      {options && options.includes("status") && (
        <div
          className={`switch ${status ? "active" : ""}`}
          onClick={onStatusToggle}
        >
          <span className="slider" />
        </div>
      )}
      {options && options.includes("delete") && (
        <span onClick={onDelete} className="default-icon">
          <TrashRedIcon />
        </span>
      )}
    </div>
  );
};

export default ActionButtons;
