import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-datepicker/dist/react-datepicker.css";
import 'swiper/css';
import "./assets/css/style.scss"
import "./assets/css/rtlStyle.scss"
import "./assets/css/responsive.scss"
import "./languages/INEXT";

const rootEl = document.getElementById("root");
const root = ReactDOM.createRoot(rootEl);
root.render(
  <Router>
    <App />
  </Router>
);
