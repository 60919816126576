import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Pagination from "react-js-pagination";
import { Box } from "../../styled/Elements";
import ActionButtons from "./components/TableComponents";
import { useTranslation } from "react-i18next";
import { NoRecord } from "../../admin/NoRecord/NoRecord";

const ListTable = ({
  columns,
  rows,
  totalCount,
  perPage,
  activePage,
  setActivePage,
  onEdit,
  onDelete,
  onStatusToggle,
  onOpen,
  options,
  onInfoView,
  onTransfer,
  onViewDetails,
  className,
}) => {
  const { t } = useTranslation();

  const [pageHrefs, setPageHrefs] = useState([]);

  const handlePageChange = (e, pageNumber) => {
    setActivePage(pageNumber);
    // const currentUrl = window.location.href.split("?")[0];
    // window.location.replace(`${currentUrl}?page=${pageNumber}`);
  };

  useEffect(() => {
    const url = window.location.href.split("?")[0];
    const hrefs = Array.from(
      { length: Math.ceil(totalCount / perPage) },
      (_, index) => `${url}?page=${index + 1}`
    );
    setPageHrefs(hrefs);
  }, [totalCount, perPage]);

  return (
    <>
    {rows && rows.length > 0 ? (
      <TableWrapper>
        {}
        <Box className="table-responsive custom-scroll overflow-x-auto">
          <table className={`w-100 ${className ? className : ""}`}>
            <thead>
              <tr>
                {columns.map((col, key) => (
                  <th
                    key={key}
                    className={`text-nowrap ${
                      col.name === "actions" && "actions-column"
                    }`}
                  >
                    {t(col.label)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows?.map((row, index) => (
                <tr key={index}>
                  {columns?.map((col, key) => (
                    <td key={key}>
                      {col.name === "actions" ? (
                        <ActionButtons
                          row={row}
                          options={options}
                          onEdit={() => onEdit(row)}
                          onDelete={() => onDelete(row)}
                          onOpen={() => onOpen(row)}
                          onStatusToggle={() => onStatusToggle(row)}
                          onInfoView={onInfoView}
                          status={row.status}
                          onTransfer={onTransfer}
                          onViewDetails={onViewDetails}
                          tooltipData={row.infoData}
                          department={row.department}
                        />
                      ) : col.render ? (
                        col.render(row[col.name], row, t)
                      ) : (
                        row[col.name]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        {totalCount && (
          <PaginationContainer>
            <Pagination
              itemClass="page-item"
              linkClass="page-link"
              activePage={activePage}
              itemsCountPerPage={perPage}
              totalItemsCount={totalCount}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              getPageUrl={(pageNumber) => pageHrefs[pageNumber - 1]}
            />
          </PaginationContainer>
        )}
      </TableWrapper>
    ) : (
      <NoRecord />
    )}
    </>
  );
};

export const TableWrapper = styled.div`
  padding: 1rem;

  table {
    border-collapse: separate;
    border-spacing: 0 4px;
    tr {
      border-radius: 10px;
    }
    thead tr {
      background-color: none;
    }
    th {
      font-weight: 600;
      font-size: 14px;
      color: var(--text);
      padding: 12px 16px;
      background-color: inherit;
      border: none;
      text-align: start;
    }
    .actions-column {
      width: 100px;
    }

    tbody tr {
      td {
        max-width: 400px;
        background-color: var(--white);
        border-bottom: 1px solid var(--borderLight);
        padding: 8px 16px;
        color: var(--text);
        font-size: 14px;
        vertical-align: middle;
      }
      td:first-child {
        border-radius: 10px 0 0 10px;
        body.rtl & {
          border-radius: 0px 10px 10px 0px;
        }
      }
      td:last-child {
        border-radius: 0 10px 10px 0px;
        body.rtl & {
          border-radius: 10px 0 0 10px;
        }
      }
      .action-buttons {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        button {
          background-color: var(--white);
          color: var(--text2);
          border: none;
          padding: 0.5rem 1rem;
          cursor: pointer;
          font-size: 14px;
          border-radius: 10px;
          border: 1px solid var(--borderLight);
          &:hover {
            color: var(--text);
            background-color: var(--borderLight);
          }
        }

        .switch {
          width: 36px;
          height: 21px;
          background-color: #ccc;
          border-radius: 20px;
          position: relative;
          cursor: pointer;
          transition: background-color 0.3s;

          &.active {
            background-color: var(--themeColor2);
          }

          .slider {
            width: 16px;
            height: 16px;
            background-color: white;
            border-radius: 50%;
            position: absolute;
            top: 0.13rem;
            left: 2px;
            transition: left 0.3s;
          }
          &.active .slider {
            left: unset;
            right: 2px;
            box-shadow: 0px 4px 5.9px 0px #00000040;
          }
        }
      }
    }
    &.tableSecondary {
      border-collapse: collapse;
      border-spacing: 0 0px;
      tbody tr {
        border: 1px solid var(--borderLight);
        border-radius: 10px 0 0 10px;
        overflow: hidden;
        td {
          padding: 12px 16px;
          border: none;
        }
        td:first-child {
          border-radius: 10px 0 0 10px;
        }

        /* td:first-child {
                border-radius: 10px 0 0 10px;
            } */
      }
    }
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`;

export default ListTable;
