import React, { useState } from "react";
import { AddUsersWrapper } from "./styled";
import Breadcrumbs from "../../../components/admin/Breadcrumbs/Breadcrumbs";
import { BackButton, Box, ButtonStyled, TabsList } from "../../../components/styled/Elements";
import { userFormTabs } from "../../../helpers/adminConstants/AdminConstants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "../../../components/styled/styled";

const EditUser = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [activeTab, setActiveTab] = useState(userFormTabs[0])
    const handleTabChange = (tabIndex) => {
        setActiveTab(userFormTabs[tabIndex])
    }
  return (
    <AddUsersWrapper>
      <Box className="content-head py-3 px-3 px-md-4">
        <Box className="d-flex align-items-center gap-5">
          <BackButton
            label={t("edit_user")}
            onClick={() => navigate("/users")}
          />
          <Breadcrumbs />
        </Box>
      </Box>
      
      <Box>
        <TabsList tabs={userFormTabs} activeTab={activeTab} handleTabChange={handleTabChange} />
      </Box>
      <ContentWrapper>
        <activeTab.component />
        <Box className="d-flex justify-content-between mx-3 mx-lg-5 py-4">
            <ButtonStyled label={t("back")} bg="var(--themeColor2)" onClick={() => setActiveTab(userFormTabs[0])} disabled={activeTab === userFormTabs[0]} />
            {activeTab === userFormTabs[0] && (
                <ButtonStyled label={t("next")} bg="var(--themeColor2)" onClick={() => setActiveTab(userFormTabs[1])} />
            )}
            {activeTab === userFormTabs[1] && (
            <ButtonStyled label={t("save_create")} />
            )}
        </Box>
      </ContentWrapper>
    </AddUsersWrapper>
  );
};

export default EditUser;
