import React from "react";
import styled from "styled-components";

import {
	Box,
	SpanStyled,
} from "../../../../../../../components/styled/Elements";
import { MenuTriggerCross } from "../../../../../../../components/styled/AllImages";

const CloneWrapper = ({ selectedMainParents, onRemoveClick }) => {
	return selectedMainParents.map((mainParent) => (
		<UserUpload key={mainParent.id}>
			{mainParent.departmentName ? (
				<Box className="abtUser">
					<SpanStyled color="var(--text)" size="14px">
						{mainParent.departmentName}
					</SpanStyled>
				</Box>
			):(
				<>
					<Box className="userPlaceholder">
						<img src={mainParent.profile} alt="user" />
					</Box>
					<Box className="abtUser">
						<SpanStyled color="var(--text)" size="14px">
							{mainParent.userName}
						</SpanStyled>
						{/* <SpanStyled color="var(--greyText)" size="10px">
							{mainParent.userRole}
						</SpanStyled> */}
					</Box>
				</>
			)}
			<MenuTriggerCross className="removeTaskTrigger" onClick={() => onRemoveClick(mainParent.id)} />
		</UserUpload>
		

	));
};

export default CloneWrapper;

const UserUpload = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding: 8px 16px;
  background-color: var(--bgSecondary);
  border-radius: 10px;
  .userPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    background-color: var(--greyBg);
    border-radius: 50%;
    // border: 1px dashed var(--greyText);
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  svg.removeTaskTrigger {
    width: 12px;
    height: 12px;
    margin-left: 7px;
    &:hover {
      stroke: var(--redColor);
      fill: var(--redColor);
      path {
        stroke: var(--redColor);
        fill: var(--redColor);
      }
    }
  }
  input[type="file"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .abtUser {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    span {
      // width: 100%;
    }
  }
`;
/*
const UserUpload = styled.div`
  // display: flex;
  // gap: 15px;
  // align-items: center;
  // overflow: hidden;
  // position: relative;
  // cursor: pointer;
  .UserUploadBtn {
	display: flex;
	gap: 15px;
	align-items: center;
  }
  .userPlaceholder {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	background-color: var(--greyBg);
	border-radius: 50%;
	border: 1px dashed var(--greyText);
	overflow: hidden;
	img {
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	}
  }
  .selected.dropdown-item {
	background-color: var(--greyBg);
  }
`;
*/
