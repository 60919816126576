import React, { useState } from "react";
import {
  BackButton,
  Box,
  ButtonStyled,
  LabelStyled,
  SpanStyled,
  SubHeadingStyled,
} from "../../../components/styled/Elements";
import { useNavigate } from "react-router-dom";
import { AddCabinetWrapper } from "./styled";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "../../../components/styled/styled";
import ValidatedInput from "../../../components/common/inputFields/ValidatedInput";
import { useForm } from "react-hook-form";
import {
  addCabinetRadio,
  departmentsList,
  usersList,
} from "../../../helpers/dummyConstants";
import NewCustomRadio from "../../../components/common/radioButtons/NewCustomRadio";
import {
  Plus,
  SelectUser,
  SingleProfile,
} from "../../../components/styled/AllImages";
import FormRadio from "../../../components/common/radioButtons/FormRadio";
import AddUsers from "./files/addFile/components/addUsers/MainData";
import AddSingleUser from "./files/addFile/components/addSingleUser/MainData";
import Breadcrumbs from "../../../components/admin/Breadcrumbs/Breadcrumbs";

const AddCabinet = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkSelectedValueID, setcheckSelectedValueID] = useState(null);
  const checkSelectedValue = (e) => {
    setcheckSelectedValueID(Number(e.target.value));
  };
  const { register } = useForm();
  return (
    <AddCabinetWrapper>
      <Box className="content-head py-3 px-3 px-md-4">
        <Box className="d-flex align-items-center gap-5">
          <BackButton
            label={t("add_cabinet")}
            onClick={() => navigate("/cabinets")}
          />
          <Breadcrumbs />
        </Box>
      </Box>
      <ContentWrapper>
        
          
          <ValidatedInput
            name="cabinet_name"
            type="input"
            fieldType="text"
            placeholder={"cabinet_name"}
            label="cabinet_name"
            register={register}
          />
        

        <Box>
          <SubHeadingStyled
            size="18px"
            weight={700}
            color={"var(--text)"}
            className="px-4 pt-4 pb-0"
          >
            {t("cabinet_settings")}
          </SubHeadingStyled>
          {addCabinetRadio.map((x, i) => (
            <Box
              className={`w-100 p-2 ${
                i !== addCabinetRadio.length - 1 && "separation-border"
              }`}
            >
              <FormRadio
                name="cabinet_setting"
                label={t(x.name)}
                subLabel={t(x.label)}
                value={x.id}
                checked={checkSelectedValueID === x.id}
                onChange={checkSelectedValue}
                // checked={selected === 2}
                // onChange={onSelect}
              />
              {(checkSelectedValueID === 3 || checkSelectedValueID === 4) &&
                x.id === checkSelectedValueID && (
                  <Box className="addUserCont">
                    <AddUsers
                      title="add_users"
                      icon={<SingleProfile />}
                      selPlaceholder="SelectUser"
                      mainParents={usersList}
                    />
                  </Box>
                )}

              {checkSelectedValueID === 5 && x.id === checkSelectedValueID && (
                <Box className="addUserCont">
                  <AddSingleUser
                    title="Add_Department"
                    icon={<Plus />}
                    selPlaceholder="Select_One_Department"
                    mainParents={departmentsList}
                  />
                </Box>
              )}
              {checkSelectedValueID === 6 && x.id === checkSelectedValueID && (
                <Box className="addUserCont">
                  <AddUsers
                    title="Add_Department"
                    icon={<Plus />}
                    selPlaceholder="select_department"
                    mainParents={departmentsList}
                  />
                </Box>
              )}
            </Box>
          ))}
          <Box className="d-flex justify-content-end">
            <Box className="p-4 d-flex gap-3">
              <ButtonStyled
                label={t("Cancel")}
                bg={"var(--bgSecondary)"}
                color={"var(--text2)"}
              />
              <ButtonStyled label={t("Create")} />
            </Box>
          </Box>
        </Box>
      </ContentWrapper>
    </AddCabinetWrapper>
  );
};

export default AddCabinet;
