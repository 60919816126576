import React from "react";
import { Table } from "react-bootstrap";
import styled from "styled-components";
import { Box, SpanStyled } from "../../../../components/styled/Elements";
import { userPermissionColumns } from "../../../../helpers/adminConstants/TableConstants";
import { useTranslation } from "react-i18next";
import { userPermissionsRows } from "../../../../helpers/dummyConstants";

const PermissionTable = () => {

  const { t } = useTranslation();

  return (
    <StyledTable>
      <thead>
        <tr>
          {userPermissionColumns.map((col, index) => (
            <th key={index} className={`${col.title === "" && "title-column"}`}>
              <SpanStyled size="16px" color="var(--text)" weight="bold">
                {t(col.title)}
              </SpanStyled>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {userPermissionsRows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {userPermissionColumns.map((col, colIndex) => (
              <td key={colIndex}>
                <Box className="d-flex justify-content-center">
                  {col.render(row[col.name], row, t)}
                </Box>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default PermissionTable;

// Styled table to match your custom styling
export const StyledTable = styled(Table)`
  border-bottom: 1px solid var(--borderLight);
  margin-bottom: 0;
  thead tr {
    border-bottom: 1px solid var(--borderLight);
  }
  th,
  td {
    text-align: center;
    vertical-align: middle;
    padding: 2rem 16px;
    border: none;
  }

  th {
    font-size: 16px;
    font-weight: 500;
    color: var(--text);
  }

  td {
    font-size: 14px;
  }

  th.title-column {
    width: 160px;
  }
`;
