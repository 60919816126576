import React, { useState } from "react";
import {
    BackButton,
    Box,
    ButtonStyled,
    FormElementWrapper,
    LabelStyled,
    SpanStyled,
    SubHeadingStyled,
} from "../../../../components/styled/Elements";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    ArchiveWrapper,
    FormDataContainer,
} from "./styled";
import {} from "../../../../components/styled/AllImages";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FloatingInput from "../../../../components/common/inputFields/FloatingInput";
import Breadcrumbs from "../../../../components/admin/Breadcrumbs/Breadcrumbs";
import ValidatedInput from "../../../../components/common/inputFields/ValidatedInput";
import ViewPage, { ViewPageWrapper } from "../../../../components/common/pageView/pageView";
import { viewarchive } from "../../../../helpers/dummyConstants";

const ArchiveDetail = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [checkSelectedValueID, setcheckSelectedValueID] = useState(null);
    const checkSelectedValue = (e) => {
        setcheckSelectedValueID(Number(e.target.value));
    };
    const { register } = useForm();
    return (
        <ArchiveWrapper>
            <Box className="content-head py-3 px-3 px-md-4">
                {/* <SubHeadingStyled size="16px" color="var(--text)">
					{t("AddFile")}
				</SubHeadingStyled> */}
                <Box className="d-flex align-items-center gap-5">
                    <BackButton
                        label={t("view_archive")}
                        onClick={() => navigate("/cabinets/files")}
                    />
                    <Breadcrumbs />
                </Box>
            </Box>
            <Box className="content-container">
                <Row className="mt-3">
                    <Col md={12}>
                        <FormDataContainer className="dataContainer">
                            {viewarchive.map((view, i) => (
                                <ViewPage
                                    key={view.id}
                                    label={view.label}
                                    text= {view.text}
                                    render={view.render ? view.render : null}
                                />
                            ))}
                        </FormDataContainer>
                    </Col>
                </Row>
            </Box>
        </ArchiveWrapper>
    );
};

export default ArchiveDetail;
