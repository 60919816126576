import React, { useState } from "react";
// import {
//     BackButton,
//     Box,
//     ButtonStyled,
//     FormElementWrapper,
//     LabelStyled,
//     SpanStyled,
//     SubHeadingStyled,
// } from "../../../../components/styled/Elements";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Box, BackButton, ButtonStyled, FormElementWrapper, LabelStyled, SpanStyled, SubHeadingStyled, DataContainerHeader, } from "../../../components/styled/Elements";
import { SettingsWrapper, FormDataContainer,} from "./styled";
// import {} from "../../../../components/styled/AllImages";
// import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/admin/Breadcrumbs/Breadcrumbs";
import { adminDirectorContact, adminDirectorInit, adminDirectorUploads, adminsListRows, branchesRows, establishmentContact, establishmentInit, establishmentLocation, establishmentUploads, headquartersRows, historyDetailRows, tempADaccessRows, viewarchive } from "../../../helpers/dummyConstants";
import ViewPage from "../../../components/common/pageView/pageView";
import { fileTileArray, overviewTileArray } from "../../../helpers/adminConstants/AdminConstants";
import CustomTile from "../../../components/common/statusTiles/CustomTile";
import { Edit } from "../../../components/styled/AllImages";
import ListTable from "../../../components/common/tables/ListTable";
import { adminsListColumns, branchesColumns, headquartersColumns, historyDetailColumns, tempADaccessColumns } from "../../../helpers/adminConstants/TableConstants";
// import FloatingInput from "../../../../components/common/inputFields/FloatingInput";
// import Breadcrumbs from "../../../../components/admin/Breadcrumbs/Breadcrumbs";
// import ValidatedInput from "../../../../components/common/inputFields/ValidatedInput";
// import ViewPage, { ViewPageWrapper } from "../../../../components/common/pageView/pageView";
// import { viewarchive } from "../../../../helpers/dummyConstants";

const AdminsList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // const [checkSelectedValueID, setcheckSelectedValueID] = useState(null);
    // const checkSelectedValue = (e) => {
    //     setcheckSelectedValueID(Number(e.target.value));
    // };
    // const { register } = useForm();
    const handleButtonClick = () => {
        // setcheckSelectedValueID(Number(e.target.value));
    };
    const handleView = (user) => {
        navigate(`/settings/admins-list/view-admin/${user.id}`)
    }
    return (
        <SettingsWrapper>
            <Box className="content-head py-3 px-3 px-md-4">
                <SubHeadingStyled size="16px" color="var(--text)">
					{t("settings")}
				</SubHeadingStyled>
                
            </Box>
            <Box className="content-container">
                <Row className="mt-3">
                    <Col md={12}>
                        
                        <FormDataContainer className="dataContainer">
                            <DataContainerHeader 
                                label={t("admins_list")}
                             />
                        </FormDataContainer>
                    </Col>
                </Row>
            </Box>
            <Box>
                <ListTable 
                    options={["view"]}
                    rows={adminsListRows} 
                    columns={adminsListColumns} 
                    onViewDetails={handleView}
                />
            </Box>
        </SettingsWrapper>

    );
};

export default AdminsList;
