import React from "react";
import { useTranslation } from "react-i18next";
import { HeadingStyled, SpanStyled } from "../../styled/Elements";

export const NoRecord = (props) => {
  const { t } = useTranslation();
  const { margin } = props;

  return (
    <div
      className={`h-100 d-flex align-items-center justify-content-center ${
        margin ? `my-${margin}` : "my-5"
      }`}
    >
      <SpanStyled size="26px" weight="900" color="var(--text2)">{t("no_data_found")}</SpanStyled>
    </div>
  );
};
