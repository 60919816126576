import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { SingleProfile } from "../../../../../../../components/styled/AllImages";
import { Box, SpanStyled } from "../../../../../../../components/styled/Elements";
import { CustomCheckbox, SelectList, SelectListWithIcon } from "../../../../../../../components/styled/FormElements";
import UserDataModal from "./DataModal";

const DataWrapper = ({ setShowUserDataModal, icon, selPlaceholder }) => {
	return (
		<UserUpload>

			<Box className="addUserBtn" onClick={() => setShowUserDataModal(true)}>
				<Box className="userPlaceholder">
        			{icon}
				</Box>
				<SpanStyled className="pt-1" color="var(--text2)" size="14px" >
        			{selPlaceholder}
				</SpanStyled>

			</Box>
			

		</UserUpload>
	);
};

export default DataWrapper;

const UserUpload = styled.div`
  // display: flex;
  // gap: 15px;
  // align-items: center;
  // overflow: hidden;
  // position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* padding-left: 32px; */
  .addUserBtn {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 7px 16px;
    border-radius: 10px;
    background-color: var(--bgSecondary);
  }
  .userPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    /* background-color: var(--greyBg); */
    border-radius: 50%;
    border: 1px dashed var(--text2);
    overflow: hidden;
    svg {
      width: 14px;
      height: auto;
      [stroke] {
        stroke: var(--text2);
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .selected.dropdown-item {
    background-color: var(--greyBg);
  }
`;

const DropListRow = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 7px;
  justify-content: space-between;
  .dropRadioContainer {
    display: flex;
    & > div {
      display: flex;
      align-items: center;
    }
  }
  label {
    width: 24px;
    height: 24px;
  }
  .selUserImg {
    display: flex;
    min-width: 25px;
	  max-width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
