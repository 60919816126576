import React from "react";
import {
  Box,
  FormButtonStyled,
  HeadingStyled,
  LazyImage,
  TextStyled,
} from "../../../components/styled/Elements";
import { ResetPassWrapper } from "./styled";
import AuthHeader from "../../../components/auth/AuthHeader/AuthHeader";
import { Col, Row } from "react-bootstrap";
import ValidatedInput from "../../../components/common/inputFields/ValidatedInput";
import { useForm } from "react-hook-form";
import { PasswordResetImg } from "../../../components/styled/AllImages";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const { t } = useTranslation();
  const { register } = useForm();
  return (
    <ResetPassWrapper>
      <AuthHeader />
      <Box className="mt-4 container-fluid pb-4">
        <Row>
          <Col lg={6} className="px-3 px-xl-5 px-md-5 pb-4">
            <Box className="mb-4">
              <HeadingStyled size="24px" weight="bold" color="var(--text)">
                {t("reset_password")}
              </HeadingStyled>
              <TextStyled size="16px" color="var(--text2)">
                {t("what_would_you_like_your_password")}
              </TextStyled>
            </Box>
            <Box>
              <Box>
                <ValidatedInput
                  name="new_password"
                  label={"new_password"}
                  type="input"
                  fieldType="password"
                  placeholder={"newPasswordPlaceholder"}
                  register={register}
                  className={"mb-3 combined flex-wrap p-0"}
                  bg="var(--white)"
                />
                <ValidatedInput
                  name="confirm_new_password"
                  label={"confirm_new_password"}
                  type="input"
                  fieldType="password"
                  placeholder={"confirmNewPasswordPlaceholder"}
                  register={register}
                  className={"mb-3 combined flex-wrap p-0"}
                  bg="var(--white)"
                />
                <FormButtonStyled
                  label={t("change")}
                  color="var(--white)"
                  bg="var(--themeColor)"
                  className="w-100"
                  clName="w-100 my-4"
                />
              </Box>
            </Box>
          </Col>
          <Col lg={6} className="px-3 px-xl-5">
            <Box className="d-flex justify-content-center">
              <LazyImage src={PasswordResetImg} />
            </Box>
          </Col>
        </Row>
      </Box>
    </ResetPassWrapper>
  );
};

export default ResetPassword;
