import React, { useState } from "react";
import {
  BackButton,
  Box,
  ButtonStyled,
} from "../../../components/styled/Elements";
import Breadcrumbs from "../../../components/admin/Breadcrumbs/Breadcrumbs";
import { FileIcon3, PlusIcon } from "../../../components/styled/AllImages";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ListTable from "../../../components/common/tables/ListTable";
import { departmentsRows, historyData } from "../../../helpers/dummyConstants";
import { departmentColumns } from "../../../helpers/adminConstants/TableConstants";
import DeleteModal from "../../../components/admin/Modals/DeleteModal/DeleteModal";
import HistoryModal from "../../../components/admin/Modals/HistoryModal/HistoryModal";

const Departments = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  const [showDelete, setShowDelete] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const onDelete = () => {
    setShowDelete(true);
  };

  const onHistory = () => {
    setShowHistory(true)
  }

  const handleView = (row) => {
    navigate(`/hq-branches/departments/view-department/${row.id}`)
  }

  const handleEdit = (row) => {
    navigate(`/hq-branches/departments/edit-department`)
  }

  return (
    <Box>
      <Box className="content-head py-3 px-3 px-md-4">
        <Box className="d-flex align-items-center gap-5">
          <BackButton
            label={t("north_region_branch")}
            onClick={() => navigate("/hq-branches")}
          />
          <Breadcrumbs />
        </Box>
        <Box className="d-flex align-items-center gap-2">
          <ButtonStyled
            label={t("print_as_pdf")}
            icon={FileIcon3}
            bg="var(--themeColor2)"
          />
          <ButtonStyled
            label={t("add_department")}
            icon={PlusIcon}
            onClick={() => navigate(`/hq-branches/departments/add-department`)}
          />
        </Box>
      </Box>
      <Box>
      <ListTable
          options={["view", "info", "edit", "status", "delete"]}
          rows={departmentsRows}
          columns={departmentColumns}
          onDelete={onDelete}
          onInfoView={onHistory}
          onViewDetails={handleView}
          onEdit={handleEdit}
        />
      </Box>
      
      <HistoryModal
        title={t("departments_history")}
        show={showHistory}
        onHide={() => setShowHistory(false)}
        createdBy={historyData.createdBy}
        editHistory={historyData.editHistory}
      />
      <DeleteModal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        title={t("department")}
      />
    </Box>
  );
};

export default Departments;
