import React, { useState } from "react";
import { Box, LabelStyled, SpanStyled } from "../../styled/Elements";
import { Form, FormGroup } from "react-bootstrap";
import styled from "styled-components";
import { Eye, Eyelash } from "../../styled/AllImages";
import { useTranslation } from "react-i18next";

const ViewPage = ({
    name,
    label,
    value,
    className,
    text,
    render, // Receive the render function if passed
    ...rest
}) => {
    const { t } = useTranslation();
    

    return (
        <ViewPageWrapper className={className} {...rest}>
            <Box className="formEleContainer">
                {label && (
                    <LabelStyled
                        size="15px"
                        color="var(--text)"
                    >
                        {t(label)}
                    </LabelStyled>
                )}
                <Box className="position-relative w-100">
                    {/* Check if render function exists */}
                    {render ? (
                        render(text)  // Call the render function if it exists
                    ) : (
                        // Render text if no render function is available
                        text && (
                            <SpanStyled color="var(--text2)">
                                {t(text)}
                            </SpanStyled>
                        )
                    )}
                </Box>
            </Box>
        </ViewPageWrapper>
    );
};

export default ViewPage;

export const ViewPageWrapper = styled.div`
  width: 100%;
  padding: 9px 25px 9px 25px;
    border-bottom: 1px solid var(--borderLight);
    width: 100%;
    .formEleContainer {
        display: flex;
        padding: 7px 0px 7px 0px;
        margin: 7px 0 7px 0;
        align-items: center;
        gap: 10px;
        & > label {
        min-width: 160px;
        }
        &.inp-white {
            input {
                background-color: var(--white);
            }
        }
    }
    
    .form-floating {
        width: 100%;
    }
    input[type="text"] {
        width: 100%;
    }

  .form-control {
    height: 50px;
    box-shadow: none;
    outline: none;
    border-radius: 10px;
    border: 1px solid var(--border);
    background-color: ${({ bg }) => (bg ? bg : "var(--bgSecondary)")};
    &::placeholder {
      color: var(--textPlaceholder2);
    }
  }
  .form-control:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid var(--border);
  }
  @media (max-width: 767px){
    .formEleContainer {
        flex-wrap: wrap;
        gap: 3px;
        margin: 0;
        padding: 3px 0px 3px 0px;
        label{
            width: 100%;
        }
    }
    }
`
