import { ActiveUser, Admins, AdminUser, Branches, Cabinets, CanceledRequests, Dashboard, FileIcon, History, HQBranches, LogoutIcon, MultiUsers, Password, Phone, Requests, Settings, SingleFile, UnactiveUser, UserIcon, Users } from "../../components/styled/AllImages";
import ReceivedRequests from "../../pages/admin/requests/ReceivedRequests";
import SentRequests from "../../pages/admin/requests/SentRequests";
import UserPermissions from "../../pages/admin/users/components/UserPermissions";
import UserPersonalInfo from "../../pages/admin/users/components/UserPersonalInfo";

export const SidebarList = [
	{
		title: "dashboard",
		icon: Dashboard,
		url: "dashboard",
	},
	{
		title: "cabinets",
		icon: Cabinets,
		url: "cabinets",
	},
	{
		title: "requests",
		icon: Requests,
		url: "requests",
	},
	{
		title: "history",
		icon: History,
		url: "history",
	},
	{
		title: "users",
		icon: Users,
		url: "users",
	},
	{
		title: "hq_branches",
		icon: HQBranches,
		url: "hq-branches",
	},
	{
		title: "settings",
		icon: Settings,
		// url: "settings",
        submenu: [
            {
              title: "overview",
              url: "settings/overview",
            },
            {
              title: "establishment",
              url: "settings/establishment",
            },
            {
              title: "admin_director",
              url: "settings/admin-director",
            },
            {
              title: "admins_list",
              url: "settings/admins-list",
            },
            {
                title: "billing",
                url: "settings/billing",
            },
        ],
	},
	{
		title: "support",
		icon: Phone,
		url: "support",
	},
];

export const supportDropdownList = [
	{
		label: "message_ca_manager",
		path: ""
	},
	{
		label: "message_ca_support",
		path: ""
	}
]
export const profileDropdownList = [
	{
		label: "Profile",
		icon: UserIcon,
		path: "profile"
	},
	{
		label: "Logout",
		icon: LogoutIcon,
		path: ""
	}
]

export const dashboardTileArray = [
	{
		icon: Cabinets,
		count: "0",
		color: "var(--white)",
		bg: "var(--themeColor)",
		title: "total_cabinets",
	},
	{
		icon: SingleFile,
		count: "0",
		color: "var(--white)",
		bg: "var(--redColor2)",
		title: "total_files",
	},
	{
		icon: FileIcon,
		count: "0",
		color: "var(--white)",
		bg: "var(--lightBlue)",
		title: "total_archives",
	},
	{
		icon: MultiUsers,
		count: "0",
		color: "var(--white)",
		bg: "var(--yellow2)",
		title: "total_users",
	},
	{
		icon: Requests,
		count: "0",
		color: "var(--white)",
		bg: "var(--yellow)",
		title: "total_requests",
	},
	{
		icon: Requests,
		count: "0",
		color: "var(--white)",
		bg: "var(--purple)",
		title: "total_open_requests",
	},
	{
		icon: CanceledRequests,
		count: "0",
		color: "var(--white)",
		bg: "var(--redColor)",
		title: "total_canceled_requests",
	},
	{
		icon: HQBranches,
		count: "0",
		color: "var(--white)",
		bg: "var(--themeColor2)",
		title: "total_hq_branches",
	},
];
export const ProfileSidebarList = [
	{
		title: "AccountSetting",
		icon: Password,
		url: "",
	},
	{
		title: "ChangePassword",
		icon: UserIcon,
		url: "",
	},
	{
		title: "Logout",
		icon: LogoutIcon,
		url: "#!",
	},
	
];
export const fileTileArray = [
	{
		icon: SingleFile,
		count: "100",
		color: "var(--white)",
		bg: "var(--redColor2)",
		title: "ActiveFiles",
	},
	{
		icon: SingleFile,
		count: "35",
		color: "var(--white)",
		bg: "var(--themeColor2)",
		title: "InactiveFile",
	},
	{
		icon: SingleFile,
		count: "139",
		color: "var(--white)",
		bg: "var(--redColor)",
		title: "TotalFiles",
	},
	
];

export const usersTileArray = [
	{
		icon: MultiUsers,
		count: "0",
		color: "var(--white)",
		bg: "var(--yellow2)",
		title: "total_users",
	},
	{
		icon: AdminUser,
		count: "0",
		color: "var(--white)",
		bg: "var(--purple)",
		title: "total_admins",
	},
	{
		icon: ActiveUser,
		count: "0",
		color: "var(--white)",
		bg: "var(--themeColor2)",
		title: "activated_users",
	},
	{
		icon: UnactiveUser,
		count: "0",
		color: "var(--white)",
		bg: "var(--redColor)",
		title: "deactivated_users",
	},
];
export const hqBranchTileArray = [
	{
		icon: HQBranches,
		count: "0",
		color: "var(--white)",
		bg: "var(--yellow2)",
		title: "total_branches",
	},
	{
		icon: HQBranches,
		count: "0",
		color: "var(--white)",
		bg: "var(--purple)",
		title: "total_departments",
	},
];

export const requestsTabs = [
	{
		title: "received_requests",
		component: ReceivedRequests
	},
	{
		title: "sent_requests",
		component: SentRequests
	},
]
export const userFormTabs = [
	{
		title: "personal_information",
		component: UserPersonalInfo
	},
	{
		title: "rights_permissions",
		component: UserPermissions
	},
]

export const overviewTileArray = [
	{
		icon: Branches,
		count: "10",
		color: "var(--white)",
		bg: "var(--yellow2)",
		title: "TotalBranches",
	},
	{
		icon: Branches,
		count: "10",
		color: "var(--white)",
		bg: "var(--purple)",
		title: "TotalHeadquarters",
	},
	{
		icon: Admins,
		count: "45",
		color: "var(--white)",
		bg: "var(--themeColor2)",
		title: "TotalAdmins",
	},
	
];





