import React, { useState } from "react";
import {
  BackButton,
  Box,
  ButtonStyled,
  FormElementWrapper,
  FormEleWrapper,
  HeadingStyled,
  LabelStyled,
  SpanStyled,
  SubHeadingStyled,
  TextStyled,
} from "../../../../../components/styled/Elements";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  DashboardWrapper,
  DataContainer,
  DataContainerForm,
  FormDataContainer,
  ProfileSidebarWrapper,
  ProfileWrapper,
} from "./styled";
import {
  Camera,
  LogoutIcon,
  Plus,
  ProfileImgLarge,
  SingleProfile,
} from "../../../../../components/styled/AllImages";
import { useNavigate } from "react-router-dom";
import FloatingInput from "../../../../../components/common/inputFields/FloatingInput";
import { RadioButton } from "../../../../../components/styled/FormElements";
import AddUsers from "./components/addUsers/MainData";
import {
  addFileRadio,
  departmentsList,
  usersList,
} from "../../../../../helpers/dummyConstants";
import FormRadio from "../../../../../components/common/radioButtons/FormRadio";
import AddSingleUser from "./components/addSingleUser/MainData";
import Breadcrumbs from "../../../../../components/admin/Breadcrumbs/Breadcrumbs";
import ValidatedInput from "../../../../../components/common/inputFields/ValidatedInput";

const AddFile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkSelectedValueID, setcheckSelectedValueID] = useState(null);
  const checkSelectedValue = (e) => {
    setcheckSelectedValueID(Number(e.target.value));
  };
  return (
    <ProfileWrapper>
      <Box className="content-head py-3 px-3 px-md-4">
        {/* <SubHeadingStyled size="16px" color="var(--text)">
					{t("AddFile")}
				</SubHeadingStyled> */}
        <Box className="d-flex align-items-center gap-5">
          <BackButton
            label={t("add_file")}
            onClick={() => navigate("/cabinets/files")}
          />
          <Breadcrumbs />
        </Box>
      </Box>
      <Box className="content-container">
        <Row className="mt-3">
          <Col md={12}>
            <FormDataContainer className="dataContainer">
                {/* <ValidatedInput
                    name="fName"
                    type="input"
                    fieldType="text"
                    placeholder={"FileName"}
                    label="FileName"
                    className="combined"
                /> */}


              <FormElementWrapper>
                <Box className="formEleContainer">
                  <LabelStyled className="labelForm">
                    {t("FileName")}
                  </LabelStyled>
                  <FloatingInput
                    name="fName"
                    type="input"
                    fieldType="text"
                    placeholder={"FileName"}
                  />
                </Box>
                <Box className="formEleContainer">
                  <LabelStyled className="labelForm">
                    {t("Description")}
                    <span className="optional">{t("Optional")}</span>
                  </LabelStyled>
                  <FloatingInput
                    name="description"
                    type="input"
                    fieldType="text"
                    placeholder={"Description"}
                  />
                </Box>
              </FormElementWrapper>
              <Box className="innerFoHead">
                <SubHeadingStyled size="16px" color="var(--text)">
                  {t("FileSettings")}
                </SubHeadingStyled>
              </Box>
              {addFileRadio.map((x, i) => (
                <Box
                  className={`w-100 p-2 ${
                    i !== addFileRadio.length - 1 && "separation-border"
                  }`}
                >
                  <FormRadio
                    name="cabinet_setting"
                    label={t(x.name)}
                    subLabel={t(x.label)}
                    value={x.id}
                    checked={checkSelectedValueID === x.id}
                    onChange={checkSelectedValue}
                    // checked={selected === 2}
                    // onChange={onSelect}
                  />
                  {(checkSelectedValueID === 3 || checkSelectedValueID === 4) &&
                    x.id === checkSelectedValueID && (
                      <Box className="addUserCont">
                        <AddUsers
                          title="add_users"
                          icon={<SingleProfile />}
                          selPlaceholder="SelectUser"
                          mainParents={usersList}
                        />
                      </Box>
                    )}

                  {checkSelectedValueID === 5 &&
                    x.id === checkSelectedValueID && (
                      <Box className="addUserCont">
                        <AddSingleUser
                          title="Add_Department"
                          icon={<Plus />}
                          selPlaceholder="Select_One_Department"
                          mainParents={departmentsList}
                        />
                      </Box>
                    )}
                  {checkSelectedValueID === 6 &&
                    x.id === checkSelectedValueID && (
                      <Box className="addUserCont">
                        <AddUsers
                          title="Add_Department"
                          icon={<Plus />}
                          selPlaceholder="select_department"
                          mainParents={departmentsList}
                        />
                      </Box>
                    )}
                </Box>
              ))}

              <Box className="commonBtns w-100">
                <ButtonStyled
                  bg="var(--bgSecondary)"
                  label={t("Cancel")}
                  color="var(--text2)"
                ></ButtonStyled>
                <ButtonStyled label={t("Create")}></ButtonStyled>
              </Box>
            </FormDataContainer>
          </Col>
        </Row>
      </Box>
    </ProfileWrapper>
  );
};

export default AddFile;
