import React, { useState } from "react";
import { Box } from "../../../components/styled/Elements";
import ListTable, {
  TableWrapper,
} from "../../../components/common/tables/ListTable";
import { receivedReqColumns } from "../../../helpers/adminConstants/TableConstants";
import { receivedReqsRows } from "../../../helpers/dummyConstants";
import ReasonModal from "../../../components/admin/Modals/ReasonModal/ReasonModal";
import { useTranslation } from "react-i18next";

const ReceivedRequests = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [reason, setReason] = useState("");
  const handleReasonChange = (e) => {
    const { value } = e.target;
    setReason(value);
  };
  return (
    <Box>
      <TableWrapper>
        <Box className="table-responsive custom-scroll overflow-x-auto">
          <table className="w-100 ">
            <thead>
              <tr>
                {receivedReqColumns.map((col, key) => (
                  <th key={key} className="text-nowrap">
                    {t(col.label)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {receivedReqsRows?.map((row, index) => (
                <tr key={index}>
                  {receivedReqColumns?.map((col, key) => (
                    <td key={key}>
                      {col.render
                        ? col.render(row[col.name], row, t, setShowModal)
                        : row[col.name]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        {/* {totalCount && (
        <PaginationContainer>
          <Pagination
            itemClass="page-item"
            linkClass="page-link"
            activePage={activePage}
            itemsCountPerPage={perPage}
            totalItemsCount={totalCount}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            getPageUrl={(pageNumber) => pageHrefs[pageNumber - 1]}
          />
        </PaginationContainer>
      )} */}
      </TableWrapper>
      <ReasonModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onChange={handleReasonChange}
      />
    </Box>
  );
};

export default ReceivedRequests;
