import React from "react";
import { Box, ButtonStyled, FormEleWrapper, HeadingStyled, LabelStyled, SubHeadingStyled, TextStyled } from "../../../components/styled/Elements";
import { useTranslation } from "react-i18next";
import FloatingInput from "../../../components/common/inputFields/FloatingInput";
import { Camera, ProfileImgLarge } from "../../../components/styled/AllImages";

const AccountSetting = () => {
	const { t } = useTranslation();
	return (
		<>
			<Box className="profileAvatarContainer">
				<Box className="profileAvatarImg">
					<Box className="profileAvatarImgContainer">
						<img className="profileAvatar" src={ProfileImgLarge} />
						<Box className="profileCamera">
							<Camera />
						</Box>
					</Box>
					<Box className="profileUserNameWrapper">
						<HeadingStyled color="var(--text)" size="24px">
							Kevin Miller
						</HeadingStyled>
						<TextStyled lh="1">
							{t('ProjectManager')}
						</TextStyled>
					</Box>
				</Box>
			</Box>
			<Box className="profileContentData pt-2">
				<FormEleWrapper className="pt-0">
					<Box className="formEleContainer">
						<LabelStyled className="labelForm">{t('FirstName')}<span className="staric">*</span></LabelStyled>
						<FloatingInput
							name="fName"
							type="input"
							fieldType="text"
							placeholder={"FirstName"}
						/>
					</Box>
					<Box className="formEleContainer">
						<LabelStyled className="labelForm">{t('LastName')}<span className="staric">*</span></LabelStyled>
						<FloatingInput
							name="lName"
							type="input"
							fieldType="text"
							placeholder={"LastName"}
						/>
					</Box>
					<Box className="formEleContainer">
						<LabelStyled className="labelForm">{t('Email')}<span className="staric">*</span></LabelStyled>
						<FloatingInput
							name="email"
							type="input"
							fieldType="text"
							placeholder={"Email"}
						/>
					</Box>
					<Box className="formEleContainer">
						<LabelStyled className="labelForm">{t('Phone')}<span className="staric">*</span></LabelStyled>
						<FloatingInput
							name="phone"
							type="input"
							fieldType="text"
							placeholder={"Phone"}
						/>
					</Box>
					{/* <Box className="formEleContainer">
						<LabelStyled className="labelForm">{t('LastName')}<span className="staric">*</span></LabelStyled>
						<InputWithIcon type="text" placeholder={t('LastName')} />
					</Box> */}
					
				</FormEleWrapper>
				<Box className="commonBtns px-0">
					<ButtonStyled bg="var(--bgSecondary)" label={t("Cancel")} color="var(--text2)"></ButtonStyled>
					<ButtonStyled label={t("Update")}></ButtonStyled>
				</Box>
			</Box>
		</>
	);
};

export default AccountSetting;
