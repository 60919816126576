import React, { useState } from "react";
import {
  Box,
  FileUploaderStyled,
  FormButtonStyled,
  HeadingStyled,
  LabelStyled,
  SpanStyled,
  TextStyled,
} from "../../../../components/styled/Elements";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import ValidatedInput from "../../../../components/common/inputFields/ValidatedInput";
import { Col, Form, Row } from "react-bootstrap";
import InformationList from "../../../../components/common/informationList/InformationList";
import InformationListItem from "../../../../components/common/informationList/InformationListItem";
import {
  Cancel,
  FileIcon,
  FileUpload,
} from "../../../../components/styled/AllImages";
import ValidationController from "../../../../components/common/inputFields/ValidationController";

const SignupStep3 = ({ handleSteps, paymentMethod }) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    register,
    control,
    getFieldState,
  } = useForm();

  const [previewImg, setPreviewImg] = useState(null);

  return (
    <Step3Wrapper>
      <Form>
        <Box>
          {paymentMethod === 1 ? (
            <>
              <Box className="mb-4">
                <HeadingStyled size="24px" weight="bold" color="var(--text)">
                  {t("pay_through_card")}
                </HeadingStyled>
                <TextStyled size="16px" color="var(--text2)">
                  {t("please_enter_card_info_bellow")}
                </TextStyled>
              </Box>
              <Box>
                <ValidatedInput
                  name={"card_holder_name"}
                  label={"card_holder_name"}
                  placeholder={"chNamePlaceholder"}
                  type="input"
                  fieldType="text"
                  className={"mb-3"}
                  bg="var(--white)"
                  register={register}
                />
                <ValidatedInput
                  name={"card_number"}
                  label={"card_number"}
                  placeholder={"card_number"}
                  type="input"
                  fieldType="text"
                  className={"mb-3"}
                  bg="var(--white)"
                  register={register}
                />
                <Row>
                  <Col lg={8}>
                    <ValidatedInput
                      name={"expiry_date"}
                      label={"expiry_date"}
                      placeholder={"expiry_date"}
                      type="input"
                      fieldType="date"
                      className={"mb-3"}
                      bg="var(--white)"
                      register={register}
                    />
                  </Col>
                  <Col lg={4}>
                    <ValidatedInput
                      name={"cvv"}
                      label={"cvv"}
                      placeholder={"cvv"}
                      type="input"
                      fieldType="number"
                      className={"mb-3"}
                      bg="var(--white)"
                      register={register}
                    />
                  </Col>
                </Row>
              </Box>
            </>
          ) : (
            <>
              <Box className="mb-4">
                <HeadingStyled size="24px" weight="bold" color="var(--text)">
                  {t("bank_account_information")}
                </HeadingStyled>
                <TextStyled size="16px" color="var(--text2)">
                  {t("please_see_account_info_bellow")}
                </TextStyled>
              </Box>
              <InformationList>
                <InformationListItem
                  label={t("total_amount")}
                  value={`600 ${t("sar")}`}
                />
                <InformationListItem
                  label={t("bank_name")}
                  value={t("al_rajhi_bank")}
                />
                <InformationListItem label={t("iban")} value={1010000096} />
                <InformationListItem
                  label={t("beneficiary_name")}
                  value={t("m_abdullah")}
                />
                <Box className="d-flex justify-content-center py-3">
                  <SpanStyled size="16px" color="var(--themeColor)">
                    <FileIcon />
                    &nbsp;
                    {t("see_invoice")}
                  </SpanStyled>
                </Box>
              </InformationList>
              <Box className="mt-3">
                <LabelStyled size="16px" color="var(--text)" weight="700">
                  {t("upload_receipt")}
                </LabelStyled>
                <ValidationController
                  name={"reciept"}
                  validationConfig={{
                    required: true ? `${t("fieldRequired")}` : "",
                  }}
                  errors={errors}
                  errorDisplay="mb-0"
                  control={control}
                  renderer={({ field }) => (
                    <>
                      {previewImg && (
                        <HeadingStyled
                          className="mb-2 mt-2 pb-1 cancelBtnEmbed"
                          size="14px"
                          weight={500}
                          color="var(--text2)"
                        >
                          <Cancel
                            size={26}
                            color="#fff"
                            className="cursor-pointer"
                          />
                        </HeadingStyled>
                      )}
                      <FileUploaderStyled className="d-flex align-items-center justify-content-center w-100 uploaded-attachments">
                        <Form
                          as="input"
                          name="catalog"
                          type="file"
                          validationConfig={{
                            required: true ? `${t("fieldRequired")}` : "",
                          }}
                          fieldState={getFieldState("reciept")}
                          accept="application/pdf"
                          className="d-none"
                        />
                        <Box className="d-flex align-items-center justify-content-center mt-n4 w-100">
                          {previewImg ? (
                            <embed
                              src={previewImg}
                              className="attachment"
                              type="application/pdf"
                            />
                          ) : (
                            <>
                              <FileUpload size={26} />
                              &nbsp;&nbsp;
                              <SpanStyled color="var(--text)" size="14px">
                                {t("upload_receipt")}
                              </SpanStyled>
                            </>
                          )}
                        </Box>
                      </FileUploaderStyled>
                    </>
                  )}
                />
              </Box>
            </>
          )}
        </Box>
        <FormButtonStyled
          label={t("sign_up")}
          color="var(--white)"
          bg="var(--themeColor)"
          className="w-100"
          clName="w-100 my-4"
          onClick={handleSteps}
        />
      </Form>
    </Step3Wrapper>
  );
};

export default SignupStep3;

const Step3Wrapper = styled.div``;
