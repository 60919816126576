import React, { useState, useRef, useEffect } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { InfoTooltip } from "../../styled/AllImages";
import { useTranslation } from "react-i18next";
import { Box, HeadingStyled, SubHeadingStyled, TextStyled, UserInfo } from "../../styled/Elements";

const ProfileTooltip = ({ data, userName, userRole, userImage }) => {
  const [offset, setOffset] = useState([]);
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const handleClick = (event) => {
    setShow(!show);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (language === "en") setOffset([-65, 0]);
    else setOffset([65, 0]);
  }, [language]);

  return (
    <TooltipWrapper>
      <span className="default-icon" ref={ref} onClick={handleClick}>
        <InfoTooltip />
      </span>

      <Overlay
        target={ref.current}
        show={show}
        popperConfig={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: offset,
              },
            },
          ],
        }}
        placement="bottom"
        container={ref.current}
      >
        {(props) => (
          <Tooltip {...props}>
            <TooltipContentStyled className="p-0">
                {data && data.length > 0 &&
                    <>
                        <Box className="userDetailInfo mt-2 px-3 py-1">
                            <UserInfo name={userName} role={t(userRole)} avatar={userImage} />
                        </Box>
                        <table>
                            {data.map((info, key) => (
                                
                                    <tr>
                                        <td><TextStyled size="14px" color="var(--text2)">{t(info.title)}</TextStyled></td>
                                        <td><SubHeadingStyled size="14px" color="var(--text)">{t(info.value)}</SubHeadingStyled></td>
                                    </tr>
                                
                            // <Box className="mt-2 px-3 py-1 single-info-box">
                            //     <TextStyled size="14px" weight={400} color="var(--text2)">{t(info.title)}</TextStyled>
                            //     <SubHeadingStyled size="14px" color="var(--text)">{t(info.value)}</SubHeadingStyled>
                            // </Box>
                            ))}
                        </table>
                    </>
                }
            </TooltipContentStyled>
          </Tooltip>
        )}
      </Overlay>
    </TooltipWrapper>
  );
};

export default ProfileTooltip;

export const TooltipWrapper = styled.div`
.tooltip-inner {
    min-width: 380px;
    text-align: start;
    padding: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--borderLight);
    background-color: var(--white);
    color: var(--text);
    border-radius: 12px;
  }
  .tooltip-arrow::before {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-bottom-color: var(--white);
  }
`;

const TooltipContentStyled = styled.div`
  z-index: 1000;

  > .single-info-box {
        border-bottom: 1px solid var(--borderLight);
    }
    > .single-info-box:last-child {
        border: none;
    }
  .tooltip-row {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-weight: bold;
    }
  }
  .userDetailInfo{
    .profile-avatar{
        width: 60px;
        span,
        img{
            width: 100%;
            height: auto;
        }
    }
  }
  table{
    border-collapse: collapse;
    border-spacing: 0 0px;
    tr{
        &:last-child{
            td{
                border-bottom: 0;
            }
        }
    }
    td{
        border-radius: 0 !important;
    }
  }
`;
