import styled from "styled-components";



export const ProfileWrapper = styled.div`
    .content-head {
        background-color: var(--white);
        border-bottom: 1px solid var(--borderLight);
    }
    .logout-tab {
      svg [stroke]{
        stroke: var(--redColor);
      }
    }
`
export const DataContainer = styled.div`
    display: flex;
    .profileContent{
        display: block;
        width: 100%;
        flex-wrap: wrap;
        border-left: 1px solid var(--borderLight); // For LTR, apply left border
        .profileContentData{
            display: block;
            width: 100%;
            padding: 24px 30px 24px 30px;
        }
        body.rtl & {
            border-right: 1px solid var(--borderLight); /* For RTL */
            border-left: none;  /* Disable left border for RTL */
        }
        
        .profileAvatarContainer{
            display: flex;
            width: 100%;
            height: fit-content;
            padding: 24px 30px 24px 30px;
            border-bottom: 1px solid var(--borderLight);
        }
    }
    .profileAvatarImg{
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        .profileAvatarImgContainer{
            width: fit-content;
            position: relative;
        }
        .profileAvatar{
            display: block;
            width: 110px;
            height: 110px;
            border-radius: 50%;
        }
        .profileCamera{
            position: absolute;
            background-color: var(--themeColor2);
            border: 2px solid var(--white);
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 4px;
            right: -12px;
            svg{
                margin-top: -2px;
            }
            body.rtl & {
                right: unset;
                left: -12px;
            }
        }
        .profileUserNameWrapper{
            padding-left: 25px;
            body.rtl & {
                padding-left: 0;
                padding-right: 25px;
            }
        }
    }
	@media (max-width: 767px){
		.profileContent{
			border-left: none;
			.profileContentData{
				border-top: 1px solid var(--borderLight);
			}
			
		}
	}
`

export const ProfileSidebarWrapper = styled.div`
  width: 270px;
  height: 100%;
  z-index: 100;
  transition: 0.3s all;
  li{
    &:hover a,
    &.active a {
      background-color: var(--bgSecondary);
      &::before {
        opacity: 1;
      }
      svg {
        [stroke] {
          stroke: var(--themeColor);
        }
        [fill] {
          fill: var(--themeColor);
        }
      }
      span {
        color: var(--themeColor);
        font-weight: 600;
      }
    }
    &.logout-tab:hover a,
    &.logout-tab.active a {
      background-color: var(--bgSecondary);
      &::before {
        opacity: 1;
      }
      svg {
        [stroke] {
          stroke: var(--redColor);
        }
        [fill] {
          fill: var(--redColor);
        }
      }
      span {
        color: var(--redColor);
        font-weight: 600;
      }
    }
}
  a {
    position: relative;
    padding: 10px 10px;
    transition: all ease-in-out 0.3s;
    font-size: 15px;
    color: var(--text);
    border-color: var(--themeColor2);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 0.25rem;
      background-color: var(--themeColor2);
      transition: all 0.3s ease;
      opacity: 0;
    }
    svg {
      width: 24px;
    }
    /* &:hover,
    &.active {
      background-color: var(--bgSecondary);
      &::before {
        opacity: 1;
      }
      svg {
        [stroke] {
          stroke: var(--themeColor);
        }
        [fill] {
          fill: var(--themeColor);
        }
      }
      span {
        color: var(--themeColor);
        font-weight: 600;
      }
    } */
  }
  
  .sidebar-list {
    height: calc(100vh - 86px);
    overflow-y: auto;
  }
  @media (max-width: 767px){
	& {
		width: 100%;
	}
	a {
		&::before {
			display: none;
		}
	}
	.sidebar-list {
		height: auto;
	}
  }
`;