import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BreadcrumbIcon } from "../../styled/AllImages";
import { useTranslation } from "react-i18next";
import { useBreadcrumbs } from "../../../hooks/useBreadcrumb";

const Breadcrumbs = () => {
    const {t} = useTranslation()
    const breadcrumbsList = useBreadcrumbs();

  return (
    <BreadcrumbWrapper>
      {breadcrumbsList.map((crumb, index) => {
        const isLast = index === breadcrumbsList.length - 1;

        return (
          <React.Fragment key={index}>
            {index !== 0 && <BreadcrumbIcon width={16} height={16} className="mx-3" />}
            {isLast ? <span>{t(crumb.breadcrumb)}</span> : <Link to={crumb.path}>{t(crumb.breadcrumb)}</Link>}
          </React.Fragment>
        );
      })}
    </BreadcrumbWrapper>
  );
};

export default Breadcrumbs;

const BreadcrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--text2);

  a {
    color: #6c757d;
    text-decoration: none;
    &:hover {
      color: var(--themeColor);
    }
  }


  span:last-child {
    font-weight: bold;
    color: var(--themeColor);
  }
`;