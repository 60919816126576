import { Navigate } from "react-router-dom";
import AddCabinet from "../pages/admin/cabinets/AddCabinet";
import Cabinets from "../pages/admin/cabinets/Cabinets";
import EditCabinet from "../pages/admin/cabinets/EditCabinet";
import AddArchive from "../pages/admin/cabinets/files/addArchive/addArchive";
import EditArchive from "../pages/admin/cabinets/files/addArchive/editArchive";
import AddFile from "../pages/admin/cabinets/files/addFile/addFile";
import EditFile from "../pages/admin/cabinets/files/addFile/editFile";
import Archives from "../pages/admin/cabinets/files/Archives";
import Files from "../pages/admin/cabinets/files/Files";
import ArchiveDetail from "../pages/admin/cabinets/files/viewArchive";
import Dashboard from "../pages/admin/dashboard/Dashboard";
import History from "../pages/admin/history/History";
import HistoryDetails from "../pages/admin/history/HistoryDetails";
import HQBranches from "../pages/admin/hqBranches/HQBranches";

import Profile from "../pages/admin/profile/Profile";
import Requests from "../pages/admin/requests/Requests";
import Overview from "../pages/admin/settings/overview";
import AddUsers from "../pages/admin/users/AddUsers";
import EditUser from "../pages/admin/users/EditUser";
import UserDetails from "../pages/admin/users/UserDetails";
import Users from "../pages/admin/users/Users";
import Establishment from "../pages/admin/settings/establishment";
import AdminDirector from "../pages/admin/settings/admin_director";
import AdminsList from "../pages/admin/settings/admins_list";
import AdminDetails from "../pages/admin/settings/admins_detail";
import AddHQBranch from "../pages/admin/hqBranches/AddHQBranch";
import EditHQBranch from "../pages/admin/hqBranches/EditHQBranch";
import EditEstablishment from "../pages/admin/settings/editEstablishment";
import HQBranchDetails from "../pages/admin/hqBranches/HQBranchDetails";
import Departments from "../pages/admin/departments/Departments";
import AddDepartment from "../pages/admin/departments/AddDepartment";
import EditDepartment from "../pages/admin/departments/EditDepartment";
import EditAdminDirector from "../pages/admin/settings/edit_admin_director";
import DepartmentDetails from "../pages/admin/departments/DepartmentDetails";


export const privateRoutes = [
    {
        path: "dashboard",
        element: <Dashboard/>,
    },
    {
        path: "cabinets",
        element: <Cabinets/>,
    },
    {
        path: "cabinets/add-cabinet",
        element: <AddCabinet/>,
    },
    {
        path: "cabinets/edit-cabinet/:id",
        element: <EditCabinet/>,
    },
    {
        path: "cabinets/files",
        element: <Files/>,
    },
    {
        path: "profile",
        element: <Profile/>,
    },
    {
        path: "cabinets/files/add-file",
        element: <AddFile />,
    },
    {
        path: "cabinets/files/edit-file",
        element: <EditFile />,
    },
    {
        path: "cabinets/files/archives",
        element: <Archives />,
    },
    {
        path: "cabinets/files/archives/archive-details",
        element: <ArchiveDetail />,
    },
    {
        path: "cabinets/files/archives/add-archive",
        element: <AddArchive />,
    },
    {
        path: "requests",
        element: <Requests />,
    },
    {
        path: "history",
        element: <History />,
    },
    {
        path: "history/history-details",
        element: <HistoryDetails />,
    },
    {
        path: "users",
        element: <Users />,
    },
    {
        path: "users/add-user",
        element: <AddUsers />,
    },
    {
        path: "users/edit-user/:id",
        element: <EditUser />,
    },
    {
        path: "users/view-user/:id",
        element: <UserDetails />,
    },
    {
        path: "hq-branches",
        element: <HQBranches />,
    },
    {
        path: "hq-branches/add-hq-branch",
        element: <AddHQBranch />,
    },
    {
        path: "hq-branches/edit-hq-branch/:id",
        element: <EditHQBranch />,
    },
    {
        path: "hq-branches/view-hq-branch/:id",
        element: <HQBranchDetails />,
    },
    {
        path: "hq-branches/departments/:id",
        element: <Departments />,
    },
    {
        path: "hq-branches/departments/add-department",
        element: <AddDepartment />,
    },
    {
        path: "hq-branches/departments/edit-department",
        element: <EditDepartment />,
    },
    {
        path: "hq-branches/departments/view-department/:id",
        element: <DepartmentDetails />,
    },
    {
        path: "cabinets/files/archives/edit-archive",
        element: <EditArchive />,
    },
    {
        path: "settings",
        element: <Navigate to="/settings/overview" />,
    },
    {
        path: "settings/overview",
        element: <Overview />,
    },
    {
        path: "settings/establishment",
        element: <Establishment />,
    },
    {
        path: "settings/establishment/edit",
        element: <EditEstablishment />,
    },
    {
        path: "settings/admin-director",
        element: <AdminDirector />,
    },
    {
        path: "settings/admin-director/edit",
        element: <EditAdminDirector />,
    },
    
    {
        path: "settings/admins-list",
        element: <AdminsList />,
    },
    {
        path: "settings/admins-list/view-admin/:id",
        element: <AdminDetails />,
    },
    
]