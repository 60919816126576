import React from "react";
import { Modal } from "react-bootstrap";
import { Cancel } from "../../../styled/AllImages";
import {
  Box,
  DateInfo,
  HeadingStyled,
  LazyImage,
  SpanStyled,
  TimeInfo,
  UserInfo,
} from "../../../styled/Elements";
import { HistoryModalWrapper } from "./styled";
import { useTranslation } from "react-i18next";

const HistoryModal = ({
  show,
  onHide,
  title,
  createdBy,
  editHistory,
  transferHistory,
}) => {
  const { t } = useTranslation();
  return (
    <Modal size="lg" show={show} onHide={onHide} centered>
      <HistoryModalWrapper>
        <Box className="p-4">
          <Box className="d-flex justify-content-between align-items-center mb-4">
            <HeadingStyled size="24px" weight="bold" color="var(--text)">
              {title}
            </HeadingStyled>
            <Cancel
              className="cursor-pointer"
              style={{ height: "20px", width: "20px" }}
              onClick={onHide}
            />
          </Box>

          <Box className="mb-4">
            <section className="mb-3">
              <Box className="history-sub-headings">
                <HeadingStyled size="20px" weight="bold" color="var(--text)">
                  {t("created_by")}
                </HeadingStyled>
              </Box>
              <Box className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="py-2" style={{ width: "50%" }}>
                        <SpanStyled size="16px" color="var(--text)">
                          {t("name")}
                        </SpanStyled>
                      </th>
                      <th className="py-2" style={{ width: "25%" }}>
                        <SpanStyled size="16px" color="var(--text)">
                          {t("date")}
                        </SpanStyled>
                      </th>
                      <th className="py-2" style={{ width: "25%" }}>
                        <SpanStyled size="16px" color="var(--text)">
                          {t("time")}
                        </SpanStyled>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="py-2 align-middle">
                        <UserInfo
                          name={createdBy.name}
                          role={createdBy.role}
                          avatar={createdBy.avatar}
                        />
                      </td>
                      <td className="py-2 align-middle">
                        <DateInfo date={createdBy.date} />
                      </td>
                      <td className="py-2 align-middle">
                        <TimeInfo time={createdBy.time} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            </section>

            {editHistory && (
              <section className="mb-3">
                <Box className="history-sub-headings">
                  <HeadingStyled size="20px" weight="bold" color="var(--text)">
                    {t("edit_history")}
                  </HeadingStyled>
                </Box>
                <Box className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="py-2" style={{ width: "50%" }}>
                          <SpanStyled size="16px" color="var(--text)">
                            {t("name")}
                          </SpanStyled>
                        </th>
                        <th className="py-2" style={{ width: "25%" }}>
                          <SpanStyled size="16px" color="var(--text)">
                            {t("date")}
                          </SpanStyled>
                        </th>
                        <th className="py-2" style={{ width: "25%" }}>
                          <SpanStyled size="16px" color="var(--text)">
                            {t("time")}
                          </SpanStyled>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {editHistory &&
                        editHistory.length > 0 &&
                        editHistory.map((edit, index) => (
                          <tr key={index}>
                            <td className="py-2 align-middle">
                              <UserInfo
                                name={edit.name}
                                role={edit.role}
                                avatar={edit.avatar}
                              />
                            </td>
                            <td className="py-2 align-middle">
                              <DateInfo date={edit.date} />
                            </td>
                            <td className="py-2 align-middle">
                              <TimeInfo time={edit.time} />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Box>
              </section>
            )}

            {transferHistory && (
              <section>
                <Box>
                  <HeadingStyled size="20px" weight="bold" color="var(--text)">
                    {t("transfer_history")}
                  </HeadingStyled>
                </Box>
                <Box className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="py-2" style={{ width: "30%" }}>
                          <SpanStyled size="16px" color="var(--text)">
                            {t("from")}
                          </SpanStyled>
                        </th>
                        <th className="py-2" style={{ width: "30%" }}>
                          <SpanStyled size="16px" color="var(--text)">
                            {t("to")}
                          </SpanStyled>
                        </th>
                        <th className="py-2" style={{ width: "20%" }}>
                          <SpanStyled size="16px" color="var(--text)">
                            {t("date")}
                          </SpanStyled>
                        </th>
                        <th className="py-2" style={{ width: "20%" }}>
                          <SpanStyled size="16px" color="var(--text)">
                            {t("time")}
                          </SpanStyled>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {transferHistory &&
                        transferHistory.length > 0 &&
                        transferHistory.map((transfer, index) => (
                          <tr key={index}>
                            <td className="py-2 align-middle">
                              <UserInfo
                                user={transfer.from}
                                name={transfer.from.name}
                                role={transfer.from.role}
                                avatar={transfer.from.avatar}
                              />
                            </td>
                            <td className="py-2 align-middle">
                              <UserInfo
                                name={transfer.to.name}
                                role={transfer.to.role}
                                avatar={transfer.to.avatar}
                              />
                            </td>

                            <td className="py-2 small align-middle">
                              <DateInfo date={transfer.date} />
                            </td>
                            <td className="py-2 small align-middle">
                              <TimeInfo time={transfer.time} />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Box>
              </section>
            )}
          </Box>
        </Box>
      </HistoryModalWrapper>
    </Modal>
  );
};

export default HistoryModal;
