import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Cancel, DateIcon, Profile, SearchIcon, TimeIcon } from "../../../../../../../components/styled/AllImages";
import {
	Box,
	ButtonStyled,
	HeadingStyled,
	LazyImage,
	SpanStyled,
} from "../../../../../../../components/styled/Elements";

import { useTranslation } from "react-i18next";
import { CustomCheckbox, RadioButton, SelectListWithIcon } from "../../../../../../../components/styled/FormElements";
import NewCustomCheckbox from "../../../../../../../components/common/checkbox/NewCustomCheckbox";
import styled from "styled-components";
import FloatingInput from "../../../../../../../components/common/inputFields/FloatingInput";




const UserDataModal = ({
	show,
	onHide,
	title,
	mainParents,
	pendingSelection,
	setPendingSelection,
	handleAddUserClick,
}) => {
	const { t } = useTranslation();


	return (
		<Modal size="md" show={show} onHide={onHide} centered>
			<Box className="d-flex justify-content-between align-items-center p-3">
				<HeadingStyled size="24px" color="var(--text)">
					{title}
				</HeadingStyled>
				<span className="close-button cursor-pointer" onClick={onHide}>
					<Cancel />
				</span>
			</Box>
			<Box className="d-flex p-3 pt-0 border-bottom">
				<FloatingInput
					name="search"
					type="input"
					fieldType="text"
					label={t("search")}
					placeholder={t("search")}
					icon={SearchIcon}
				/>
			</Box>
			{mainParents &&
				mainParents.map((mainParent) => (
					<UserDataRow
						href="#!"
						className={`userDataRow ${pendingSelection?.id === mainParent.id && "selected"
							}`}
						key={mainParent.id}
					>
						<Box className="userDataContainer">
							<Box className="userDataInfo"
								// onClick={() => onMainParentClick(mainParent)}
								>
								{mainParent.departmentName ? ( 
									<Box className="userDataNameRole">
										<SpanStyled color="var(--text)" size="16px" family="var(--regularBold)" className="">
											{t(mainParent.departmentName)}
										</SpanStyled>
									</Box>
										
								):(
									<>
										<Box className="userDataImg">
											<img src={mainParent.profile} alt="User" />
										</Box>
										<Box className="userDataNameRole">
											<SpanStyled color="var(--text)" size="16px" family="var(--regularBold)" className="">
												{mainParent.userName}
											</SpanStyled>
											<SpanStyled color="var(--text2)" size="14px" family="var(--light)" className="">
												{t(mainParent.userRole)}
											</SpanStyled>
										</Box>
									</>
								)}
								<Box className="userDataCheckBox">
									<RadioButton
										type="radio"
										name="user"
										checked={pendingSelection?.id === mainParent.id}
										onChange={() => setPendingSelection(mainParent)} // Update pending user selection
									/>
								</Box>
							</Box>
							
						</Box>
					</UserDataRow>
				))}
				<Box className="modal-footer">
				
					<ButtonStyled
						label={t("Cancel")}
						onClick={onHide}
						bg="var(--bgSecondary)"
						color="var(--text2)"
					/>
					<ButtonStyled
						bg="var(--themeColor)"
						
						label={t("add")}
						onClick={handleAddUserClick} // Only add the user when the Add button is clicked
					/>
				</Box>
			
		</Modal>
	);
};

export default UserDataModal;
const UserDataRow = styled.div`
	padding: 1rem;
	border-bottom: 1px solid var(--borderLight);
  	width: 100%;
	cursor: pointer;
	.userDataContainer{
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
	}
	.userDataInfo{
		display: flex;
		width: 100%;
		align-items: center;
		gap: 16px;
		/* padding-right: 10px; */
		.userDataImg{
			display: block;
			min-width: 48px;
			max-width: 48px;
			height: 48px;
			border-radius: 50%;
			overflow: hidden;
			img{
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
		.userDataNameRole{
			display: flex;
			width: 100%;
			flex-wrap: wrap;
			span{
				width: 100%;
			}
		}
	}
	.userDataCheckBox{
		.customRadio label{
			padding-left: 26px;
		}
		
	}
`