import React, { useState, useRef, useEffect } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { InfoTooltip } from "../../styled/AllImages";
import { useTranslation } from "react-i18next";
import { Box, HeadingStyled, SubHeadingStyled, TextStyled } from "../../styled/Elements";

const ReasonTooltip = ({ data }) => {
  const [offset, setOffset] = useState([]);
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const handleClick = (event) => {
    setShow(!show);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (language === "en") setOffset([-155, 0]);
    else setOffset([155, 0]);
  }, [language]);

  return (
    <TooltipWrapper>
      <span className="default-icon" ref={ref} onClick={handleClick}>
        <InfoTooltip />
      </span>

      <Overlay
        target={ref.current}
        show={show}
        popperConfig={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: offset,
              },
            },
          ],
        }}
        placement="bottom"
        container={ref.current}
      >
        {(props) => (
          <Tooltip {...props}>
            <TooltipContentStyled className="p-0">
                  <Box className="my-2 px-3 py-3 single-info-box">
                    <HeadingStyled size="14px" weight={700} color="var(--text)">{t("reason")}</HeadingStyled>
                    <SubHeadingStyled size="14px" color="var(--text2)">{t(data)}</SubHeadingStyled>
                  </Box>
            </TooltipContentStyled>
          </Tooltip>
        )}
      </Overlay>
    </TooltipWrapper>
  );
};

export default ReasonTooltip;

export const TooltipWrapper = styled.div`
.tooltip-inner {
      max-width: 350px;
      min-width: 233px;
    text-align: start;
    padding: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--borderLight);
    background-color: var(--white);
    color: var(--text);
    border-radius: 12px;
  }
  .tooltip-arrow::before {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-bottom-color: var(--white);
  }
`;

const TooltipContentStyled = styled.div`
  z-index: 1000;
  .tooltip-row {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-weight: bold;
    }
  }
`;
