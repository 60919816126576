import React, { useState } from "react";
import { UsersWrapper } from "./styled";
import {
  Box,
  ButtonStyled,
  LabelStyled,
  SubHeadingStyled,
} from "../../../components/styled/Elements";
import ListTable from "../../../components/common/tables/ListTable";
import {
  departmentsList,
  historyData,
  usersListRows,
} from "../../../helpers/dummyConstants";
import { usersListColumns } from "../../../helpers/adminConstants/TableConstants";
import { useTranslation } from "react-i18next";
import { PlusIcon, SearchIcon } from "../../../components/styled/AllImages";
import { Col, Row } from "react-bootstrap";
import FormRadio from "../../../components/common/radioButtons/FormRadio";
import { DepartmentDropdown } from "../../../components/common/dropdowns/DropdownInputs";
import FloatingInput from "../../../components/common/inputFields/FloatingInput";
import CustomTile from "../../../components/common/statusTiles/CustomTile";
import { usersTileArray } from "../../../helpers/adminConstants/AdminConstants";
import DeleteModal from "../../../components/admin/Modals/DeleteModal/DeleteModal";
import HistoryModal from "../../../components/admin/Modals/HistoryModal/HistoryModal";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [showDelete, setShowDelete] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const onDelete = () => {
    setShowDelete(true);
  };

  const onHistory = () => {
    setShowHistory(true)
  }

  const handleView = (user) => {
    navigate(`/users/view-user/${user.id}`)
  }

  const handleEdit = (user) => {
    navigate(`/users/edit-user/${user.id}`)
  }
  return (
    <UsersWrapper>
      <Box className="content-head py-3 px-3 px-md-4">
        <SubHeadingStyled size="18px" weight="700" color="var(--text)">
          {t("users")}
        </SubHeadingStyled>

        <Box>
          <ButtonStyled label={t("add_user")} icon={PlusIcon} onClick={() => navigate('/users/add-user')} />
        </Box>
      </Box>

      <Row className="mx-2 mt-2">
        <Col lg={5} md={8} sm={12} className="my-2">
          <Box className="d-flex align-items-center type-select-box">
            <LabelStyled size="14px">{t("type")}</LabelStyled>
            <FormRadio
              name="type"
              subLabel={t("all")}
              value={1}
              // checked={selected === 2}
              // onChange={onSelect}
            />
            <FormRadio
              name="type"
              subLabel={t("activated")}
              value={1}
              // checked={selected === 2}
              // onChange={onSelect}
            />
            <FormRadio
              name="type"
              subLabel={t("deactivated")}
              value={1}
              // checked={selected === 2}
              // onChange={onSelect}
            />
          </Box>
        </Col>
        <Col lg={3} md={4} sm={6} className="my-2">
          <DepartmentDropdown
            departmentList={departmentsList}
            placeholder={t("search_by_department")}
          />
        </Col>
        <Col lg={4} md={5} sm={6} className="my-2">
          <FloatingInput
            name="search"
            type="input"
            fieldType="text"
            label={t("search")}
            placeholder={t("search")}
            bg="var(--white)"
            icon={SearchIcon}
          />
        </Col>
      </Row>

      <Row className="mx-2">
        {usersTileArray.map((tile, key) => (
          <Col lg={3} md={6} sm={12} className="my-2">
            <CustomTile key={key} {...tile} />
          </Col>
        ))}
      </Row>
      <Box>
        <ListTable
          options={["view", "info", "edit", "status", "delete"]}
          rows={usersListRows}
          columns={usersListColumns}
          onDelete={onDelete}
          onInfoView={onHistory}
          onViewDetails={handleView}
          onEdit={handleEdit}
        />
      </Box>
      <HistoryModal
        title={t("user_history")}
        show={showHistory}
        onHide={() => setShowHistory(false)}
        createdBy={historyData.createdBy}
        editHistory={historyData.editHistory}
      />
      <DeleteModal
        show={showDelete}
        onHide={() => setShowDelete(false)}
        title={t("user")}
      />
    </UsersWrapper>
  );
};

export default Users;
