import React, { useState } from "react";
// import {
//     BackButton,
//     Box,
//     ButtonStyled,
//     FormElementWrapper,
//     LabelStyled,
//     SpanStyled,
//     SubHeadingStyled,
// } from "../../../../components/styled/Elements";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Box, BackButton, ButtonStyled, FormElementWrapper, LabelStyled, SpanStyled, SubHeadingStyled, DataContainerHeader, } from "../../../components/styled/Elements";
import { SettingsWrapper, FormDataContainer,} from "./styled";
// import {} from "../../../../components/styled/AllImages";
// import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/admin/Breadcrumbs/Breadcrumbs";
import { adminDirectorContact, adminDirectorInit, adminDirectorUploads, adminsListRows, adminsViewsListRows, branchesRows, establishmentContact, establishmentInit, establishmentLocation, establishmentUploads, headquartersRows, historyDetailRows, tempADaccessRows, viewarchive } from "../../../helpers/dummyConstants";
import ViewPage from "../../../components/common/pageView/pageView";
import { fileTileArray, overviewTileArray } from "../../../helpers/adminConstants/AdminConstants";
import CustomTile from "../../../components/common/statusTiles/CustomTile";
import { Edit } from "../../../components/styled/AllImages";
import ListTable from "../../../components/common/tables/ListTable";
import { adminsListColumns, adminsViewsListColumns, branchesColumns, headquartersColumns, historyDetailColumns, tempADaccessColumns } from "../../../helpers/adminConstants/TableConstants";
import { InformationRowWrapper } from "../../../components/styled/styled";
// import FloatingInput from "../../../../components/common/inputFields/FloatingInput";
// import Breadcrumbs from "../../../../components/admin/Breadcrumbs/Breadcrumbs";
// import ValidatedInput from "../../../../components/common/inputFields/ValidatedInput";
// import ViewPage, { ViewPageWrapper } from "../../../../components/common/pageView/pageView";
// import { viewarchive } from "../../../../helpers/dummyConstants";

const AdminDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // const [checkSelectedValueID, setcheckSelectedValueID] = useState(null);
    // const checkSelectedValue = (e) => {
    //     setcheckSelectedValueID(Number(e.target.value));
    // };
    // const { register } = useForm();
    const handleButtonClick = () => {
        // setcheckSelectedValueID(Number(e.target.value));
    };
    return (
        <SettingsWrapper>
            <Box className="content-head py-3 px-3 px-md-4">
                <SubHeadingStyled size="16px" color="var(--text)">
					{t("settings")}
				</SubHeadingStyled>
            </Box>
            <Box className="content-container">
                <Row className="mt-3">
                    <Col md={12}>
                        
                        <FormDataContainer className="dataContainer">
                            <DataContainerHeader 
                                label={t("admins_list")}
                                backBtnLabel="admins_list"
                                onClickBack={() => navigate("/settings/admins-list")}
                            />
                        </FormDataContainer>
                    </Col>
                </Row>
            </Box>
            <Box>
                <InformationRowWrapper className="m-3">
                    <Box className="table-content">
                        <Box className="column">
                            <Box className="column-label">{t("AdminName")}</Box>
                            <Box className="column-value">Guy Hawkins</Box>
                        </Box>
                        <Box className="column">
                            <Box className="column-label">{t("email")}</Box>
                            <Box className="column-value">guyhawkins@example.com</Box>
                        </Box>
                        <Box className="column">
                            <Box className="column-label">{t("ContactNo")}</Box>
                            <Box className="column-value">(207) 555-0119</Box>
                        </Box>
                        <Box className="column">
                            <Box className="column-label">{t("numOfLicensePlaceholder")}</Box>
                            <Box className="column-value">524</Box>
                        </Box>
                        <Box className="column">
                            <Box className="column-label">{t("NoofUsersCreated")}</Box>
                            <Box className="column-value">954</Box>
                        </Box>
                        <Box className="column">
                            <Box className="column-label">{t("no_of_licenses_left")}</Box>
                            <Box className="column-value">726</Box>
                        </Box>
                    </Box>
                </InformationRowWrapper>
            </Box>
            <Box>
                <ListTable 
                    options={["tooltip2"]}
                    rows={adminsViewsListRows} 
                    columns={adminsViewsListColumns} 
                />
            </Box>
        </SettingsWrapper>

    );
};

export default AdminDetails;
