import React, { useState } from "react";
import { ContentWrapper } from "../../../components/styled/styled";
import {
    BackButton,
  Box,
  ButtonStyled,
  LabelStyled,
  SelectionDropdown,
  SpanStyled,
} from "../../../components/styled/Elements";
import { useTranslation } from "react-i18next";
import FormRadio from "../../../components/common/radioButtons/FormRadio";
import ValidatedInput from "../../../components/common/inputFields/ValidatedInput";
import { cityOptions, usersList } from "../../../helpers/dummyConstants";
import { ManageHqBranchesWrapper } from "./styled";
import { useForm } from "react-hook-form";
import AddSingleUser from "../cabinets/files/addFile/components/addSingleUser/MainData";
import { Plus, PlusIcon } from "../../../components/styled/AllImages";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/admin/Breadcrumbs/Breadcrumbs";

const EditHQBranch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    type: null,
  });

  const { setValue } = useForm({ defaultValues: formData });

  const handleChange = (e) => {
    const { value, name } = e.target;

    setValue(name, value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <ManageHqBranchesWrapper>
      <Box className="content-head py-3 px-3 px-md-4">
        <Box className="d-flex align-items-center gap-5">
          <BackButton
            label={t("edit_hq_branch")}
            onClick={() => navigate("/hq-branches")}
          />
          <Breadcrumbs />
        </Box>
      </Box>
      <ContentWrapper>
        <Box className="pt-4">
          <Box className="d-flex align-items-center px-4">
            <Box className="form-label">
              <LabelStyled>{t("type")}</LabelStyled>
            </Box>
            <Box>
              <Box className="d-flex align-items-center">
                <Box>
                  <FormRadio
                    name={"type"}
                    label={t("headquarter")}
                    value={1}
                    checked={formData.type == "1"}
                    onChange={handleChange}
                  />
                </Box>
                <Box>
                  <FormRadio
                    name={"type"}
                    label={t("branch")}
                    value={2}
                    checked={formData.type == "2"}
                    onChange={handleChange}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <ValidatedInput
              name={"english_name"}
              label={t("name_en")}
              placeholder={t("english_name")}
              className={"border-0"}
            />
            <ValidatedInput
              name={"arabic_name"}
              label={t("name_ar")}
              placeholder={t("arabic_name")}
              className={"border-0"}
            />
            <ValidatedInput
              name={"initial_name"}
              label={t("initial_name")}
              placeholder={t("initial_name")}
              className={"border-0"}
            />
          </Box>
        </Box>
        <Box className="mt-4">
          <SpanStyled
            size={"20px"}
            weight="800"
            color="var(--text)"
            className="px-4"
          >
            {t("location")}
          </SpanStyled>
          <Box className="d-flex align-items-center px-4 hqbranch-form-item py-4">
            <Box className="form-label">
              <LabelStyled>{t("City")}</LabelStyled>
            </Box>
            <SelectionDropdown
              name={"city"}
              label={t("name_en")}
              placeholder={t("select_city")}
              options={cityOptions}
            />
          </Box>
          <ValidatedInput
            name={"building"}
            label={t("building")}
            placeholder={t("building")}
            className={"border-0"}
          />
          <Box className="d-flex align-items-center px-4 hqbranch-form-item py-4">
            <Box className="form-label">
              <LabelStyled>{t("floor")}</LabelStyled>
            </Box>
            <SelectionDropdown
              name={"floor"}
              label={t("floor")}
              placeholder={t("select_floor")}
              options={cityOptions}
            />
          </Box>
          <ValidatedInput
            name={"office"}
            label={t("office")}
            placeholder={t("office")}
            className={"border-0"}
          />
          <ValidatedInput
            name={"address"}
            label={t("address")}
            placeholder={t("address")}
            className={"border-0"}
          />
        </Box>
        <Box className="mt-4">
          <SpanStyled
            size={"20px"}
            weight="800"
            color="var(--text)"
            className="px-4"
          >
            {t("contact")}
          </SpanStyled>
          <ValidatedInput
            name={"contact_no"}
            label={t("contact_no")}
            placeholder={t("contact_no")}
            className={"border-0"}
          />
          <ValidatedInput
            name={"email"}
            label={t("email")}
            type={"input"}
            fieldType={"email"}
            placeholder={t("emailPlaceholder")}
            className={"border-0"}
          />
          <Box className="d-flex align-items-center px-4 hqbranch-form-item py-4">
            <Box className="form-label">
              <LabelStyled>{t("contact_person")}</LabelStyled>
            </Box>
            <AddSingleUser
              title={t("add_contact_person")}
              icon={<Plus />}
              mainParents={usersList}
              selPlaceholder={t("add_contact_person")}
            />
          </Box>
          <Box className="d-flex align-items-center px-4 hqbranch-form-item py-4">
            <Box className="form-label">
              <LabelStyled>{t("no_of_departments")}</LabelStyled>
            </Box>
            <SelectionDropdown
              name={"city"}
              label={t("name_en")}
              placeholder={t("select_number")}
              options={cityOptions}
            />
          </Box>
        </Box>
        <Box className="d-flex justify-content-end gap-3 p-4">
          <ButtonStyled
            label={t("Cancel")}
            bg={"var(--bgSecondary)"}
            color={"var(--text)"}
          />
          <ButtonStyled label={t("send")} bg={"var(--themeColor)"} />
        </Box>
      </ContentWrapper>
    </ManageHqBranchesWrapper>
  );
};

export default EditHQBranch;
