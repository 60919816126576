import React, { useState, useId } from 'react';
import styled from 'styled-components';
import { Cancel, DocxIcon, FileUpload, PdfIcon } from '../../styled/AllImages';
import { useTranslation } from "react-i18next";

const ImageUpload = ({UploadImage}) => {
    const { t } = useTranslation();
    const [selectedImage, setSelectedImage] = useState(null); // Store selected image
    const uniqueId = useId(); // Generate a unique ID for each instance

    // Function to handle file selection (image upload)
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileURL = URL.createObjectURL(file); // Generate a URL for the selected image
            setSelectedImage(fileURL); // Store the image URL
        }
    };

    // Function to remove the selected image
    const handleRemoveImage = () => {
        setSelectedImage(null); // Reset the image
    };

    return (
        <UploadContainer className='uploadImgContainer' >
            {selectedImage ? (
                <ImagePreviewContainer>
                    <ImagePreview src={selectedImage} alt="Selected" />
                    <RemoveButton onClick={handleRemoveImage}>
                        <Cancel />
                    </RemoveButton>
                </ImagePreviewContainer>
            ) : (
                <DefaultUploadBox className='defaultUploadBox' onClick={() => document.getElementById(`imageInput-${uniqueId}`).click()}>
                    <FileUpload size={40} />
                    <UploadText>{t(UploadImage)}</UploadText>
                </DefaultUploadBox>
            )}

            {/* Hidden file input */}
            <input
                id={`imageInput-${uniqueId}`} // Use the unique ID here
                type="file"
                accept="image/png, image/jpeg, image/jpg" // Accept only image files
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </UploadContainer>
    );
};

export default ImageUpload;

// Styled components
const UploadContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 450px;
  border: 2px dashed var(--text2);
  position: relative;
  border-radius: 10px;
`;

const DefaultUploadBox = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 30px;
  font-size: 14px;
  color: var(--text2);
`;

const UploadText = styled.p`
  font-size: 14px;
  margin-top: 7px;
  color: var(--text2);
  line-height: 18px;
`;

const ImagePreviewContainer = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 10px;
`;


const RemoveButton = styled.button`
  position: absolute;
  top: 11px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  svg{
    width: 14px;
    height: auto;
    [fill] {
        fill: var(--redColor);
    }

  }
`;
