import React from "react";
import { Box, FormButtonStyled, HeadingStyled, SpanStyled, TextStyled } from "../../../../components/styled/Elements";
import OTPInputField from "../../../../components/common/inputFields/OTPInputField";
import { useTranslation } from "react-i18next";

const PhoneSignin2 = ({register, handleSteps}) => {
    const {t} = useTranslation()
  return (
    <Box>
      <Box className="mb-4">
        <HeadingStyled size="24px" weight="bold" color="var(--text)">
          {t("we_just_texted_you")}
        </HeadingStyled>
        <TextStyled size="16px" color="var(--text2)">
          {t("enter_the_code_we_sent_you")}
        </TextStyled>
      </Box>
      <Box>
        <Box>
          <OTPInputField />
          <FormButtonStyled
            label={t("sign_in")}
            color="var(--white)"
            bg="var(--themeColor)"
            className="w-100"
            clName="w-100 my-4"
            onClick={handleSteps}
          />
        </Box>
        <Box className="d-flex justify-content-end">
          <Box>
            <TextStyled color="var(--text2  )" size="16px" weight="500">
              {t("did_not_receive_otp")}
              &emsp;
              <SpanStyled
                className="cursor-pointer"
                color="var(--themeColor)"
                size="16px"
                weight="700"
              >
                {t("resend")}
              </SpanStyled>
            </TextStyled>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PhoneSignin2;
