import {
    CloudLogo,
    Download,
    PdfIcon,
  Profile,
  Profile2,
  Profile3,
  Profile4,
  ReceivedArchives,
  SaudiIdcard,
  SaudiIdCard2,
  SentArchives,
  TotalArchives,
} from "../components/styled/AllImages";
import { ProfileImg } from "../components/styled/AllImages";
import { Box, DateInfo, SpanStyled } from "../components/styled/Elements";

export const addCabinetRadio = [
    {
        id: 1,
        name: "only_view",
        label: "in_department_of_cabinet_creator",
    },
    {
        id: 2,
        name: "open_to_all_users",
        label: "in_department_of_cabinet_creator",
    },
    {
        id: 3,
        name: "only_open_to_select_multi_user_others_view",
        label: "in_department_of_cabinet_creator",
    },
    {
        id: 4,
        name: "only_open_to_multiple_user",
        label: "in_department_of_cabinet_creator",
    },
    {
        id: 5,
        name: "only_open_to_one_department",
        label: "rest_of_the_departments_can_view",
    },
    {
        id: 6,
        name: "only_open_to_multi_department",
    },
    {
        id: 7,
        name: "private",
    },
]
export const addFileRadio = [
  {
      id: 1,
      name: "only_view",
      label: "in_department_of_cabinet_creator",
  },
  {
      id: 2,
      name: "open_to_all_users",
      label: "in_department_of_cabinet_creator",
  },
  {
      id: 3,
      name: "only_open_to_select_multi_user_others_view",
      label: "in_department_of_cabinet_creator",
  },
  {
      id: 4,
      name: "only_open_to_multiple_user",
      label: "in_department_of_cabinet_creator",
  },
  {
      id: 5,
      name: "only_open_to_one_department",
      label: "rest_of_the_departments_can_view",
  },
  {
      id: 6,
      name: "only_open_to_multi_department",
  },
  {
      id: 7,
      name: "private",
  },
]
export const archiveFilters = [
    {
        id: 1,
        name: "all",
    },
    {
        id: 2,
        name: "sent",
    },
    {
        id: 3,
        name: "received",
    },
  
]
//   {
//     id: 1,
//     name: "only_view",
//     label: "in_department_of_cabinet_creator",
//   },
//   {
//     id: 2,
//     name: "open_to_all_users",
//     label: "in_department_of_cabinet_creator",
//   },
//   {
//     id: 3,
//     name: "only_open_to_select_multi_user_others_view",
//     label: "in_department_of_cabinet_creator",
//   },
//   {
//     id: 4,
//     name: "only_open_to_multiple_user",
//     label: "in_department_of_cabinet_creator",
//   },
//   {
//     id: 5,
//     name: "only_open_to_one_department",
//     label: "rest_of_the_departments_can_view",
//   },
//   {
//     id: 6,
//     name: "only_open_to_multi_department",
//   },
//   {
//     id: 7,
//     name: "private",
//   },
// ];

export const usersList = [
  {
    id: 1,
    profile: Profile,
    userName: "Jenny Wilson",
    userRole: "Corporate Administration (NR Branch)",
  },
  {
    id: 2,
    profile: Profile2,
    userName: "Brooklyn Simmons",
    userRole: "Sales and Distribution (CC Branch)",
  },
  {
    id: 3,
    profile: Profile3,
    userName: "Arlene McCoy",
    userRole: "Research and Development (EB Branch)",
  },
  {
    id: 4,
    profile: Profile4,
    userName: "Devon Lane",
    userRole: "IT Infrastructure and Security (NR Branch)",
  },
];

export const departmentsList = [
  {
    id: 1,
    departmentName: "corporate_administration",
    branch: "north_region_branch"
  },
  {
    id: 2,
    departmentName: "it_infrastructure_security",
    branch: "north_region_branch"
  },
  {
    id: 3,
    departmentName: "sales_and_distribution",
    branch: "north_region_branch"
  },
  {
    id: 4,
    departmentName: "research_and_development",
    branch: "north_region_branch"
  },
];
export const receivedReqsRows = [
  {
    id: "00125",
    status: 1,
    requester: {
      name: "Albert Flores",
      role: "corporate_administration",
      avatar: ProfileImg,
    },
    accessRequestedFor: {
      utilityName: "Cabinet",
      infoData: [
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
      ],
    },
    decision: 0,
    closeDate: "",
  },
  {
    id: "00126",
    status: 2,
    requester: {
      name: "Theresa Webb",
      role: "it_infrastructure_security",
      avatar: ProfileImg,
    },
    accessRequestedFor: {
      utilityName: "Cabinet",
      infoData: [
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
      ],
    },
    decision: 1,
    closeDate: "5/30/14",
  },
  {
    id: "00127",
    status: 1,
    requester: {
      name: "Dianne Russell",
      role: "sales_and_distribution",
      avatar: ProfileImg,
    },
    accessRequestedFor: {
      utilityName: "File",
      infoData: [
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
      ],
    },
    decision: 0,
    closeDate: "",
  },
  {
    id: "00128",
    status: 2,
    requester: {
      name: "Ronald Richards",
      role: "customer_support_services",
      avatar: ProfileImg,
    },
    accessRequestedFor: {
      utilityName: "Archive",
      infoData: [
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
      ],
    },
    decision: 2,
    reason: "access_denied_due_to_insufficient_clearance",
    closeDate: "5/30/14",
  },
  {
    id: "00129",
    status: 1,
    requester: {
      name: "Robert Fox",
      role: "corporate_administration",
      avatar: ProfileImg,
    },
    accessRequestedFor: {
      utilityName: "Archive",
      infoData: [
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
      ],
    },
    decision: 0,
    closeDate: "",
  },
  {
    id: "00130",
    status: 2,
    requester: {
      name: "Darrell Steward",
      role: "sales_and_distribution",
      avatar: ProfileImg,
    },
    accessRequestedFor: {
      utilityName: "File",
      infoData: [
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
      ],
    },
    decision: 0,
    closeDate: "5/30/14",
  },
  {
    id: "00131",
    status: 1,
    requester: {
      name: "Savannah Nguyen",
      role: "it_infrastructure_security",
      avatar: ProfileImg,
    },
    accessRequestedFor: {
      utilityName: "Cabinet",
      infoData: [
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
      ],
    },
    decision: 0,
    closeDate: "",
  },
];
export const sentReqsRows = [
  {
    id: "00125",
    status: 1,
    benefactor: {
      name: "Albert Flores",
      role: "corporate_administration",
      avatar: ProfileImg,
    },
    accessRequestedFor: {
      utilityName: "Cabinet",
      infoData: [
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
      ],
    },
    decision: 0,
    closeDate: "",
  },
  {
    id: "00126",
    status: 2,
    benefactor: {
      name: "Theresa Webb",
      role: "it_infrastructure_security",
      avatar: ProfileImg,
    },
    accessRequestedFor: {
      utilityName: "Cabinet",
      infoData: [
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
      ],
    },
    decision: 1,
    closeDate: "5/30/14",
  },
  {
    id: "00127",
    status: 1,
    benefactor: {
      name: "Dianne Russell",
      role: "sales_and_distribution",
      avatar: ProfileImg,
    },
    accessRequestedFor: {
      utilityName: "File",
      infoData: [
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
      ],
    },
    decision: 0,
    closeDate: "",
  },
  {
    id: "00128",
    status: 2,
    benefactor: {
      name: "Ronald Richards",
      role: "customer_support_services",
      avatar: ProfileImg,
    },
    accessRequestedFor: {
      utilityName: "Archive",
      infoData: [
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
      ],
    },
    decision: 2,
    reason: "access_denied_due_to_insufficient_clearance",
    closeDate: "5/30/14",
  },
  {
    id: "00129",
    status: 1,
    benefactor: {
      name: "Robert Fox",
      role: "corporate_administration",
      avatar: ProfileImg,
    },
    accessRequestedFor: {
      utilityName: "Archive",
      infoData: [
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
      ],
    },
    decision: 0,
    closeDate: "",
  },
  {
    id: "00130",
    status: 2,
    benefactor: {
      name: "Darrell Steward",
      role: "sales_and_distribution",
      avatar: ProfileImg,
    },
    accessRequestedFor: {
      utilityName: "File",
      infoData: [
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
      ],
    },
    decision: 0,
    closeDate: "5/30/14",
  },
  {
    id: "00131",
    status: 1,
    benefactor: {
      name: "Savannah Nguyen",
      role: "it_infrastructure_security",
      avatar: ProfileImg,
    },
    accessRequestedFor: {
      utilityName: "Cabinet",
      infoData: [
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
        {
          title: "archive_id",
          value: "32413",
        },
      ],
    },
    decision: 0,
    closeDate: "",
  },
];

export const historyRows = [
  {
    key: "1",
    id: 125,
    date: "5/30/14",
    number: "62545",
    archiveTitle: "Contracts",
    views: "102",
    downloads: "102",
    infoData: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
  },
  {
    key: "2",
    id: 126,
    date: "5/7/16",
    number: "95452",
    archiveTitle: "Sales",
    views: "102",
    downloads: "102",
    infoData: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
  },
  {
    key: "3",
    id: 127,
    date: "8/2/19",
    number: "63254",
    archiveTitle: "Marketing",
    views: "102",
    downloads: "102",
    infoData: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
  },
  {
    key: "4",
    id: 128,
    date: "8/21/15",
    number: "95648",
    archiveTitle: "Products",
    views: "102",
    downloads: "102",
    infoData: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
  },
  {
    key: "5",
    id: 129,
    date: "4/4/18",
    number: "35624",
    archiveTitle: "Human Resources",
    views: "102",
    downloads: "102",
    infoData: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
  },
  {
    key: "6",
    id: 130,
    date: "7/11/19",
    number: "95874",
    archiveTitle: "Administration",
    views: "102",
    downloads: "102",
    infoData: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
  },
  {
    key: "7",
    id: 131,
    date: "9/18/16",
    number: "37951",
    archiveTitle: "Finance",
    views: "102",
    downloads: "102",
    infoData: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
  },
  {
    key: "8",
    id: 132,
    date: "7/27/13",
    number: "65328",
    archiveTitle: "Technical Support",
    views: "102",
    downloads: "102",
    infoData: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
  },
  {
    key: "9",
    id: 133,
    date: "5/27/15",
    number: "16578",
    archiveTitle: "Contracts",
    views: "102",
    downloads: "102",
    infoData: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
  },
  {
    key: "10",
    id: 134,
    date: "8/15/17",
    number: "26562",
    archiveTitle: "Sales",
    views: "102",
    downloads: "102",
    infoData: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
  },
  {
    key: "11",
    id: 135,
    date: "9/4/12",
    number: "12548",
    archiveTitle: "Marketing",
    views: "102",
    downloads: "102",
    infoData: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
  },
];
export const historyDetailRows = [
  {
    id: 125,
    date: "5/30/14",
    username: "Jane Cooper",
    user_info: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
    view: true,
    download: false,
  },
  {
    id: 126,
    date: "5/7/16",
    username: "Eleanor Pena",
    user_info: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
    view: true,
    download: false,
  },
  {
    id: 127,
    date: "8/2/19",
    username: "Savannah Nguyen",
    user_info: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
    view: true,
    download: true,
  },
  {
    id: 128,
    date: "8/2/15",
    username: "Jenny Wilson",
    user_info: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
    view: true,
    download: true,
  },
  {
    id: 129,
    date: "4/4/18",
    username: "Courtney Henry",
    user_info: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
    view: true,
    download: true,
  },
  {
    id: 130,
    date: "7/11/19",
    username: "Marvin McKinney",
    user_info: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
    view: true,
    download: true,
  },
  {
    id: 131,
    date: "9/18/16",
    username: "Esther Howard",
    user_info: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
    view: true,
    download: true,
  },
  {
    id: 132,
    date: "7/27/13",
    username: "Devon Lane",
    user_info: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
    view: false,
    download: true,
  },
  {
    id: 133,
    date: "5/27/15",
    username: "Darlene Robertson",
    user_info: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
    view: true,
    download: true,
  },
  {
    id: 134,
    date: "8/15/17",
    username: "Robert Fox",
    user_info: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
    view: true,
    download: true,
  },
  {
    id: 135,
    date: "9/4/12",
    username: "Arlene McCoy",
    user_info: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
    view: true,
    download: false,
  },
];
export const usersListRows = [
  {
    id: 125,
    status: '1',
    type: 'user',
    user: { name: 'Albert Flores', role: 'Software Engineer', image: ProfileImg},
    department: { name: 'corporate_administration', branch: 'North Region Branch' },
    email: 'nathan.roberts@example.com',
    phone: '(252) 555-0126',
  },
  {
    id: 126,
    status: '1',
    type: 'user',
    user: { name: 'Theresa Webb', role: 'Marketing Manager', image: ProfileImg },
    department: { name: 'it_infrastructure_security', branch: 'Central City Branch' },
    email: 'michelle.rivera@example.com',
    phone: '(303) 555-0105',
  },
  {
    id: 127,
    status: '2',
    type: 'admin',
    user: { name: 'Dianne Russell', role: 'Data Analyst', image: ProfileImg },
    department: { name: 'corporate_administration', branch: 'Eastside Branch' },
    email: 'tim.jennings@example.com',
    phone: '(225) 555-0118',
  },
  {
    id: 128,
    status: '1',
    type: 'user',
    user: { name: 'Ronald Richards', role: 'HR Specialist', image: ProfileImg },
    department: { name: 'sales_and_distribution', branch: 'West End Branch' },
    email: 'felicia.reid@example.com',
    phone: '(684) 555-0102',
  },
  {
    id: 129,
    status: '2',
    type: 'user',
    user: { name: 'Robert Fox', role: 'Project Manager', image: ProfileImg },
    department: { name: 'it_infrastructure_security', branch: 'South Valley Branch' },
    email: 'michael.mitc@example.com',
    phone: '(208) 555-0112',
  },
  {
    id: 130,
    status: '1',
    type: 'user',
    user: { name: 'Darrell Steward', role: 'Sales Executive', image: ProfileImg },
    department: { name: 'research_and_development', branch: 'North Region Branch' },
    email: 'sara.cruz@example.com',
    phone: '(207) 555-0119',
  },
  {
    id: 129,
    status: '2',
    type: 'user',
    user: { name: 'Robert Fox', role: 'Project Manager', image: ProfileImg },
    department: { name: 'Operations Department', branch: 'South Valley Branch' },
    email: 'michael.mitc@example.com',
    phone: '(208) 555-0112',
  },
  {
    id: 130,
    status: '1',
    type: 'user',
    user: { name: 'Darrell Steward', role: 'Sales Executive', image: ProfileImg },
    department: { name: 'sales_and_distribution', branch: 'North Region Branch' },
    email: 'sara.cruz@example.com',
    phone: '(207) 555-0119',
  },
];

export const archiveTileArray = [
	{
		icon: TotalArchives,
		count: "478",
		color: "var(--white)",
		bg: "var(--lightBlue)",
		title: "TotalArchives",
	},
	{
		icon: SentArchives,
		count: "301",
		color: "var(--white)",
		bg: "var(--orange)",
		title: "SentArchives",
	},
	{
		icon: ReceivedArchives,
		count: "177",
		color: "var(--white)",
		bg: "var(--lightGreen)",
		title: "ReceivedArchives",
	},
	
]; 



export const archiveRows = [
  {
    key: "1",
    id: 125,
    date: "5/30/14",
    title: "Contracts",
    number: "125",
    from: "Darlene Robertson",
    to: "Ralph Edwards",
    infoData: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
  },
  {
    key: "2",
    id: 126,
    date: "5/7/16",
    title: "Contracts",
    number: "125",
    from: "Darlene Robertson",
    to: "Ralph Edwards",
    infoData: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
  },
  {
    key: "3",
    id: 127,
    date: "8/2/19",
    title: "Contracts",
    number: "125",
    from: "Darlene Robertson",
    to: "Ralph Edwards",
    infoData: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
  },
  {
    key: "4",
    id: 128,
    date: "8/21/15",
    title: "Contracts",
    number: "125",
    from: "Darlene Robertson",
    to: "Ralph Edwards",
    infoData: [
      {
        title: "archive_id",
        value: "32413",
      },
      {
        title: "archive_id",
        value: "32413",
      },
    ],
  },
  
];

export const viewarchive = [
    {
        id: 1,
        label: "type",
        text: "Document",
    },
    {
        id: 2,
        label: "Nopages",
        text: "100",
    },
    {
        id: 3,
        label: "Attachment",
        text: "file",
        render: () => (
            <Box className="fileAttachmentBox">
                <img src={PdfIcon} width="24px" />
                <SpanStyled size="15px" color="var(--text2)" >
                    Employee Records.pdf
                </SpanStyled>
                <Download />
            </Box>
        ),
    },
    {
        id: 4,
        label: "status",
        text: "sent",
    },
    {
        id: 5,
        label: "date",
        text: "12/11/2024",
        render: (date) => (date ? <DateInfo date={date} /> : "-"),
    },
    {
        id: 6,
        label: "number",
        text: "654",
    },
    {
        id: 7,
        label: "title",
        text: "title",
    },
    {
        id: 8,
        label: "From",
        text: "From",
    },
    {
        id: 9,
        label: "To",
        text: "To",
    },
]

export const overviewData = [
    {
        id: 1,
        label: "name",
        text: "Arlene McCoy",
    },
    {
        id: 2,
        label: "email",
        text: "arlene@example.com",
    },
    {
        id: 3,
        label: "phone",
        text: "+966 963852741",
    },
    
]

export const addArchiveRadio = [
    {
        id: 1,
        name: "Document",
    },
    {
        id: 2,
        name: "Video",
    },
    {
        id: 3,
        name: "Voice",
    },
    {
        id: 4,
        name: "Picture",
    },
    {
        id: 5,
        name: "Other",
    },
    
]

export const statusArchiveRadio = [
    {
        id: 1,
        name: "sent",
    },
    {
        id: 2,
        name: "received",
    },
    {
        id: 3,
        name: "created",
    },
    
]

export const addArchiveSettingRadio = [
    {
        id: 1,
        name: "Based_on_setting",
        label: "Same_settings_archive",
    },
    {
        id: 2,
        name: "only_view",
        label: "in_department_of_archive_creator",
    },
    {
        id: 3,
        name: "only_open_to_select_multi_user_others_view",
        label: "in_department_of_archive_creator",
    },
    {
        id: 4,
        name: "only_open_to_multiple_user",
        label: "in_department_of_archive_creator",
    },
    {
        id: 5,
        name: "only_open_to_one_department",
        label: "rest_of_the_departments_can_view",
    },
    {
        id: 6,
        name: "only_open_to_multi_department",
    },
    {
        id: 7,
        name: "private",
    },
]


export const historyData = {
  createdBy: {
    name: "Jenny Wilson",
    role: "Corporate Administration (HQ Branch)",
    avatar: ProfileImg,
    date: "5/30/14",
    time: "10:32 PM",
  },
  editHistory: [
    {
      name: "Wade Warren",
      role: "Corporate Administration (CO Branch)",
      avatar: ProfileImg,
      date: "3/4/15",
      time: "12:54 PM",
    },
    // ... other edit history entries
  ],
  transferHistory: [
    {
      from: {
        name: "Floyd Miles",
        avatar: ProfileImg,
        role: "Some Role",
      },
      to: {
        name: "Wade Warren",
        avatar: ProfileImg,
        role: "Some Role",
      },
      date: "8/15/17",
      time: "12:54 PM",
    },
    {
      from: {
        name: "Floyd Miles",
        avatar: ProfileImg,
        role: "Some Role",
      },
      to: {
        name: "Wade Warren",
        avatar: ProfileImg,
        role: "Some Role",
      },
      date: "8/15/17",
      time: "12:54 PM",
    },
    // ... other transfer history entries
  ],
};



export const userPermissionsRows = [
  {
    module_name: "Cabinet",
    all: false,
    create: false,
    view: true,
    open: false,
    edit: false,
  },
  {
    module_name: "File",
    all: false,
    create: true,
    view: false,
    open: true,
    edit: false,
  },
  {
    module_name: "Archive",
    all: false,
    create: true,
    view: false,
    open: true,
    edit: false,
  },
  {
    module_name: "users",
    all: true,
    create: true,
    view: true,
    open: true,
    edit: false,
  },
  {
    module_name: "requests",
    all: true,
    create: true,
    view: true,
    open: true,
    edit: false,
  },
  {
    module_name: "history",
    all: true,
    create: true,
    view: true,
    open: true,
    edit: false,
  },
];

export const hqBranchRows = [
  {
    id: 1,
    type: 'Headquarters',
    name: 'NorthRegionBranch',
    city: 'New York',
    no_of_departments: 5,
    no_of_users: 120,
    actions: 'Manage',
  },
  {
    id: 2,
    type: 'Regional Office',
    name: 'NorthRegionBranch',
    city: 'Los Angeles',
    no_of_departments: 3,
    no_of_users: 80,
    actions: 'Manage',
  },
  {
    id: 3,
    type: 'Branch Office',
    name: 'NorthRegionBranch',
    city: 'Chicago',
    no_of_departments: 4,
    no_of_users: 95,
    actions: 'Manage',
  },
  {
    id: 4,
    type: 'Branch Office',
    name: 'NorthRegionBranch',
    city: 'Houston',
    no_of_departments: 2,
    no_of_users: 50,
    actions: 'Manage',
  },
  {
    id: 5,
    type: 'Headquarters',
    name: 'NorthRegionBranch',
    city: 'Boston',
    no_of_departments: 6,
    no_of_users: 150,
    actions: 'Manage',
  },
];


export const establishmentInit = [
    {
        id: 1,
        label: "type",
        text: "Government",
    },
    {
        id: 2,
        label: "Sector",
        text: "ITSector",
    },
    {
        id: 3,
        label: "name",
        text: "John Smith",
    },
    {
        id: 4,
        label: "Description",
        text: "DescriptionLocation",
    },
]
export const establishmentLocation = [
    {
        id: 1,
        label: "Country",
        text: "SaudiArabia",
    },
    {
        id: 2,
        label: "City",
        text: "Riyadh",
    },
    {
        id: 3,
        label: "Address",
        text: "AddressFull",
    },
    
]
export const establishmentContact = [
    {
        id: 1,
        label: "ContactNo",
        text: "+966 963852741",
    },
    {
        id: 2,
        label: "email",
        text: "johnsmith@example.com",
    },
    {
        id: 3,
        label: "ContactPerson",
        text: "John Smith",
    },
    
]
export const establishmentUploads = [
    {
        id: 1,
        label: "Logo",
        text: "Logo",
        render: () => (
            <Box className="fileAttachmentBox">
                <img src={CloudLogo} />
            </Box>
        ),
    },
    
]

export const branchesRows = [
    {
        key: "1",
        BranchnameEN: "North Region Branch",
        BranchnameAR: "فرع المنطقة الشمالية",
        City: "Riyadh",
    },
    {
        key: "2",
        BranchnameEN: "Central City Branch",
        BranchnameAR: "فرع المدينة الوسطى",
        City: "Jeddah",
    },
    {
        key: "3",
        BranchnameEN: "Eastside Branch",
        BranchnameAR: "فرع الجانب الشرقي",
        City: "Mecca",
    },
];
export const headquartersRows = [
    {
        key: "1",
        HeadquarternameEN: "Riyadh Headquarter",
        HeadquarternameAR: "المقر الرئيسي في الرياض",
        City: "Riyadh",
    },
    {
        key: "2",
        HeadquarternameEN: "Jeddah Headquarter",
        HeadquarternameAR: "المقر الرئيسي في جدة",
        City: "Jeddah",
    },
    
];

export const adminDirectorInit = [
    {
        id: 1,
        label: "name",
        text: "John Smith",
    },
    {
        id: 2,
        label: "JobTitle",
        text: "Manager",
    },
    {
        id: 3,
        label: "JobRole",
        text: "ProjectManager",
    },
    {
        id: 4,
        label: "HQBranch",
        text: "NorthRegionBranch",
    },
    {
        id: 5,
        label: "department",
        text: "ITDepartment",
    },
]
export const adminDirectorContact = [
    {
        id: 1,
        label: "ContactNo",
        text: "+966 963852741",
    },
    {
        id: 2,
        label: "email",
        text: "johnsmith@example.com",
    },

]
export const adminDirectorUploads = [
    {
        id: 1,
        label: "IDJobID",
        text: "IDJobID",
        render: () => (
            <Box className="fileAttachmentBox d-flex gap-3 flex-wrap">
                <img src={SaudiIdcard} />
                <img src={SaudiIdCard2} />
            </Box>
        ),
    },
    
]

export const tempADaccessRows = [
    {
        key: "1",
        user: { name: 'Theresa Webb', role: 'Marketing Manager', image: ProfileImg },
        startDate: '8/15/2024',
        endDate: '9/15/2024',
    },
    {
        key: "2",
        user: { name: 'Theresa Webb', role: 'Marketing Manager', image: ProfileImg },
        startDate: '8/15/2024',
        endDate: '9/15/2024',
    },
    {
        key: "3",
        user: { name: 'Theresa Webb', role: 'Marketing Manager', image: ProfileImg },
        startDate: '8/15/2024',
        endDate: '9/15/2024',
    },
    {
        key: "4",
        user: { name: 'Theresa Webb', role: 'Marketing Manager', image: ProfileImg },
        startDate: '8/15/2024',
        endDate: '9/15/2024',
    },
    
];

export const adminsListRows = [
    {
        id: 125,
        type: "Headquarter",
        department: { 
            name: 'corporate_administration', 
            branch: 'Eastside Branch',

            userName: 'Albert Flores',
            userRole: 'ProjectManager',
            userImage: ProfileImg,
            department_info: [ // Add department_info to hold tooltip data
                {
                title: "JobRoll",
                value: 'ProjectManagement',
                },
                {
                title: "ContactEmail",
                value: 'albert@example.com',
                },
                {
                title: "NoofAdmins",
                value: '54',
                },
            ],
        },
        user: { name: 'Dianne Russell', image: ProfileImg },
        no_of_licenses: "210",
        no_of_users: "254",
        no_of_licenses_left: "210",
    },
    {
        id: 126,
        type: "Headquarter",
        department: { 
            name: 'corporate_administration', 
            branch: 'Eastside Branch',

            userName: 'Albert Flores',
            userRole: 'ProjectManager',
            userImage: ProfileImg,
            department_info: [ // Add department_info to hold tooltip data
                {
                title: "JobRoll",
                value: 'ProjectManagement',
                },
                {
                title: "ContactEmail",
                value: 'albert@example.com',
                },
                {
                title: "NoofAdmins",
                value: '54',
                },
            ],
        },
        user: { name: 'Dianne Russell', image: Profile2 },
        no_of_licenses: "210",
        no_of_users: "254",
        no_of_licenses_left: "210",
    },
    {
        id: 127,
        type: "Headquarter",
        department: { 
            name: 'corporate_administration', 
            branch: 'Eastside Branch',

            userName: 'Albert Flores',
            userRole: 'ProjectManager',
            userImage: ProfileImg,
            department_info: [ // Add department_info to hold tooltip data
                {
                title: "JobRoll",
                value: 'ProjectManagement',
                },
                {
                title: "ContactEmail",
                value: 'albert@example.com',
                },
                {
                title: "NoofAdmins",
                value: '54',
                },
            ],
        },
        user: { name: 'Dianne Russell', image: Profile3 },
        no_of_licenses: "210",
        no_of_users: "254",
        no_of_licenses_left: "210",
    },
    {
        id: 128,
        type: "Headquarter",
        department: { 
            name: 'corporate_administration', 
            branch: 'Eastside Branch',

            userName: 'Albert Flores',
            userRole: 'ProjectManager',
            userImage: ProfileImg,
            department_info: [ // Add department_info to hold tooltip data
                {
                title: "JobRoll",
                value: 'ProjectManagement',
                },
                {
                title: "ContactEmail",
                value: 'albert@example.com',
                },
                {
                title: "NoofAdmins",
                value: '54',
                },
            ],
        },
        user: { name: 'Dianne Russell', image: Profile4 },
        no_of_licenses: "210",
        no_of_users: "254",
        no_of_licenses_left: "210",
    },
];

export const adminsViewsListRows = [
    {
        id: 125,
        status: 1,
        user: { name: 'Dianne Russell', image: ProfileImg },
        department: { 
            userName: 'Albert Flores',
            userRole: 'ProjectManager',
            userImage: ProfileImg,
            department_info: [ // Add department_info to hold tooltip data
                {
                    title: "DateofCreation",
                    value: '18/10/2024',
                },
                {
                    title: "DateofDeactive",
                    value: '10/12/2024',
                },
                {
                    title: "JobRoll",
                    value: 'ProjectManagement',
                },
                {
                    title: "department",
                    value: 'Operations',
                },
                {
                    title: "ContactEmail",
                    value: 'albert@example.com',
                },
                {
                    title: "ContactNumber",
                    value: '(406) 555-0120',
                },
                
            ],
        },
        
    },
    {
        id: 126,
        status: 2,
        user: { name: 'Dianne Russell', image: Profile2 },
        department: { 
            userName: 'Albert Flores',
            userRole: 'ProjectManager',
            userImage: ProfileImg,
            department_info: [ // Add department_info to hold tooltip data
                {
                    title: "DateofCreation",
                    value: '18/10/2024',
                },
                {
                    title: "DateofDeactive",
                    value: '10/12/2024',
                },
                {
                    title: "JobRoll",
                    value: 'ProjectManagement',
                },
                {
                    title: "department",
                    value: 'Operations',
                },
                {
                    title: "ContactEmail",
                    value: 'albert@example.com',
                },
                {
                    title: "ContactNumber",
                    value: '(406) 555-0120',
                },
                
            ],
        },
    },
    {
        id: 127,
        status: 1,
        user: { name: 'Dianne Russell', image: Profile3 },
        department: { 
            userName: 'Albert Flores',
            userRole: 'ProjectManager',
            userImage: ProfileImg,
            department_info: [ // Add department_info to hold tooltip data
                {
                    title: "DateofCreation",
                    value: '18/10/2024',
                },
                {
                    title: "DateofDeactive",
                    value: '10/12/2024',
                },
                {
                    title: "JobRoll",
                    value: 'ProjectManagement',
                },
                {
                    title: "department",
                    value: 'Operations',
                },
                {
                    title: "ContactEmail",
                    value: 'albert@example.com',
                },
                {
                    title: "ContactNumber",
                    value: '(406) 555-0120',
                },
                
            ],
        },
    },
    {
        id: 128,
        type: "Headquarter",
        status: 2,
        user: { name: 'Dianne Russell', image: Profile4 },
        department: { 
            userName: 'Albert Flores',
            userRole: 'ProjectManager',
            userImage: ProfileImg,
            department_info: [ // Add department_info to hold tooltip data
                {
                    title: "DateofCreation",
                    value: '18/10/2024',
                },
                {
                    title: "DateofDeactive",
                    value: '10/12/2024',
                },
                {
                    title: "JobRoll",
                    value: 'ProjectManagement',
                },
                {
                    title: "department",
                    value: 'Operations',
                },
                {
                    title: "ContactEmail",
                    value: 'albert@example.com',
                },
                {
                    title: "ContactNumber",
                    value: '(406) 555-0120',
                },
                
            ],
        },
    },
];
export const cityOptions = [
  {
    label: "riyadh",
    value: 1
  },
  {
    label: "jeddah",
    value: 2
  }
]
export const estTypeOptions = [
    {
      label: "Government",
      value: 1
    },
];
export const estSectorOptions = [
    {
      label: "ITSector",
      value: 1
    },
    
];
export const estCountryOptions = [
    {
      label: "SaudiArabia",
      value: 1
    },
    
];
export const estCityOptions = [
    {
      label: "Riyadh",
      value: 1
    },
    {
        label: "Jeddah",
        value: 2
    },
];

export const viewHqBranch = [
  {
      id: 1,
      label: "type",
      text: "Headquarter",
  },
  {
      id: 2,
      label: "name_en",
      text: "North Region Branch",
  },
  {
      id: 3,
      label: "name_ar",
      text: "فرع المنطقة الشمالية",
  },
  {
      id: 4,
      label: "initial_name",
      text: "North Region",
  },
]

export const viewHqBranchLocation = [
  {
      id: 1,
      label: "Country",
      text: "Saudi Arabia",
  },
  {
      id: 2,
      label: "City",
      text: "Jeddah",
  },
  {
      id: 3,
      label: "building",
      text: "Al Faisaliah Tower",
  },
  {
      id: 4,
      label: "floor",
      text: "6th",
  },
  {
      id: 5,
      label: "office",
      text: "654",
  },
  {
      id: 6,
      label: "address",
      text: "P.O. Box: 84261,Riyadh,Saudi Arabia",
  },
]

export const viewHqBranchContact = [
  {
      id: 1,
      label: "contact_person",
      text: "Darell Steward",
  },
  {
      id: 2,
      label: "contact_number",
      text: "(207) 55-0119",
  },
  {
      id: 3,
      label: "contact_email",
      text: "darellswitchard@gmail.com",
  },
  {
      id: 4,
      label: "admin_name",
      text: "Ronald Richards",
  },
  {
      id: 5,
      label: "number_of_users",
      text: "453",
  },
]
export const departmentsRows = [
  {
      id: 125,
      name: "Human Resources",
      city: "Riyadh",
      contactEmail: "alma.lawson@example.com",
      contactPhone: "(702) 555-0122",
      status: "active",
  },
  {
      id: 126,
      name: "Marketing and Communications",
      city: "Jeddah",
      contactEmail: "jessica.hanson@example.com",
      contactPhone: "(208) 555-0112",
      status: "inactive",
  },
  {
      id: 127,
      name: "Information Technology",
      city: "Dammam",
      contactEmail: "michael.mitc@example.com",
      contactPhone: "(505) 555-0125",
      status: "inactive",
  },
  {
      id: 128,
      name: "Finance and Accounting",
      city: "Mecca",
      contactEmail: "kenzi.lawson@example.com",
      contactPhone: "(603) 555-0123",
      status: "inactive",
  },
  {
      id: 129,
      name: "Sales and Business Development",
      city: "Medina",
      contactEmail: "sara.cruz@example.com",
      contactPhone: "(319) 555-0115",
      status: "inactive",
  },
  {
      id: 130,
      name: "Operations and Logistics",
      city: "Riyadh",
      contactEmail: "jackson.graham@example.com",
      contactPhone: "(308) 555-0121",
      status: "active",
  },
  {
      id: 131,
      name: "Customer Service",
      city: "Jeddah",
      contactEmail: "dolores.chambers@example.com",
      contactPhone: "(303) 555-0105",
      status: "inactive",
  },
  {
      id: 132,
      name: "Research and Development",
      city: "Dammam",
      contactEmail: "michelle.rivera@example.com",
      contactPhone: "(205) 555-0100",
      status: "inactive",
  },
  {
      id: 133,
      name: "Legal and Compliance",
      city: "Mecca",
      contactEmail: "bill.sanders@example.com",
      contactPhone: "(252) 555-0126",
      status: "inactive",
  },
  {
      id: 134,
      name: "Facilities Management",
      city: "Medina",
      contactEmail: "nevaeh.simmons@example.com",
      contactPhone: "(239) 555-0108",
      status: "active",
  },
];

export const adHqBranchOptions = [
    {
      label: "NorthRegionBranch",
      value: 1
    },
    
];
export const adDepartmentOptions = [
    {
      label: "ITDepartment",
      value: 1
    },
    
];
export const editTempADaccessRows = [
    {
        key: "1",
        user: { name: 'Theresa Webb', role: 'Marketing Manager', image: ProfileImg },
        startDate: '8/15/2024',
        endDate: '9/15/2024',
    },
    {
        key: "2",
        user: { name: 'Theresa Webb', role: 'Marketing Manager', image: ProfileImg },
        startDate: '8/15/2024',
        endDate: '9/15/2024',
    },
    {
        key: "3",
        user: { name: 'Theresa Webb', role: 'Marketing Manager', image: ProfileImg },
        startDate: '8/15/2024',
        endDate: '9/15/2024',
    },
    {
        key: "4",
        user: { name: 'Theresa Webb', role: 'Marketing Manager', image: ProfileImg },
        startDate: '8/15/2024',
        endDate: '9/15/2024',
    },
    
];


export const usersTempList = [
    {
      id: 1,
      profile: Profile,
      userName: "Jenny Wilson",
      userRole: "Corporate Administration (NR Branch)",
    },
    {
      id: 2,
      profile: Profile2,
      userName: "Brooklyn Simmons",
      userRole: "Sales and Distribution (CC Branch)",
    },
    {
      id: 3,
      profile: Profile3,
      userName: "Arlene McCoy",
      userRole: "Research and Development (EB Branch)",
    },
    {
      id: 4,
      profile: Profile4,
      userName: "Devon Lane",
      userRole: "IT Infrastructure and Security (NR Branch)",
    },
];