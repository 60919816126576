import React, { useState } from "react";
import {
    BackButton,
    Box,
    ButtonStyled,
    FormElementWrapper,
    FormEleWrapper,
    HeadingStyled,
    LabelStyled,
    SpanStyled,
    SubHeadingStyled,
    TextStyled,
} from "../../../../../components/styled/Elements";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    DashboardWrapper,
    DataContainer,
    DataContainerForm,
    FormDataContainer,
    ProfileSidebarWrapper,
    ProfileWrapper,
} from "./styled";
import {
    Camera,
    LogoutIcon,
    MenuTriggerCross,
    Plus,
    Profile,
    Profile2,
    ProfileImgLarge,
    SingleProfile,
} from "../../../../../components/styled/AllImages";
import { useNavigate } from "react-router-dom";
import FloatingInput from "../../../../../components/common/inputFields/FloatingInput";
import { RadioButton } from "../../../../../components/styled/FormElements";
import AddUsers from "./components/addUsers/MainData";
import {
    addFileRadio,
    departmentsList,
    usersList,
} from "../../../../../helpers/dummyConstants";
import styled from "styled-components";
import FormRadio from "../../../../../components/common/radioButtons/FormRadio";
import AddSingleUser from "./components/addSingleUser/MainData";
import Breadcrumbs from "../../../../../components/admin/Breadcrumbs/Breadcrumbs";

const EditFile = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [checkSelectedValueID, setcheckSelectedValueID] = useState(null);
    const checkSelectedValue = (e) => {
        setcheckSelectedValueID(Number(e.target.value));
    };
    return (
        <ProfileWrapper>
            <Box className="content-head py-3 px-3 px-md-4">
                <Box className="d-flex align-items-center gap-5">
                    <BackButton
                        label={t("edit_file")}
                        onClick={() => navigate("/cabinets/files")}
                    />
                    <Breadcrumbs />
                </Box>
            </Box>
            <Box className="content-container">
                <Row className="mt-3">
                    <Col md={12}>
                        <FormDataContainer className="dataContainer">
                            <FormElementWrapper>
                                <Box className="formEleContainer">
                                    <LabelStyled className="labelForm">
                                        {t("FileName")}
                                    </LabelStyled>
                                    <FloatingInput
                                        name="fName"
                                        type="input"
                                        fieldType="text"
                                        placeholder={"FileName"}
                                    />
                                </Box>
                                <Box className="formEleContainer">
                                    <LabelStyled className="labelForm">
                                        {t("Description")}
                                        <span className="optional">{t("Optional")}</span>
                                    </LabelStyled>
                                    <FloatingInput
                                        name="description"
                                        type="input"
                                        fieldType="text"
                                        placeholder={"Description"}
                                    />
                                </Box>
                            </FormElementWrapper>
                            <Box className="innerFoHead">
                                <SubHeadingStyled size="16px" color="var(--text)">
                                    {t("FileSettings")}
                                </SubHeadingStyled>
                            </Box>
                            {addFileRadio.map((x, i) => (
                                <Box className={`w-100 p-2 separation-border`}>
                                    <FormRadio
                                        name="cabinet_setting"
                                        label={t(x.name)}
                                        subLabel={t(x.label)}
                                        value={x.id}
                                        checked={checkSelectedValueID === x.id}
                                        onChange={checkSelectedValue}
                                    // checked={selected === 2}
                                    // onChange={onSelect}
                                    />
                                    {(checkSelectedValueID === 3 || checkSelectedValueID === 4) &&
                                        x.id === checkSelectedValueID && (
                                            <Box className="addUserCont">
                                                <AddUsers
                                                    title="add_users"
                                                    icon={<SingleProfile />}
                                                    selPlaceholder="SelectUser"
                                                    mainParents={usersList}
                                                />
                                            </Box>
                                        )}

                                    {checkSelectedValueID === 5 &&
                                        x.id === checkSelectedValueID && (
                                            <Box className="addUserCont">
                                                <AddSingleUser
                                                    title="Add_Department"
                                                    icon={<Plus />}
                                                    selPlaceholder="Select_One_Department"
                                                    mainParents={departmentsList}
                                                />
                                            </Box>
                                        )}
                                    {checkSelectedValueID === 6 &&
                                        x.id === checkSelectedValueID && (
                                            <Box className="addUserCont">
                                                <AddUsers
                                                    title="Add_Department"
                                                    icon={<Plus />}
                                                    selPlaceholder="select_department"
                                                    mainParents={departmentsList}
                                                />
                                            </Box>
                                        )}
                                </Box>
                            ))}
                            <Box className="innerFoHead">
                                <SubHeadingStyled size="16px" color="var(--text)">
                                    {t("Access_granted_through_request")}
                                </SubHeadingStyled>
                            </Box>
                            <FormElementWrapper className="d-flex gap-2">
                                <UserUpload>
                                    <Box className="userPlaceholder">
                                        <img src={Profile} alt="user" />
                                    </Box>
                                    <Box className="abtUser">
                                        <SpanStyled color="var(--text)" size="14px">
                                            Cameron Williamson
                                        </SpanStyled>
                                    </Box>
                                    <MenuTriggerCross className="removeTaskTrigger" />
                                </UserUpload>
                                <UserUpload>
                                    <Box className="userPlaceholder">
                                        <img src={Profile2} alt="user" />
                                    </Box>
                                    <Box className="abtUser">
                                        <SpanStyled color="var(--text)" size="14px">
                                            Brooklyn Simmons
                                        </SpanStyled>
                                    </Box>
                                    <MenuTriggerCross className="removeTaskTrigger" />
                                </UserUpload>
                            </FormElementWrapper>

                            <Box className="commonBtns w-100">
                                <ButtonStyled
                                    bg="var(--bgSecondary)"
                                    label={t("Cancel")}
                                    color="var(--text2)"
                                ></ButtonStyled>
                                <ButtonStyled label={t("Update")}></ButtonStyled>
                            </Box>
                        </FormDataContainer>
                    </Col>
                </Row>
            </Box>
        </ProfileWrapper>
    );
};

export default EditFile;

const UserUpload = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding: 8px 16px;
  background-color: var(--bgSecondary);
  border-radius: 10px;
  width: fit-content;
  .userPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    background-color: var(--greyBg);
    border-radius: 50%;
    // border: 1px dashed var(--greyText);
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  svg.removeTaskTrigger {
    width: 12px;
    height: 12px;
    margin-left: 7px;
    &:hover {
      stroke: var(--redColor);
      fill: var(--redColor);
      path {
        stroke: var(--redColor);
        fill: var(--redColor);
      }
    }
  }
  input[type="file"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .abtUser {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    span {
      // width: 100%;
    }
  }
`;
