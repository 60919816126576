import { Password, UserIcon } from "../../../components/styled/AllImages";
import AccountSetting from "./AccountSetting";
import ChangePassword from "./ChangePassword";

export const tabsArrayList = [
  {
    title: "AccountSetting",
    icon: UserIcon,
    component: AccountSetting,
  },
  {
    title: "ChangePassword",
    icon: Password,
    component: ChangePassword,
  },
];
