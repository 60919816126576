import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import LangChangeButton from "../../../languages/LangChangeButton";
import { HeaderContainer, MenuToggle } from "./styled";
import {
  Box,
  HeadingStyled,
  LazyImage,
  SpanStyled,
  SubHeadingStyled,
} from "../../styled/Elements";
import CustomDropdown from "../../common/dropdowns/CustomDropdown";
import {
  profileDropdownList,
  supportDropdownList,
} from "../../../helpers/adminConstants/AdminConstants";
import { Menu, ProfileImg } from "../../styled/AllImages";

const AdminHeader = ({sidebarStatus, setSidebarStatus}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasLoginInLocation = location.pathname.includes("login");
  const { t } = useTranslation();
  const [isSticky, setIsSticky] = useState(false);

  const profileDropdownTitle = () => {
    return (
      <>
        <SpanStyled className="active-dot bg-transparent position-relative border-0 p-0">
          <LazyImage
            width="35px"
            height="35px"
            src={ProfileImg}
            className="rounded-circle"
          />
        </SpanStyled>
        <SubHeadingStyled color="var(--text)" size="16px" className="mx-2">
          {t("abdullah")}
        </SubHeadingStyled>
      </>
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <HeaderContainer>
        <Box className={`header-container px-3 px-md-4`}>
          <Box className="nav-bar justify-content-between">
            <Box className="d-flex align-items-center gap-2">
              <MenuToggle
                className="border-0 px-0 bg-transparent mb-1"
                onClick={() => setSidebarStatus(!sidebarStatus)}
              >
                <Menu />
              </MenuToggle>
              <HeadingStyled size="20px" weight="bold" color="var(--text)" className="greet-heading">
                {t("hello_abdullah")}
              </HeadingStyled>
            </Box>
            <Box className="d-flex align-items-center">
              <CustomDropdown
                bg="var(--bgSecondary)"
                color="var(--text)"
                className="mx-2 support-dropdown"
                itemsList={supportDropdownList}
                title={t("contact_support")}
              />
              <LangChangeButton />
              <CustomDropdown
                bg="var(--white)"
                color="var(--text)"
                className="mx-2"
                paddingY={5}
                paddingX={5}
                itemsList={profileDropdownList}
                title={profileDropdownTitle()}
              />
            </Box>
          </Box>
        </Box>
      </HeaderContainer>
    </>
  );
};

export default AdminHeader;
