import React, { useState } from "react";
import { HistoryDetailsWrapper } from "./styled";
import {
  BackButton,
  Box,
  LabelStyled,
} from "../../../components/styled/Elements";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ListTable from "../../../components/common/tables/ListTable";
import { historyDetailColumns } from "../../../helpers/adminConstants/TableConstants";
import { historyDetailRows } from "../../../helpers/dummyConstants";
import { InformationRowWrapper } from "../../../components/styled/styled";
import FormRadio from "../../../components/common/radioButtons/FormRadio";
import { SearchIcon } from "../../../components/styled/AllImages";
import FloatingInput from "../../../components/common/inputFields/FloatingInput";
import { Col, Row } from "react-bootstrap";
import DatePickerDefault from "../../../components/common/inputFields/DatePicker";
import { UsersDropdown } from "../../../components/common/dropdowns/DropdownInputs";
import Breadcrumbs from "../../../components/admin/Breadcrumbs/Breadcrumbs";

const HistoryDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState();

  return (
    <HistoryDetailsWrapper>
      <Box className="content-head py-3 px-3 px-md-4">
        <Box className="d-flex align-items-center gap-5">
          <BackButton
            label={t("view_history")}
            onClick={() => navigate("/history")}
          />
          <Breadcrumbs />
        </Box>
      </Box>
      <Box>
        <InformationRowWrapper className="m-3">
          <div className="table-content">
            <div className="column">
              <div className="column-label">{t("Archive")}</div>
              <div className="column-value">Human_Resources_Archive</div>
            </div>
            <div className="column">
              <div className="column-label">{t("date")}</div>
              <div className="column-value">5/30/2024</div>
            </div>
            <div className="column">
              <div className="column-label">{t("number")}</div>
              <div className="column-value">32658</div>
            </div>
            <div className="column">
              <div className="column-label">{t("cabinet_name")}</div>
              <div className="column-value">Human Resources</div>
            </div>
            <div className="column">
              <div className="column-label">{t("FileName")}</div>
              <div className="column-value">HR_EmployeeRecords_2024</div>
            </div>
          </div>
        </InformationRowWrapper>
        <Row className="mx-2">
          <Col lg={5} md={8} sm={12} className="my-2">
            <Box className="d-flex align-items-center type-select-box">
              <LabelStyled size="14px">{t("type")}</LabelStyled>
              <FormRadio
                name="type"
                subLabel={t("all")}
                value={1}
                // checked={selected === 2}
                // onChange={onSelect}
              />
              <FormRadio
                name="type"
                subLabel={t("views")}
                value={1}
                // checked={selected === 2}
                // onChange={onSelect}
              />
              <FormRadio
                name="type"
                subLabel={t("downloads")}
                value={1}
                // checked={selected === 2}
                // onChange={onSelect}
              />
            </Box>
          </Col>
          <Col lg={2} md={4} sm={6} className="my-2">
            <DatePickerDefault
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              placeholder={t("search_by_date")}
            />
          </Col>
          <Col lg={2} md={4} sm={6} className="my-2">
            <UsersDropdown placeholder={t("search_by_user")} />
          </Col>
          <Col lg={3} md={5} sm={6} className="my-2">
            <FloatingInput
              name="search"
              type="input"
              fieldType="text"
              label={t("search")}
              placeholder={t("search")}
              bg="var(--white)"
              icon={SearchIcon}
            />
          </Col>
        </Row>
        <ListTable rows={historyDetailRows} columns={historyDetailColumns} />
      </Box>
    </HistoryDetailsWrapper>
  );
};

export default HistoryDetails;
