import React from "react";
import styled from "styled-components";

const CustomRadioButton = ({ label, value, checked, onChange, name }) => {
  return (
    <RadioWrapper checked={checked}>
      <input
        className="radio-input"
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <span className="radio-text mx-2" checked={checked}>
        {label}
      </span>
    </RadioWrapper>
  );
};

export default CustomRadioButton;

const RadioWrapper = styled.label`
  display: flex;
  align-items: end;
  width: 100%;
  padding: 11px 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: var(--white);
  border: 1px solid
    ${(props) => (props.checked ? "var(--themeColor2)" : "var(--border)")};

  .radio-input {
    appearance: none;
    min-width: 25px;
	  max-width: 25px;
    height: 25px;
    border: 2px solid var(--border);
      background-color: var(--bgSecondary);
    border-radius: 50%;
    position: relative;

    &:checked {
        border: 7px solid var(--themeColor2);
    }
    &:checked::after {
      content: "";
      width: 9px;
      height: 9px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .radio-text {
    font-weight: ${(props) => (props.checked ? "bold" : "normal")};
    color: ${(props) => (props.checked ? "var(--themeColor2)" : "var(--text)")};
  }
`;