import React, { useState } from "react";
import { Box } from "../components/styled/Elements";
import AdminHeader from "../components/admin/AdminHeader/AdminHeader";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import AdminSidebar from "../components/admin/AdminSidebar/AdminSidebar";

const AdminLayout = () => {
  const [sidebarStatus, setSidebarStatus] = useState(false);
  return (
    <LayoutWrapper className="d-flex center-main">
      <Box>
        <AdminSidebar sidebarStatus={sidebarStatus} setSidebarStatus={setSidebarStatus} />
      </Box>
      <Box className="flex-1 w-100">
        <AdminHeader sidebarStatus={sidebarStatus} setSidebarStatus={setSidebarStatus} />
        <Box className={"flex-1 custom-scroll pb-4 content-main"}>
          <Outlet />
        </Box>
      </Box>
    </LayoutWrapper>
  );
};

export default AdminLayout;

const LayoutWrapper = styled.div`
  background-color: var(--bgSecondary);
`;
