import React from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";

const DatePickerDefault = ({
  selectedDate,
  onChange,
  placeholder,
  icon: Icon,
  value,
}) => {
  return (
    <DatepickerWrapper>
      <DatePicker
        selected={selectedDate}
        onChange={onChange}
        className="form-control"
        placeholderText={placeholder}
        customInput={<input readOnly disabled />}
        value={value}
        {...(Icon && { showIcon: true, icon: <Icon /> })}
      />
    </DatepickerWrapper>
  );
};

export default DatePickerDefault;

const DatepickerWrapper = styled.div`
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-wrapper .form-control {
    background-color: ${({ bg }) => (bg ? bg : "var(--white)")};
    border: 1px solid var(--borderLight);
    height: 48px;
    box-shadow: none;
    outline: none;
    border-radius: 10px;
    color: var(--text);
    font-size: 14px;
    &::placeholder {
      color: var(--text2);
    }
  }

  .react-datepicker__input-container {
    display: flex;
    align-items: center;
}

.react-datepicker__calendar-icon {
    width: 18px;
    height: 19px;
    padding-top: 5px;
}

  .react-datepicker__view-calendar-icon input {
    padding: 6px 10px 5px 33px;
    body.rtl &{
        padding: 6px 33px 5px 10px;
    }
  }

  .form-control:focus {
    box-shadow: none;
    outline: none;
    /* border: none; */
  }
`;
