import React from "react";
import { Box } from "../../../components/styled/Elements";
import { SignupWrapper } from "./styled";
import { AuthSlides } from "../../../helpers/authConstants/AuthConstants";
import AuthSlider from "../../../components/auth/authSlider/AuthSlider";
import { Col, Row } from "react-bootstrap";
import AuthHeader from "../../../components/auth/AuthHeader/AuthHeader";
import SignupForm from "./components/SignupForm";

const SignUp = () => {
  return (
    <SignupWrapper>
      <AuthHeader />
      <Box className="mt-4 container-fluid pb-4">
        <Row>
          <Col lg={6} className="px-3 px-xl-5 px-md-5 pb-4">
            <Box>
              <SignupForm />
            </Box>
          </Col>
          <Col lg={6} className="px-3 px-xl-5">
            <Box className="sticky-item">
              <AuthSlider slides={AuthSlides} />
            </Box>
          </Col>
        </Row>
      </Box>
    </SignupWrapper>
  );
};

export default SignUp;
