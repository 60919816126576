import { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
// import { ArrowLeft, Eye, EyeSlash } from "react-bootstrap-icons";
// import { rightAuth, leftAuth, Add, Attach, Deletes } from "./AllImages";
import {
	Box,
	IconBoxColor,
	LazyImage,
	SpanStyled,
	TextStyled,
	ButtonStyledDiv,
	SvgContainerSmallStyled,
	BackButtonWrapper,
	PillStyled,
} from "./Elements";
import {
	ButtonWrapper,
	InputStyled,
	InputStyledWrap,
	LayoutBox,
	RadioButtonStyled,
	CustomCheckboxStyled,
	FileUploadContainer,
	UserUpload,
} from "./styled";
import LangChangeButton from "../../languages/LangChangeButton";

export const sliceProps = (props, sub) => {
	const { children, ...otherProps } = props;
	sub.forEach((prop) => delete otherProps[prop]);
	return otherProps;
};

export const InputWithIcon = (props) => {
	const unwantedProps = ["icon", "label"];
	return (
		<InputStyledWrap className="position-relative">
			<InputStyled
				{...sliceProps(props, unwantedProps)}
				className={`w-100 ${!props.icon ? "no-icon" : ""}`}
				autoComplete="false"
			/>
			{props.icon && <props.icon width="18px" className="position-absolute" />}
		</InputStyledWrap>
	);
};

export const InputFloating = (props) => {
	const unwantedProps = ["icon", "textarea", "iconColor", "type"];
	const { type, placeholder, required, textarea, icon, iconColor } = props;
	const [showType, setType] = useState(type);
	return (
		<FloatingLabel
			controlId={`floatingInput__${placeholder
				?.trim()
				?.toLowerCase()
				?.replace(/\s/g, "_")}`}
			label={`${placeholder} ${required ? "*" : ""}`}
			{...(!textarea && { style: { height: "" } })}
		>
			<Form.Control
				as={textarea ? `textarea` : "input"}
				{...sliceProps(props, unwantedProps)}
				className={`${icon ? "icon-float" : ""} ${type === "password" ? "icon-security" : ""
					} floating-input h-100 `}
				type={showType}
			/>
			{icon && (
				<IconBoxColor iconColor={iconColor}>
					<icon width="20px" className="position-absolute" />
				</IconBoxColor>
			)}
			{type === "password" && (
				<IconBoxColor iconColor={iconColor}>
					{/* {showType === "password" ? (
						<Eye
							size="20px"
							color="var(--grey-color)"
							onClick={() => setType("text")}
							className="cursor-pointer position-absolute password-show-btn"
						/>
					) : (
						<EyeSlash
							size="20px"
							color="var(--grey-color)"
							onClick={() => setType("password")}
							className="cursor-pointer position-absolute password-show-btn"
						/>
					)} */}
				</IconBoxColor>
			)}
		</FloatingLabel>
	);
};
/*
export const InputFloating = (props) => {
	const unwantedProps = ['icon', 'textarea', 'iconColor', 'type'];
	const [showType, setType] = useState(props.type)
	return (
		<FloatingLabel
			controlId={`floatingInput__${props?.placeholder?.trim()?.toLowerCase()?.replace(/\s/g, "_")}`}
			label={`${props.placeholder} ${props.required ? '*' : ''}`}
			{...(!props.textarea && { style: { height: '' } })}
		>
		    
			<Form.Control as={props.textarea ? `textarea` : 'input'} {...sliceProps(props, unwantedProps)}
				className={`${props.icon ? 'icon-float' : ''} ${props.type == 'password' ? 'icon-security' : ''} floating-input h-100 `}
				type={showType}
			/>
			{props.icon && (
				<IconBoxColor iconColor={props.iconColor}>
					<props.icon width='20px' className='position-absolute' />
				</IconBoxColor>
			)}
			{props.type == 'password' && (
				<IconBoxColor iconColor={props.iconColor}>
					{showType == 'password' ?
						<Eye size='20px' color='var(--grey-color)' onClick={() => setType('text')}
							className='cursor-pointer position-absolute password-show-btn'
						/>
						:
						<EyeSlash size='20px' color='var(--grey-color)' onClick={() => setType('password')}
							className='cursor-pointer position-absolute password-show-btn'
						/>
					}
				</IconBoxColor>
			)}
		</FloatingLabel>
	)
}
*/
export const SelectList = (props) => {
	const unwantedProps = ["options", "placeholder"];
	const defaultSelectedOption = props.options[0]; // Set the default selected option
	return (
		<select
			defaultValue={defaultSelectedOption}
			className="custom-select-design"
			{...sliceProps(props, unwantedProps)}
		>
			<option value="" hidden>
				{props.placeholder}
			</option>
			{props.options?.map((item, key) => (
				<option value={item} className="text-capitalize" key={key}>
					{item}
				</option>
			))}
		</select>
	);
};

export const SelectListWithIcon = (props) => {
	const unwantedProps = ["icon", "options", "placeholder"];
	const defaultSelectedOptions = props.options[0]; // Set the default selected option
	return (
		<InputStyledWrap className="position-relative">
			<select
				defaultValue={defaultSelectedOptions}
				className={`custom-select-design med-sel ${props.selClass} ${!props.icon ? "no-icon" : ""}`}
				{...sliceProps(props, unwantedProps)}
			>
				<option value="Select Role" selected="selected">
					{props.placeholder}
				</option>
				{props.options?.map((item, key) => (
					<option value={item} className="text-capitalize" key={key}>
						{item}
					</option>
				))}
			</select>
			{props.icon && <props.icon width="18px" className="position-absolute" />}
		</InputStyledWrap>
	);
};



export const LandingWrapper = (props) => {
	const unwantedProps = ["label1", "label2", "text"];
	return (
		<LayoutBox {...sliceProps(props, unwantedProps)}>
			<Box className="layout_content">
				<SpanStyled className="layout_heading">{props.label1}</SpanStyled>
				<SpanStyled className="layout_heading" color="var(--themeColor)">
					{props.label2}
				</SpanStyled>
				<TextStyled color="var(--white)">{props.text}</TextStyled>
				<Box className="btnWapper">
					<LangChangeButton />
				</Box>
			</Box>
			<SpanStyled className="vector_right vector">
				{/* <LazyImage src={rightAuth} /> */}
			</SpanStyled>
			<SpanStyled className="vector_left vector">
				{/* <LazyImage src={leftAuth} /> */}
			</SpanStyled>
		</LayoutBox>
	);
};


/*
export const ImageUploader = (props) => {
	const [selectedImage, setSelectedImage] = useState(null);
	const [selImgPhdr, setSelImgPhdr] = useState(true);

	const handleImageChange = (e) => {
		const file = e.target.files[0];

		if (file) {
		if (file.type === 'image/jpeg' || file.type === 'image/png') {
			const reader = new FileReader();

			reader.onload = (e) => {
			setSelectedImage(e.target.result);
			};

			reader.readAsDataURL(file);
			setSelImgPhdr(false);
		} else {
			alert('Please select a valid image file (JPEG or PNG).');
		}
		}
	};
	const unwantedProps = ['labelText', 'label'];
	return (
		<UserUpload>
			<input className="" type="file" accept=".jpg, .jpeg, .png" onChange={handleImageChange}/>
			<Box className="userPlaceholder">
				{selectedImage && (
					<img src={selectedImage} alt="Preview" />
				)}
				{selImgPhdr && (
					<SingleProfile />
				)}
			</Box>
			{props.label && (
				<SpanStyled color="grey" >{props.label}</SpanStyled>
			)}
		    

		</UserUpload>
	)
}
*/

export const ImageUploader = (props) => {
	return (
		<UserUpload>
			<Box className="userPlaceholder">
				<img src={props.userImg} alt="user" />
			</Box>
			<Box className="abtUser">
				{props.label && (
					<SpanStyled color="var(--grey)">{props.label}</SpanStyled>
				)}
				{props.role && (
					<SpanStyled color="var(--greyText)" size="10px">
						{props.role}
					</SpanStyled>
				)}
			</Box>
		</UserUpload>
	);
};

export const FileUploader = (props) => {
	const [files, setFiles] = useState([]);
	const [isDragOver, setIsDragOver] = useState(false);

	const handleFileChange = (e) => {
		const selectedFiles = Array.from(e.target.files);
		setFiles([...files, ...selectedFiles]);
	};

	const handleDrop = (e) => {
		e.preventDefault();
		const droppedFiles = Array.from(e.dataTransfer.files);
		setFiles([...files, ...droppedFiles]);
		setIsDragOver(false);
	};
	const handleDragOver = (e) => {
		e.preventDefault();
		setIsDragOver(true);
	};

	const handleDragLeave = () => {
		setIsDragOver(false);
	};

	const handleDelete = (fileToDelete) => {
		const updatedFiles = files.filter((file) => file !== fileToDelete);
		setFiles(updatedFiles);
	};
	return (
		<FileUploadContainer>
			<Box
				className={`fileUpload ${isDragOver ? "drag-over" : ""}`}
				onDrop={handleDrop}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
			>
				{/* <ButtonStyledDiv className="btn-add-files">
					<input type="file" accept="*" onChange={handleFileChange} multiple />
					<Add /> Add
				</ButtonStyledDiv> */}
				<Box>
					<SpanStyled className="spnDfIc" fstyle="italic" color="var(--grey)">
						{/* <Attach /> Drag and drop files to attach or{" "} */}
						<SpanStyled color="var(--themeColor)" family="var(--medium)">
							browse
						</SpanStyled>
					</SpanStyled>
				</Box>
			</Box>
			<Box className="w-100 mt-2">
				<ul className="file-list">
					{files.map((file, index) => (
						<li key={index}>
							{file.name}
							{/* <Deletes onClick={() => handleDelete(file)} /> */}
						</li>
					))}
				</ul>
			</Box>
		</FileUploadContainer>
	);
};
export const RadioButton = (props) => {
	const id = Math.floor(100000 + Math.random() * 900000);
	const unwantedProps = ["label", "labelClass", "labelInfo"];
	// console.log(sliceProps(props, unwantedProps));
	return (
		<RadioButtonStyled className="customRadio" color={props.color} width={props.width} height={props.height}>
			<input
				type="radio"
				id={props.name + "-" + id}
				{...sliceProps(props, unwantedProps)}
			/>
			<label className={props.labelClass} htmlFor={props.name + "-" + id}>
				{props.label}
				{props.labelInfo && (
					<span>
						{props.labelInfo}
					</span>
				)}
			</label>
		</RadioButtonStyled>
	);
};

export const CustomCheckbox = (props) => {
	const id = Math.floor(100000 + Math.random() * 900000);
	const unwantedProps = ["label", "labelClass", "checked", "value", "onChange"];
	return (
		<CustomCheckboxStyled
			className="mb-0 customCheck"
			{...sliceProps(props, unwantedProps)}
		>
			<input
				type="checkbox"
				checked={props.checked}
				value={props.value}
				onChange={props.onChange}
				
			/>
			<span className={`checkLabel ${props.labelClass}`}>
				{props.label}
			</span>
			<span className="checkmark"></span>
		</CustomCheckboxStyled>
	);
};
export const ButtonWithIcon = (props) => {
	const unwantedProps = ["icon", "label"];
	return (
		{/* <ButtonWrapper
			{...sliceProps(props, unwantedProps)}
			className="d-flex align-items-center"
		>
			<button
				{...sliceProps(props, unwantedProps)}
				className={`${!props.icon ? "Btn-icon" : ""}`}
				autoComplete="false"
			>
				{props.icon && <props.icon width="18px" />}
				<SpanStyled weight={500} color="var(--drColor)" size="14px">
					{props.label ? props.label : ""}
				</SpanStyled>
			</button>
		</ButtonWrapper> */}
	);
};

export const IconTitleStyled = (props) => (
	<Box>
		{/* <SvgContainerSmallStyled stroke={props?.color}>
			{props.icon ? (
				<Box
					className={`tile-icon me-3 d-flex align-items-center justify-content-center`}
					style={{ background: props?.bg }}
				>
					<props.icon width="22px" height="22px" />
				</Box>
			) : null}
			{props.image ? (
				<Box
					className={`${props?.bg ? "tile-icon" : ""
						} me-3 d-flex align-items-center justify-content-center`}
					style={{ background: props?.bg }}
				>
					<LazyImage
						className="rounded-circle"
						src={props?.image}
						width="40px"
						height="40px"
						onError={props?.handleImageError}
					/>
				</Box>
			) : null}
			<Box>
				<TextStyled
					className={`mb-0 ${props?.elips ? props?.elips : ""}`}
					weight={500}
					size={props.headingSize ? props.headingSize : "14px"}
					color={props?.color ? props.color : "var(--drColor)"}
					dangerouslySetInnerHTML={{ __html: props.title }}
					title={props.title}
				></TextStyled>
				<SpanStyled
					color={"var(--grey-color)"}
					size="14px"
					weight={300}
					className={props?.customClass || ""}
				>
					{props?.desc}
				</SpanStyled>
			</Box>
		</SvgContainerSmallStyled> */}
	</Box>
);

export const BackButton = (props) => {
	return (
		{/* <BackButtonWrapper {...props} className="d-flex align-items-center">
			<button type="button" className="icon-box border-0 p-0">
				<ArrowLeft color="var(--drColor)" />
			</button>
			<SpanStyled size="18px" weight="normal" color="var(--darkBlue)" family="var(--semiBold)">
				{props.label ? props.label : `Back`}
			</SpanStyled>
		</BackButtonWrapper> */}
	);
};

export const Pill = (props) => {
	return (
		<InputStyledWrap className="position-relative">
			{/* <PillStyled>
				{props.icon && <props.icon width="18px" />}
				<SpanStyled weight={500} size="14px">
					{props.text}
				</SpanStyled>
				{props.removeIcon && <props.removeIcon width="18px" />}
			</PillStyled> */}
		</InputStyledWrap>
	);
};
